import styled from "@emotion/styled";
import React from "react";

import { Typography } from "@mui/material";

import SidebarNavList from "components/sidebar/SidebarNavList";

const Title = styled(Typography)`
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

const SidebarNavSection = (props) => {
  const {
    className,
    component: Component = "nav",
    pages,
    title,
    ...rest
  } = props;

  return (
    <Component {...rest}>
      {title && <Title variant="subtitle2">{title}</Title>}
      <SidebarNavList depth={0} pages={pages} />
    </Component>
  );
};

export default SidebarNavSection;

import { useLazyQuery } from "@apollo/client";
import { SEARCH_SERVICE } from "components/gql/gql_queries";
import { logGQLError } from "data/functions";

export function SearchService() {
  const queryName = "SearchService";
  const [refetch, variables] = useLazyQuery(SEARCH_SERVICE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

import { Box, LinearProgress, Paper } from "@mui/material";
import CustomizedBreadcrumbs from "components/Breadcrumbs";
import { NotFoundBlock } from "components/NotFoundBlock";
import { GetServices } from "components/gql/queris/GetServices";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { getBreadcrumbs, getCategories, isObjectEmpty } from "data/functions";
import FolderList from "pages/services/list/FolderList";
import ServiceList from "pages/services/list/ServiceList";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const FolderPage = (props) => {
  const { dataObj, pushDataObj, setDataObj } = GetData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [item, setItem] = useState(
    window.history.state
      ? (dataObj?.folderList ?? [])?.find(
          (el) => el?.id === (window.history.state?.folder || ""),
        ) || {}
      : {},
  );
  const [br, setBr] = useState([]);
  const [noFolders, setNoFolders] = useState(false);
  const [noServices, setNoServices] = useState(false);
  const { cInfo } = GetCInfo();
  let searchMode = !isObjectEmpty(searchParams.get("mode")) || false;
  const [svcRefetch, svcVariables] = GetServices();

  useEffect(() => {
    if (
      !window.history.state ||
      (window.history.state?.folder || "") !== (item?.id || "")
    ) {
      window.history.pushState(
        { folder: item?.id || "" },
        "",
        window.location.pathname + window.location?.search,
      );
    }

    if (searchParams.get("mode")) {
      searchParams.delete("mode");
      setSearchParams(searchParams);
    }
    if (cInfo?.UUID) {
      getCategories(svcRefetch, item, { dataObj, pushDataObj }, cInfo?.UUID);
    }

    // eslint-disable-next-line
  }, [item, cInfo?.UUID]);

  useEffect(() => {
    if (
      window.history.state &&
      (window.history.state?.folder || "") !== (item?.id || "")
    ) {
      setItem(
        (dataObj?.folderList ?? [])?.find(
          (el) => el?.id === (window.history.state?.folder || ""),
        ),
      );
    }
  }, [window.history.state]);

  useEffect(() => {
    let b = getBreadcrumbs(dataObj?.folderList, item?.id || "", "id");
    setBr(b);
    // eslint-disable-next-line
  }, [item, dataObj?.folderList, searchMode]);

  function clickOnBr(el) {
    setItem(el);
    if (searchParams.get("mode")) {
      searchParams.delete("mode");
      setSearchParams(searchParams);
    }
  }
  return (
    <React.Fragment>
      <Box sx={{ padding: "0 0.4rem 0.4rem 0.4rem" }}>
        <Paper sx={{ position: "sticky", top: 0, zIndex: 3 }}>
          <CustomizedBreadcrumbs array={br} func={clickOnBr} />
        </Paper>

        <Box sx={{ height: "2px" }}>
          {svcVariables.loading && noFolders && noServices && (
            <LinearProgress sx={{ height: "2px" }} />
          )}
        </Box>

        {((isObjectEmpty(svcVariables?.data?.export_servicesList?.services) &&
          isObjectEmpty(svcVariables?.data?.export_servicesList?.categories) &&
          noFolders &&
          noServices) ||
          svcVariables.error) &&
          !svcVariables.loading && <NotFoundBlock />}

        <FolderList
          parent={item}
          searchMode={searchMode}
          setNoFolders={setNoFolders}
          setParent={setItem}
        />
        <ServiceList
          checked={props.checked}
          handleChecked={props.handleChecked}
          parent={item}
          searchMode={searchMode}
          setNoServices={setNoServices}
        />
      </Box>
    </React.Fragment>
  );
};

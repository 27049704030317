import { Button } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { showRoute } from "data/mobapp_functions";
import React from "react";

export function MapButton(props) {
  const { dataObj } = GetData();

  return (
    <Button
      disableRipple
      onClick={(e) => {
        if (
          (dataObj?.platform === "Android" ||
            dataObj?.platform === "iPhone" ||
            dataObj?.platform === "iPad" ||
            dataObj?.platform === "iPod") &&
          props?.item?.locationLatitude &&
          props?.item?.locationLongitude
        )
          showRoute(
            props?.item?.locationLatitude,
            props?.item?.locationLongitude,
          );
        else {
          let URL = "https://yandex.ru/maps/";
          if (dataObj?.locationParam) {
            URL =
              URL +
              "?rtext=" +
              dataObj?.locationParam?.location_longitude +
              "," +
              dataObj?.locationParam?.location_latitude +
              "~" +
              props.item?.locationLongitude +
              "," +
              props.item?.locationLatitude +
              "&rtt=auto";
          } else {
            URL =
              URL +
              "?whatshere[point]=" +
              props.item?.locationLongitude +
              "," +
              props.item?.locationLatitude +
              "&whatshere[zoom]=17";
          }
          window.open(URL, "_blank");
        }
      }}
      variant="outlined"
    >
      Маршрут
    </Button>
  );
}

import { useLazyQuery } from "@apollo/client";
import { EXPORT_COMPANY_DEPARTMENTS } from "components/gql/gql_queries_export";
import { GetCInfo } from "contexts/CompanyContext";
import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
  saveMap,
} from "data/functions";

export function DepartmentsQuery() {
  const queryName = "CompanyDepartments";
  const { cInfo, setCInfo } = GetCInfo();
  const [refetch, variables] = useLazyQuery(EXPORT_COMPANY_DEPARTMENTS, {
    fetchPolicy: "no-cache",
    skip: !cInfo.UUID,
    // variables: {
    //   companyUuid: cInfo?.UUID,
    // },
    onCompleted: (data) => {
      if (isObjectEmpty(data?.export_companyDepartments?.departments)) {
        setCInfo([], "departments");
      } else if (
        !isIdentical(
          data?.export_companyDepartments?.departments,
          cInfo?.departments,
        )
      ) {
        consoleBeauty("Получен список подразделений", "GraphQL", "darkgreen");
        let res = saveMap(
          data.export_companyDepartments?.departments,
          "DepartmentsQuery.onCompleted",
        ).map((e, i) => {
          if (!e?.ID) e.ID = "unknown-department_" + i;
          return e;
        });
        setCInfo(res, "departments");
      }
      if (isObjectEmpty(data?.export_companyDepartments?.regions)) {
        setCInfo([], "regions");
      } else if (
        !isIdentical(data?.export_companyDepartments?.regions, cInfo?.regions)
      ) {
        setCInfo(data.export_companyDepartments?.regions, "regions");
      }
    },
    onError: (e) => {
      setCInfo([], "departments");
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

import { Box, Button, Paper, Typography } from "@mui/material";

import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export function ButtonWithPrice(props) {
  const { dataObj } = GetData();
  const { getPrice, isEmptyContext } = GetBasket();
  const navigate = useNavigate();

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalType, setTotalType] = useState("");
  const [sale, setSale] = useState(0);

  async function handleSubmit() {
    if (isObjectEmpty(props?.selected)) return;
    navigate("/request");
  }

  React.useEffect(() => {
    setLocalPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selected]);

  function setLocalPrices() {
    let carID = dataObj?.usedCar?.car?.id || "all";
    let fSalePrice = 0;
    let fPrice = 0;
    let type = "";
    let typeEmpty = "";
    Object.keys(props?.selected).forEach((key) => {
      props?.selected[key].forEach((element) => {
        const priceObj = getPrice(element?.id, carID);
        if (priceObj) {
          fSalePrice = fSalePrice + priceObj?.salePrice;
          fPrice = fPrice + priceObj?.price;
          if (priceObj?.priceType === "min_price") type = priceObj?.priceType;
          if (priceObj?.priceType === "empty_price")
            typeEmpty = priceObj?.priceType;
        }
      });
    });
    if (!type && typeEmpty) type = typeEmpty;
    setTotalType(type);
    setTotalPrice(fSalePrice);
    setSale(fPrice - fSalePrice);
  }

  return (
    <Paper
      sx={{
        width: dataObj?.deviceWidth?.isMobile ? "100%" : "33%",
        padding: dataObj?.deviceWidth?.isMobile ? "12px" : "24px",
        display: "flex",
        justifyContent: dataObj?.deviceWidth?.isMobile
          ? "space-around"
          : "flex-start",
        flexDirection: dataObj?.deviceWidth?.isMobile
          ? "row-reverse"
          : "column",
        alignItems: "center",
      }}
    >
      <Button
        disabled={isEmptyContext("registration")}
        fullWidth={dataObj?.deviceWidth?.isMobile ? false : true}
        onClick={handleSubmit}
        size={dataObj?.deviceWidth?.isMobile ? "small" : "large"}
        sx={{
          textTransform: "none",
          maxHeight: dataObj?.deviceWidth?.isMobile ? "40px" : undefined,
          whiteSpace: "break-spaces",
        }}
        variant="contained"
      >
        Перейти к оформлению
      </Button>

      {(totalPrice > 0 || sale > 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingTop: dataObj?.deviceWidth?.isMobile ? undefined : "12px",
            width: dataObj?.deviceWidth?.isMobile ? undefined : "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 12px",
              gap: "20px",
            }}
          >
            {totalPrice > 0 ? (
              <React.Fragment>
                {!dataObj?.deviceWidth?.isMobile && (
                  <Typography sx={{ textAlign: "right" }} variant="h6">
                    Цена:
                  </Typography>
                )}
                <Typography
                  sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                  variant="h6"
                >
                  {totalType === "min_price" || totalType === "empty_price"
                    ? "от "
                    : ""}
                  {totalPrice} руб.
                </Typography>
              </React.Fragment>
            ) : (
              totalType === "empty_price" && (
                <Typography sx={{ textAlign: "right" }} variant="h6">
                  Цена по запросу
                </Typography>
              )
            )}
          </Box>

          {sale > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                padding: "0px 12px 0.4rem 12px",
                gap: "4px",
              }}
            >
              {!dataObj?.deviceWidth?.isMobile && (
                <Typography sx={{ textAlign: "right" }} variant="body1">
                  Скидка:
                </Typography>
              )}
              <Typography
                color="error"
                sx={{ textAlign: "right", whiteSpace: "nowrap" }}
                variant="body1"
              >
                - {sale} руб.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}

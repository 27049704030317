import { HeaderRow } from "components/HeaderRow";
import { MapBlock } from "components/MapBlock";
import { PromotionSlider } from "components/sliders/PromotionSlider";
import { GetData } from "contexts/DataContext";
import { PromoList } from "pages/promotions/list/PromoList";
import React from "react";

export function MainFooter() {
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      <HeaderRow
        boxShadow="none"
        navigateOnClick="/departments"
        text="Контакты"
      />
      <MapBlock />
      {dataObj?.includeBeta && (
        <React.Fragment>
          <HeaderRow
            boxShadow="none"
            navigateOnClick="/promotions"
            text="Акции"
          />
          {dataObj?.deviceWidth?.isMobile ? (
            <PromoList fromMain={true} />
          ) : (
            <PromotionSlider fromMain={false} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

import { useMutation } from "@apollo/client";
import { SEARCH_DNS } from "components/gql/gql_queries";
import { consoleBeauty, logGQLError } from "data/functions";

export function SearchDnsMutation() {
  const queryName = "SearchDns";
  const [mutation, variables] = useMutation(SEARCH_DNS, {
    onCompleted: (data) => {
      if (
        data?.acc_searchDnsHostLink.code === 200 &&
        data?.acc_searchDnsHostLink?.data?.companyUUID
      ) {
        consoleBeauty(
          "Найдена компания по домену\n" +
            data?.acc_searchDnsHostLink?.data?.companyUUID,
          "GraphQL",
          "darkgreen",
        );
      } else {
        logGQLError(queryName, data?.acc_searchDnsHostLink);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}

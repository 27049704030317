import React from "react";
import { useLocation } from "react-router-dom";

import reduceChildRoutes from "components/sidebar/reduceChildRoutes";

const SidebarNavList = (props) => {
  const { depth, pages } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ currentRoute, depth, items, page }),
    [],
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;

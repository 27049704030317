import { Box } from "@mui/material";

import FindBlock from "pages/services/list/FindBlock";
import { FolderPage } from "pages/services/list/FolderPage";
import ServiceTitle from "pages/services/list/ServiceTitle";
import React from "react";

export default function ServicesMain(props) {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <ServiceTitle />

        <Box sx={{ overflow: "auto", position: "relative" }}>
          <FindBlock />
          <FolderPage />
        </Box>
      </Box>
    </React.Fragment>
  );
}

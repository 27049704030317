// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import InDevelop from "components/InDevelop";
import Home from "pages/Home";
import AdminMainPage from "pages/admin/MainPage";
import AuthMain from "pages/auth/AuthMain";
import BasketPage from "pages/basket/Main";
import CarAdd from "pages/cars/add/Main";
import CarProfile from "pages/cars/info/Main";
import CarList from "pages/cars/list/Main";
import Chat from "pages/chat/Chat.js";
import LoyaltyMain from "pages/credits/LoyaltyMain";
import DealAdd from "pages/deal/Main";
import DepartmentProfile from "pages/departments/info/Main";
import Departments from "pages/departments/list/Main";
import ErrorPage from "pages/error-page";
import MainPage from "pages/main/MainPage";
import OrderProfile from "pages/orders/info/Main";
import OrderList from "pages/orders/list/Main";
import { PrivacyPage } from "pages/privacy/Main";
import Profile from "pages/profile/info/Main";
import PromotionProfile from "pages/promotions/info/Main";
import PromoMain from "pages/promotions/list/PromoList";
import Recs from "pages/recs/Recs";
import ServiceProfile from "pages/services/info/Main";
import ServicesMain from "pages/services/list/Main";

import { createBrowserRouter } from "react-router-dom";
export const router = createBrowserRouter([
  {
    children: [
      {
        element: <MainPage />,
        path: "/",
      },

      {
        element: <Recs />,
        path: "recs",
      },

      {
        element: <Chat />,
        path: "chat",
      },
      {
        element: <DealAdd />,
        path: "request",
      },
      {
        element: <BasketPage />,
        path: "basket",
      },
      {
        // element: ,
        children: [
          {
            element: <CarProfile />,
            index: true,
            path: ":id",
          },
          {
            element: <CarAdd />,
            path: "add",
          },
          {
            element: <CarList />,
            path: "",
          },
        ],
        path: "/cars",
      },
      {
        // element: ,
        children: [
          {
            element: <AdminMainPage />,
            path: "",
          },
        ],
        path: "/admin",
      },
      // {
      //   path: "cars/:id",
      //   element: <CarProfile />,
      // },
      // {
      //   path: "cars/add",
      //   element: <CarAdd />,
      // },
      {
        children: [
          {
            element: <OrderProfile />,
            path: ":id",
          },
          {
            element: <OrderList />,
            path: "",
          },
        ],
        path: "orders",
      },

      {
        // element: <ServicesMain />,
        children: [
          {
            element: <ServiceProfile />,
            path: ":id",
          },
          {
            element: <ServicesMain />,
            path: "",
          },
        ],
        path: "services",
      },
      {
        children: [
          {
            element: <PromotionProfile />,
            path: ":id",
          },
          {
            element: <PromoMain />,
            path: "",
          },
        ],
        path: "promotions",
      },
      {
        element: <LoyaltyMain />,
        path: "credits",
      },
      {
        children: [
          {
            element: <DepartmentProfile />,
            path: ":id",
          },
          {
            element: <Departments />,
            path: "",
          },
        ],
        path: "departments",
      },
      {
        element: <Profile />,
        path: "profile",
      },
    ],
    element: <Home />,
    errorElement: <ErrorPage />,
    path: "",
  },
  {
    element: <PrivacyPage />,
    path: "privacy",
  },
  {
    element: <AuthMain />,
    errorElement: <ErrorPage />,
    path: "auth",
  },
]);

import React, { useEffect, useState } from "react";

import { Box, Paper } from "@mui/material";
import { CarSelectBlock } from "components/CarSelectBlock";
import { PageTitle } from "components/PageTitle";
import { RegistrationBlock } from "components/registration/RegistrationBlock";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isIdentical, isObjectEmpty, roundMinutes } from "data/functions";
import CommentField from "pages/deal/fields/CommentField";
import DateField from "pages/deal/fields/DateField";
import DepField from "pages/deal/fields/DepField";
import PhoneField from "pages/deal/fields/PhoneField";
import PromocodeField from "pages/deal/fields/PromocodeField";
import RegionField from "pages/deal/fields/RegionField";
import { useLocation } from "react-router-dom";
import { DealButton } from "./DealButton";

export default function DealAdd() {
  const { dataObj } = GetData();

  const { cInfo } = GetCInfo();

  const [dealAddData, setDealAddData] = useState({});

  // useEffect(() => {
  //   console.log(dealAddData);
  //   // eslint-disable-next-line
  // }, [dealAddData]);

  const location = useLocation();

  useEffect(() => {
    setDataFromDataArr();
    // eslint-disable-next-line
  }, [
    dataObj.clientName,
    cInfo?.departments,
    dataObj.usedPhone,
    cInfo?.preferedRegion,
  ]);

  function setDataFromDataArr() {
    if (!dealAddData?.date) {
      changeDealAddData(roundMinutes(new Date(Date.now())), "date");
    }

    if (dataObj.clientName) {
      changeDealAddData(dataObj?.clientName, "name");
    }
    if (!isObjectEmpty(cInfo?.departments)) {
      changeDealAddData(cInfo?.departments, "departments");
      if (location?.state && location?.state?.departmentItem) {
        changeDealAddData(location?.state?.departmentItem, "department");
        changeDealAddData(location?.state?.departmentItem?.region, "region");
        location.state = {};
      } else {
        if (cInfo?.preferedRegion?.name) {
          changeDealAddData(cInfo?.preferedRegion?.name, "region");
          let find = cInfo?.departments.find(
            (dep) =>
              (dep?.region || "") === (cInfo?.preferedRegion?.name || ""),
          );
          if (find) {
            changeDealAddData(find, "department");
          } else changeDealAddData(null, "department");
        } else {
          changeDealAddData(cInfo?.departments[0]?.region, "region");
          changeDealAddData(cInfo?.departments[0], "department");
        }
      }
    }
    if (dataObj.usedPhone) {
      changeDealAddData(dataObj?.usedPhone, "usedPhone");
    }
  }

  function changeDealAddData(value, param) {
    if (!isIdentical(dealAddData[param], value)) {
      setDealAddData((e) => {
        return {
          ...e,
          [param]: value,
        };
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <PageTitle title="Запись на ремонт" />
      <CarSelectBlock />
      <Box sx={{ overflow: "auto" }}>
        <Box component="form" sx={{ padding: "0 20px 0px 20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "unset",
            }}
          >
            <RegionField
              department={dealAddData?.department}
              region={dealAddData?.region}
              setDepartment={(e) => changeDealAddData(e, "department")}
              setRegion={(e) => changeDealAddData(e, "region")}
            />
            <DepField
              department={dealAddData?.department}
              departments={dealAddData?.departments}
              region={dealAddData?.region}
              setDepartment={(e) => changeDealAddData(e, "department")}
            />
          </Box>
          <Paper
            sx={{
              overflow: "auto",
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <RegistrationBlock />
          </Paper>
          {dataObj?.includeBeta && (
            <PromocodeField
              promocode={dealAddData?.promocode}
              setPromocode={(e) => changeDealAddData(e, "promocode")}
            />
          )}
          <DateField
            date={dealAddData?.date}
            setDate={(e) => changeDealAddData(e, "date")}
          />
          <PhoneField
            setUsedPhone={(e) => changeDealAddData(e, "usedPhone")}
            usedPhone={dealAddData?.usedPhone}
            // logined={logined}
          />
          <CommentField
            comment={dealAddData?.comment}
            filesArray={dealAddData?.filesArray}
            setComment={(e) => changeDealAddData(e, "comment")}
            setFilesArray={(e) => changeDealAddData(e, "filesArray")}
          />
        </Box>
      </Box>
      <DealButton dealAddData={dealAddData} />
    </Box>
  );
}

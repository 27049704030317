import { useQuery } from "@apollo/client";
import { EXPORT_CREDIT_HISTORY } from "components/gql/gql_queries_export";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
} from "data/functions";

export function GetCreditsHistoryQuery() {
  const queryName = "CreditsHistory";
  const { dataObj, forceSetDataObj, setDataObj } = GetData();
  const { token } = GetAuthData();
  const query = useQuery(EXPORT_CREDIT_HISTORY, {
    skip: !token,
    onCompleted: (data) => {
      if (isObjectEmpty(data?.export_creditHistory)) {
        forceSetDataObj([], "creditHistory");
      } else if (
        !isIdentical(data?.export_creditHistory, dataObj?.creditHistory)
      ) {
        consoleBeauty(
          "Запрос истории по бонусам. Успех.",
          "GraphQL",
          "darkgreen",
        );
        setDataObj(data.export_creditHistory, "creditHistory");
      }
    },
    onError: (e) => {
      forceSetDataObj([], "creditHistory");
      logGQLError(queryName, e);
    },
  });
  return query;
}

import styled from "@emotion/styled";
import {
  AudioFileOutlined,
  CancelRounded,
  FolderZipOutlined,
  ListAltOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material/";
import { ExportUploadUrlQuery } from "components/gql/queris/ExportUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { uploadFile } from "tools/requests";

const Input = styled("input")({
  display: "none",
});

const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export function DealFilesComponent(props) {
  const [files, setFiles] = useState(props?.filesArray || []);
  const [queryUpload] = ExportUploadUrlQuery();
  const { setAlert } = GetAlertData();

  useEffect(() => {
    // console.log(files);
    try {
      props?.setArray(files);
    } catch (e) {}
    // eslint-disable-next-line
  }, [files]);

  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [dialogType, setDialogType] = useState();

  const [URL, setURL] = useState([]);
  const openImageViewer = useCallback((index, url, type) => {
    setURL([url]);
    setIsViewerOpen(true);
    setDialogType(type);
  }, []);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    changeImageArray(e.dataTransfer.files);
    e.dataTransfer.value = null;
  };

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };

  async function changeImageArray(array) {
    if (array && array[0] && cInfo?.UUID) {
      let imgArr = [];
      for (let i = 0; i < array.length; i++) {
        // showLoading();
        if (array[i]?.type === "application/x-msdownload") {
          setAlert("Исполнительные файлы не разрешены", "warning");
          continue;
        }
        let mb = array[i]?.size / (1024 * 1024);
        if (mb > 30) {
          setAlert("Файлы размером более 30 МБ не разрешены", "warning");
          continue;
        }
        let { data } = await queryUpload({
          variables: {
            companyUuid: cInfo?.UUID,
            filename: array[i].name,
            prefix: "deals",
          },
        });
        if (data?.export_uploadUrl?.fileID) {
          let success = await uploadFile(data?.export_uploadUrl, array[0]);
          if (!success) {
            setAlert("Не удалось загрузить файл", "warning");
          } else {
            let obj = {
              fileData: array[i],
              fileId: data?.export_uploadUrl?.fileID,
            };
            imgArr.push(obj);
          }
          //There wll be a func upload file
        }
        if (imgArr.length > 0) {
          setFiles([...files, ...imgArr]);
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Box
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <Input
          className="input-file-upload"
          id={`input-file-upload ${props.variant}`}
          multiple={true}
          onChange={handleChange}
          ref={inputRef}
          type="file"
        />
        <label
          className={
            dragActive ? "label-file-upload drag-active " : "label-file-upload"
          }
          htmlFor={`input-file-upload ${props.variant}`}
          id={`label-file-upload ${props.variant}`}
        >
          <Typography>
            Перетащите файлы в эту область или загрузите по нажатию
          </Typography>
        </label>

        {dragActive && (
          <DragFileElement
            id={`drag-file-element ${props.variant}`}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </Box>
      {files && files.length > 0 && (
        <Box sx={{ marginTop: "1rem", display: "flex", flexWrap: "wrap" }}>
          {files.map((file, key) => {
            return (
              <Box
                key={key}
                sx={{
                  margin: "4px",
                  position: "relative",
                  maxWidth: "125px",
                  maxHeight: "125px",
                  height: "max-content",
                  width: "max-content",
                  boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title={file?.fileData?.name}>
                  {file?.fileData?.type.includes("image") ? (
                    <img
                      alt=""
                      onClick={() =>
                        openImageViewer(
                          key,
                          window.URL.createObjectURL(file?.fileData),
                          "image",
                        )
                      }
                      src={window.URL.createObjectURL(file?.fileData)}
                      style={{
                        // margin: "4px",
                        maxWidth: "100px",
                        maxHeight: "125px",
                        overflow: "hidden",
                      }}
                    />
                  ) : file?.fileData?.type.includes("compressed") ? (
                    <FolderZipOutlined sx={{ width: "36px", height: "36px" }} />
                  ) : file?.fileData?.type.includes("document") ||
                    file?.fileData?.type.includes("text") ? (
                    <ListAltOutlined sx={{ width: "36px", height: "36px" }} />
                  ) : file?.fileData?.type.includes("video") ? (
                    <VideoFileOutlined
                      // onClick={() =>
                      //   openImageViewer(
                      //     key,
                      //     window.URL.createObjectURL(file?.fileData),
                      //     "video",
                      //   )
                      // }
                      sx={{ width: "36px", height: "36px" }}
                    />
                  ) : file?.fileData?.type.includes("audio") ? (
                    <VideoFileOutlined sx={{ width: "36px", height: "36px" }} />
                  ) : (
                    <AudioFileOutlined sx={{ width: "36px", height: "36px" }} />
                  )}
                </Tooltip>
                <IconButton
                  // disableRipple
                  color="error"
                  onClick={() => {
                    let newArr = [...files].filter((val) => val !== file);
                    setFiles(newArr);
                  }}
                  sx={{
                    "&.MuiIconButton-root": {
                      padding: "0px",
                    },
                    position: "absolute",
                    top: -6,
                    right: -6,
                  }}
                >
                  <CancelRounded
                    sx={{
                      background: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "50%",
                      fill: "white",
                      width: "0.96em",
                      height: "0.96em",
                    }}
                  />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      )}

      {isViewerOpen && (
        <Box
          onClick={() => setIsViewerOpen(false)}
          onTouchEnd={() => setIsViewerOpen(false)}
        >
          {dialogType === "image" && (
            <ImageViewer
              src={URL}
              // currentIndex={currentImage}

              backgroundStyle={{
                width: dataObj?.deviceWidth?.isMobile
                  ? "100%"
                  : "calc(100% - 256px)",
                left: dataObj?.deviceWidth?.isMobile ? "0" : "256px",
                backgroundColor: "rgba(0,0,0,0.7)",
              }}
              closeComponent={<></>}
              closeOnClickOutside={true}
              disableScroll={true}
              onClose={() => setIsViewerOpen(false)}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

import { List } from "@mui/material";
import { CarAddBlock } from "components/CarAddBlock";
import CarListItem from "components/CarListItem";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import CarListTitle from "pages/cars/list/CarListTitle";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

export default function CarList() {
  const { dataObj } = GetData();
  const [carArr, setCarArr] = useState(dataObj?.carArr || []);
  return (
    <React.Fragment>
      <CarListTitle setCarArr={setCarArr} title="Мои автомобили" />
      <List>
        {!isObjectEmpty(carArr) &&
          carArr.map((el, index) => {
            return (
              <CarListItem el={el} index={index} key={index} navigate={true} />
            );
          })}
        <CarAddBlock />
      </List>
      <Outlet />
    </React.Fragment>
  );
}

import { useMutation } from "@apollo/client";
import { REJECT_RECOMMENDATIONS } from "components/gql/gql_queries";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, logGQLError } from "data/functions";

export function RejectRecsMutation() {
  const queryName = "RejectRecs";
  const { setAlert } = GetAlertData();

  const [rejectRecommendation, rejectRecommendationVars] = useMutation(
    REJECT_RECOMMENDATIONS,
    {
      onCompleted: (data) => {
        // console.log(data.recommendationReject.message);
        if (data.export_recommendationReject.code === 200) {
          setAlert("Рекомендации удалены", "success");
          consoleBeauty(
            "Успешное удаление рекомендаций",
            "GraphQL",
            "darkgreen",
          );
        } else {
          logGQLError(queryName, data?.export_recommendationReject);
        }
      },
      onError: (e) => {
        logGQLError(queryName, e);
        setAlert("Не удалось удалить рекомендации", "error");
      },
    },
  );
  return [rejectRecommendation, rejectRecommendationVars];
}

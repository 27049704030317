import { Settings, ShoppingBasket } from "@mui/icons-material/";
import { Badge, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { CarSelectBlock } from "components/CarSelectBlock";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import { FlexColumn } from "data/styleGlobals";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ServiceTitle(props) {
  const [hide, setHide] = React.useState(true);
  return (
    <React.Fragment>
      <Grid container spacing={0} sx={{ alignItems: "center" }}>
        <Grid item xs={8}>
          <PageTitle alignStart={true} title="Услуги" />
        </Grid>
        <Grid item xs={2}>
          <BasketButton />
        </Grid>
        <Grid item xs={2}>
          <CarButton hide={hide} setHide={setHide} />
        </Grid>
      </Grid>
      {!hide && <CarSelectBlock />}
    </React.Fragment>
  );
}

function BasketButton(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <IconButton
      disableRipple
      onClick={() => {
        navigate("/basket");
      }}
      size="small"
      sx={{
        ...FlexColumn,
        width: "100%",
        borderRadius: "200px",

        //
      }}
    >
      <Tooltip title={"Перейти в корзину"}>
        <Badge badgeContent={dataObj.currentBasketCount} color="primary">
          <ShoppingBasket
            sx={{
              animation:
                (dataObj.currentBasketCount || 0) > 0
                  ? "wobble 3s infinite"
                  : "none",
              borderRadius: "20px",
            }}
          />
        </Badge>
      </Tooltip>
      {!dataObj?.deviceWidth?.isMobile && (
        <Typography sx={{ fontSize: "12px" }} variant="body1">
          {"Корзина"}
        </Typography>
      )}
    </IconButton>
  );
}

function CarButton(props) {
  const { dataObj } = GetData();

  return (
    <IconButton
      disableRipple
      onClick={() => {
        props?.setHide((prev) => !prev);
      }}
      sx={{
        ...FlexColumn,
        width: "100%",
        borderRadius: "200px",
      }}
    >
      <Tooltip
        title={
          props?.hide ? "Показать выбор автомобиля" : "Скрыть выбор автомобиля"
        }
      >
        <Settings />
      </Tooltip>

      {!dataObj?.deviceWidth?.isMobile && (
        <Typography sx={{ fontSize: "12px" }} variant="body1">
          {props?.hide
            ? "Показать выбор автомобиля"
            : "Скрыть выбор автомобиля"}
        </Typography>
      )}
    </IconButton>
  );
}

import { Description } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GarantDialog } from "components/GarantDialog";
import { ReviewDialog } from "components/ReviewDialog";
import { GetData } from "contexts/DataContext";
import { isOrderStatusActive, translateOrderType } from "data/functions";
import React, { useState } from "react";
import { OpenMapButton } from "./OpenMapButton";

export function OrderButtons({ department, docItem }) {
  const [openReview, setOpenReview] = useState(false);
  const [openGarant, setOpenGarant] = useState(false);
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      {isOrderStatusActive(docItem?.status) && (
        <OpenMapButton department={department} docItem={docItem} />
      )}
      {dataObj.includeBeta && !isOrderStatusActive(docItem?.status) && (
        <Button
          fullWidth
          onClick={() => setOpenReview(true)}
          sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
          variant="outlined"
        >
          Отправить отзыв
        </Button>
      )}
      {dataObj.includeBeta && !isOrderStatusActive(docItem?.status) && (
        <Button
          fullWidth
          onClick={() => setOpenGarant(true)}
          sx={{ margin: "0.4rem 0px 0.4rem 0px" }}
          variant="outlined"
        >
          Запрос по гарантии
        </Button>
      )}
      <ReviewDialog
        open={openReview}
        setOpen={setOpenReview}
        title={
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Description color="dimblue" />
            {translateOrderType(docItem?.type) + " №" + docItem?.docNumber}
          </Box>
        }
      />
      <GarantDialog item={docItem} open={openGarant} setOpen={setOpenGarant} />
    </React.Fragment>
  );
}

import { Box } from "@mui/material";
import { GetCInfo } from "contexts/CompanyContext";
import { GetMetrika } from "contexts/MetrikaContext";

import FindBlock from "pages/services/list/FindBlock";
import { FolderPage } from "pages/services/list/FolderPage";
import ServiceTitle from "pages/services/list/ServiceTitle";
import React from "react";

export default function ServicesMain(props) {
  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ ServicesMain: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <ServiceTitle />

        <Box sx={{ overflow: "auto", position: "relative" }}>
          <FindBlock />
          <FolderPage />
        </Box>
      </Box>
    </React.Fragment>
  );
}

import { useLazyQuery } from "@apollo/client";
import { DECODE_PLATE } from "components/gql/gql_queries";
import { consoleBeauty, logGQLError } from "data/functions";

export function GetCarReportQuery() {
  const queryName = "CarReport";
  const [refetch, variables] = useLazyQuery(DECODE_PLATE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: () => {
      consoleBeauty(
        "Получен ответ по расшифровке ГИБДД",
        "GraphQL",
        "darkgreen",
      );
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

import { useLazyQuery } from "@apollo/client";
import { GIBDD_UPLOAD_URL } from "components/gql/gql_queries";

import { GetAuthData } from "contexts/TAuthContext";
import { logGQLError } from "data/functions";

export function GibddUploadUrlQuery() {
  const queryName = "GibddUploadUrl";
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(GIBDD_UPLOAD_URL, {
    skip: !token,

    onError: (e) => {
      logGQLError(queryName, e);
      // setLogined(false);
    },
  });
  return [refetch, variables];
}

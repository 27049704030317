import { useMutation } from "@apollo/client";
import { OIDC_LOGIN } from "components/gql/gql_queries";
import { logGQLError } from "data/functions";

export function OidcLoginMutation() {
  const queryName = "OidcLogin";

  const [mutation, variables] = useMutation(OIDC_LOGIN, {
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}

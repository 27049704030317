import { PhoneAndroid } from "@mui/icons-material";
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import { TextMaskCustom } from "data/styleGlobals";
import PropTypes from "prop-types";
import React from "react";
TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PhoneField(props) {
  const { dataObj } = GetData();
  return (
    <TextField
      // fullWidth
      InputProps={{
        // readOnly: props.logined ? props.logined : false,
        style: {
          // width: props.name?.length
          //   ? "calc(" + props.name?.length + "px * 11)"
          //   : undefined,
          fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
        },

        disableUnderline: props.logined ? props.logined : false,
        startAdornment: (
          <InputAdornment position="start">
            <PhoneAndroid />
          </InputAdornment>
        ),
        inputComponent: TextMaskCustom,
      }}
      autoFocus={props?.autoFocus || undefined}
      // // size="small"
      error={!props.usedPhone}
      fullWidth={true}
      label="Номер телефона"
      mb={2}
      mt={2}
      name="phone"
      onChange={(e) => props.setUsedPhone(e.target.value?.replace(/\D/g, ""))}
      // helperText={!props.usedPhone ? "Введите номер телефона" : ""}
      sx={{
        minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "48%",
        margin: "0.3rem 0",
      }}
      type="text"
      value={props.usedPhone || "+7"}
      variant="standard"
    >
      {/* <InputMask mask="(0)999 999 99 99" maskChar=" " /> */}
    </TextField>
  );
}

import { useMutation } from "@apollo/client";
import { PAYMENT_CREATE } from "components/gql/gql_queries";
import { logGQLError } from "data/functions";

export function ExtPaymentCreateMutation() {
  const queryName = "ExtPaymentCreate";
  const [mutation, variables] = useMutation(PAYMENT_CREATE, {
    onCompleted: (data) => {
      if (data?.export_paymentCreate.code === 200) {
      } else {
        logGQLError(queryName, data?.export_paymentCreate);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}

import { Add } from "@mui/icons-material";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export function CarAddBlock() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <ListItem
        onClick={() => navigate("/cars/add")}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(0 0 0 / 5%)",
            cursor: "pointer",
          },
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              background: "transparent",
              boxShadow: "inset 0px 0px 2px #00000026",
            }}
          >
            <Add color="dimblue" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Добавить автомобиль" />
      </ListItem>
    </React.Fragment>
  );
}

import { StarOutline } from "@mui/icons-material/";
import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

export function CreditBlock(props) {
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {cInfo?.loyalty?.used && (
        <Tooltip title={"Бонусы"}>
          <Box
            onClick={() => navigate("/credits")}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "nowrap",
              margin: "0px 6px",
              cursor: "pointer",
            }}
            xs={2}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "2px",
                borderTop: "1px solid",
                borderBottom: "1px solid",
                borderLeft: "1px solid",
                borderRight: isObjectEmpty(dataObj?.creditBalance)
                  ? "1px solid"
                  : undefined,
                borderRadius: !isObjectEmpty(dataObj?.creditBalance)
                  ? "0.4rem 0px 0px 0.4rem"
                  : "0.4rem",
                height: "24px",
              }}
            >
              <StarOutline sx={{ width: "0.6em", height: "0.6em" }} />
            </Box>
            {!isObjectEmpty(dataObj?.creditBalance) && (
              <Typography
                sx={{
                  padding: "1px 4px",
                  borderTop: "1px solid",
                  borderBottom: "1px solid",
                  borderRight: "1px solid",
                  borderRadius: "0px 0.4rem 0.4rem 0px",
                  height: "24px",
                }}
                variant="body2"
              >
                {dataObj?.creditBalance}
              </Typography>
            )}
          </Box>
        </Tooltip>
      )}
    </React.Fragment>
  );
}

import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

export default function FieldYear(props) {
  return (
    <Box sx={{ margin: "1rem 1rem" }}>
      <DatePicker
        disableFuture
        label={"Год выпуска"}
        onChange={(e) => {
          props?.setCarAddData((el) => {
            return { ...el, year: e };
          });
        }}
        openTo="year"
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "standard",
            helperText: props?.helperText?.year
              ? props?.helperText?.year
              : undefined,
          },
        }}
        value={props?.carAddData?.year || null}
        views={["year"]}
      />
    </Box>
  );
}

import { useLazyQuery } from "@apollo/client";
import { EXPORT_ORDER_READ } from "components/gql/gql_queries_export";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, findInArray, logGQLError } from "data/functions";
import { isObjectEmpty } from "data/functions";

export function OrderReadQuery() {
  const queryName = "OrderRead";
  const { dataObj, pushDataObj } = GetData();
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(EXPORT_ORDER_READ, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onCompleted: (data) => {
      onCompletedOrderRead(data, dataObj, pushDataObj);
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

export function onCompletedOrderRead(data, dataObj, pushDataObj) {
  if (isObjectEmpty(data?.export_orderRead?.orderID)) return;

  let order = findInArray(
    dataObj?.orders,
    data?.export_orderRead?.orderID,
    "orderID",
  );
  if (!order) {
    consoleBeauty(
      "Получена информация по " + data?.export_orderRead?.orderID,
      "GraphQL",
      "darkgreen",
    );
    pushDataObj(data?.export_orderRead, "orders", []);
  }
}

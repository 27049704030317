import { isObjectEmpty } from "data/functions";
import { AdminPanel } from "pages/admin/AdminPanel";
import { AuthAdmin } from "pages/admin/AuthAdmin";
import React from "react";

export default function AdminMainPage() {
  const [adminToken, setAdminToken] = React.useState();

  return (
    <React.Fragment>
      {!isObjectEmpty(adminToken) && adminToken?.accessToken ? (
        <AdminPanel
          accessTokenExpires={adminToken?.accessTokenExpires}
          adminToken={adminToken?.accessToken}
          setAdminToken={setAdminToken}
        />
      ) : (
        <AuthAdmin setAdminToken={setAdminToken} />
      )}
    </React.Fragment>
  );
}

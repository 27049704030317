import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { GetData } from "contexts/DataContext";
import {
  assignedArray,
  changeInArray,
  getFirstInArray,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
} from "data/functions";

import {
  AccountCircle,
  AlternateEmail,
  Apartment,
  Event,
} from "@mui/icons-material/";
import { LoadingButton } from "@mui/lab";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { UserUpdateMutation } from "components/gql/mutations/UpdateUser";
import { SelfInfoQuery } from "components/gql/queris/GetSelfInfo";
import { GetCInfo } from "contexts/CompanyContext";

export const ChangeProfile = (props) => {
  const [changeList, setChangeList] = useState([]);
  const { dataObj } = GetData();
  const { cInfo, setCInfo } = GetCInfo();
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [email, setEmail] = useState("");
  const [birthdayDate, setBirthdayDate] = useState();
  const [gender, setGender] = useState("");
  const [userUpdate, userUpdateVars] = UserUpdateMutation();
  const [selfInfoQuery] = SelfInfoQuery();

  async function sendChanges() {
    if (
      !isObjectEmpty(changeList) ||
      !isObjectEmpty(dataObj?.clientInfoArray?.telegram)
    ) {
      let variables = {
        input: {
          companyUUID: cInfo?.UUID,
          userID: dataObj?.clientID,
        },
      };
      if (!isObjectEmpty(changeList)) {
        Object.keys(changeList).forEach((key) => {
          if (key !== "region")
            variables = {
              ...variables,
              input: {
                ...variables.input,
                [key]:
                  key === "birthday"
                    ? new Date(changeList[key] || 0).toISOString()
                    : changeList[key],
              },
            };
        });
      } else if (!isObjectEmpty(dataObj?.clientInfoArray?.telegram)) {
        variables = {
          ...variables,
          input: {
            ...variables.input,
            telegram: dataObj?.clientInfoArray?.telegram,
          },
        };
      }
      //console.log(variables);
      let { data } = await userUpdate({
        variables: variables,
      });
      if (data?.export_userUpdate?.success) {
        props.setOpen(false);
        selfInfoQuery({
          variables: {
            companyUuid: cInfo?.UUID,
          },
        });
      }
    } else {
      props.setOpen(false);
    }
  }

  useEffect(() => {
    onOpen();
    // eslint-disable-next-line
  }, [dataObj?.clientInfoArray, props.open]);

  function onOpen() {
    setName(dataObj?.clientName);
    setRegion(cInfo?.preferedRegion);
    setEmail(dataObj?.clientInfoArray?.email);
    setGender(dataObj?.clientInfoArray?.sex);
    let birthdayDate = "";
    // let birthdayDate = new Date();
    if (!isObjectEmpty(dataObj?.clientInfoArray?.birthday))
      birthdayDate = parseRFC3339(dataObj?.clientInfoArray?.birthday);
    if (birthdayDate) setBirthdayDate(birthdayDate);
    else setBirthdayDate();
    setChangeList([]);
  }

  useEffect(() => {}, [name]);

  function setValue(param, value) {
    if (param === "fullName") {
      setName(value);
      addParamToArray(param, value);
    }
    if (param === "email") {
      setEmail(value);
      addParamToArray(param, value);
    }
    if (param === "birthday") {
      setBirthdayDate(value);
      addParamToArray(param, value);
    }
    if (param === "sex") {
      setGender(value);
      addParamToArray(param, value);
    }
    if (param === "region") {
      setRegion(value);
      setCInfo(value, "preferedRegion");
      // addParamToArray(param, value);
    }
  }

  function addParamToArray(param, value) {
    let arr = assignedArray(changeList);
    let data =
      param === "fullName"
        ? dataObj?.clientName
        : param === "email"
          ? dataObj?.clientInfoArray?.email
          : param === "birthday"
            ? dataObj?.clientInfoArray?.birthday
            : param === "region"
              ? cInfo?.preferedRegion
              : param === "sex"
                ? dataObj?.clientInfoArray?.sex
                : "";
    if (!isIdentical(value, data)) {
      arr = changeInArray(arr, value, param);
    } else {
      let inArr = arr.hasOwnProperty(param);
      if (inArr) delete arr[param];
    }
    setChangeList(arr);
  }

  return (
    <Dialog
      onClose={() => props.setOpen(false)}
      open={props.open}
      sx={{ zIndex: 1201 }}
    >
      <DialogTitle>Изменения профиля</DialogTitle>
      <DialogContent>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          error={!name}
          // helperText={!name ? "Заполните это поле" : ""}
          fullWidth
          label="ФИО"
          mb={2}
          mt={2}
          name="name"
          onChange={(e) => setValue("fullName", e.target.value)}
          sx={{
            minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
            margin: "0.3rem 0",
          }}
          type="text"
          value={name || ""}
          variant="standard"
        />
        {/* {dataObj?.includeBeta && ( */}
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmail />
              </InputAdornment>
            ),
          }}
          // error={!email}
          // helperText={!email ? "Заполните это поле" : ""}
          fullWidth
          label="Почта"
          mb={2}
          mt={2}
          name="email"
          onChange={(e) => setValue("email", e.target.value)}
          sx={{
            minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
            margin: "0.3rem 0",
            // display: "none",
          }}
          type="text"
          value={email || ""}
          variant="standard"
        />
        {/* )} */}
        <RegionSelectBlock
          region={region}
          regions={cInfo?.regions}
          setValue={setValue}
        />
        <FormControl fullWidth variant="standard">
          <MobileDatePicker
            defaultValue={birthdayDate || undefined}
            disableFuture
            label="День рождения"
            onChange={(e) => setValue("birthday", e)}
            slotProps={{
              textField: {
                variant: "standard",
                sx: { marginTop: 2, marginBottom: 2 },
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Event />
                    </InputAdornment>
                  ),
                },
              },
              popper: {
                placement: "top",
              },
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Пол</FormLabel>
          <RadioGroup
            onChange={(e) => {
              setValue("sex", e.target.value);
            }}
            row
            value={gender || ""}
          >
            <FormControlLabel
              control={<Radio />}
              label="Мужской"
              value="male"
            />
            <FormControlLabel
              control={<Radio />}
              label="Женский"
              value="female"
            />
            <FormControlLabel
              control={<Radio />}
              label="Не указан"
              value="other"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onOpen();
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Отмена
        </Button>
        <LoadingButton
          loading={userUpdateVars?.loading}
          onClick={() => {
            sendChanges();
          }}
          variant="outlined"
        >
          Сохранить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

function RegionSelectBlock(props) {
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      {!isObjectEmpty(props.regions) && props.regions.length > 1 && (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Apartment />
              </InputAdornment>
            ),
          }}
          autoFocus
          error={!props.region}
          fullWidth
          helperText={
            !props.region
              ? "Пожалуйста, выберите предпочитаемый регион"
              : undefined
          }
          label="Регион"
          mb={2}
          mt={2}
          name="region"
          // defaultValue=""
          select
          sx={{
            minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
            margin: "0.3rem 0",
          }}
          type="text"
          value={props.region?.name || ""}
          variant="standard"
        >
          {!isObjectEmpty(props.regions) &&
            props.regions?.map((item, index) => (
              <MenuItem
                key={index}
                onClick={(e) => props?.setValue("region", item)}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      )}
    </React.Fragment>
  );
}

import React, { useEffect } from "react";

import { ExportMessageListQuery } from "components/gql/queris/ExportMessageList";
import {
  dateToString,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
  scrollBottom,
} from "data/functions";
import { GetCInfo } from "./CompanyContext";
import { GetAuthData } from "./TAuthContext";

export const ChatContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    // case "deleteMess": {
    //   let newMessages = undefined;
    //   let existMessages = state[action.object][action.paramName] || [];
    //   newMessages = [...existMessages].filter(
    //     (item) => item?.eventID !== action.message?.eventID,
    //   );
    //   if (
    //     !isIdentical(existMessages, newMessages) &&
    //     newMessages !== undefined
    //   ) {
    //     return {
    //       ...state,
    //       messages: {
    //         ...state.messages,
    //         [action.key]: newMessages,
    //       },
    //     };
    //   } else return state;
    // }
    case "addMess": {
      let newMessages = undefined;
      let existMessages = state?.messages || [];

      let dateMess = createSystemDateMess(
        new Date(parseRFC3339(action.message?.timestamp).valueOf()),
      );
      if (!existMessages.some((item) => item?.eventID === dateMess?.eventID)) {
        newMessages = [...[], dateMess];
      }

      if (action.message) {
        action.message.unix = parseRFC3339(action.message?.timestamp).valueOf();
      }
      if (
        !existMessages.some((item) => item?.eventID === action.message?.eventID)
      )
        newMessages = [
          ...existMessages,
          ...(newMessages ?? []),
          action.message,
        ];

      if (
        !isIdentical(existMessages, newMessages) &&
        newMessages !== undefined
      ) {
        return {
          ...state,
          messages: newMessages.sort((a, b) => {
            if (a?.unix > b?.unix) {
              return -1;
            }
            if (a?.unix < b?.unix) {
              return 1;
            }
            return 0;
          }),
        };
      } else return state;
    }
    default:
      return state;
  }
};

function createSystemDateMess(time) {
  let messDate = new Date(time.setHours(0, 0, 1, 0));
  let messageDateText = dateToString(messDate, false);

  return {
    content: {
      body: messageDateText,
      fileType: "SYSTEM",
    },
    eventID: time.setHours(0, 0, 0, 0),
    timestamp: messDate,
    unix: messDate.valueOf(),
  };
}
export const initialState = {
  messages: [],
};

function ChatProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [getMessageList] = ExportMessageListQuery();
  const { cInfo } = GetCInfo();
  const { token } = GetAuthData();

  const value = {
    addMess: React.useCallback(
      (message) =>
        dispatch({
          message: message,
          type: "addMess",
        }),
      [],
    ),
    // deleteMess: React.useCallback(
    //   (key, message) =>
    //     dispatch({
    //       // key: key,
    //       message: message,
    //       type: "deleteMess",
    //     }),
    //   [],
    // ),
    messages: state.messages,
  };

  React.useEffect(() => {
    getMessages(cInfo?.UUID, token, state.messages);
    const interval = setInterval(() => {
      getMessages(cInfo?.UUID, token, state.messages);
    }, 10000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [cInfo?.UUID, token, state.messages]);

  async function getMessages(uuid, token, arr) {
    if (!uuid || !token) return;
    let { data } = await getMessageList({
      variables: {
        companyUuid: uuid,
        limit: 20,
      },
    });

    if (
      data?.chat_messageList &&
      !isObjectEmpty(data?.chat_messageList?.messages)
    ) {
      let check = false;
      let list = data?.chat_messageList?.messages;
      (list ?? []).forEach((element) => {
        let find = (arr ?? []).find((el) => el.eventID === element?.eventID);
        if (!find) {
          element["endString"] = data?.chat_messageList?.end;
          value?.addMess(element);
          check = true;
        }
      });
      if (check) {
        scrollBottom(1);
      }
    }
  }

  function addTextMess(i = 1) {
    let date = new Date(Date.now() - 1000 * 60 * 60 * i++);
    value.addMess({
      content: {
        body: "от " + date.toDateString(),
      },
      eventID: Math.floor(
        Math.random() *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20 *
          20,
      ),
      timestamp: date,
      type: "text",
    });
  }

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
export const GetChat = () => {
  const context = React.useContext(ChatContext);
  return context;
};

export { ChatProvider };

import React, { useState } from "react";

import { Box, Typography } from "@mui/material";

import { Call, Sms, Telegram } from "@mui/icons-material/";
import { LoadingButton } from "@mui/lab";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isIdentical, isObjectEmpty } from "data/functions";
import { Link } from "react-router-dom";

export const AuthMethodButtons = (props) => {
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const [channels, setChannels] = useState([]);
  React.useEffect(() => {
    if (cInfo?.channels) {
      let arr = [];
      ["Telegram", "Sms", "Phone"].forEach((element) => {
        let find = (cInfo?.channels ?? []).find((el) => {
          if (el?.name === element) return true;
          else return false;
        });
        if (find) arr = [...arr, find];
      });
      if (!isIdentical(channels, arr)) {
        setChannels(arr);
      }
      props?.setNoChannels(isObjectEmpty(arr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.channels]);

  return (
    <React.Fragment>
      {!props?.method && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "12px",
              margin: "2rem 0px",
              width: "100%",
            }}
          >
            {(channels ?? []).map((c, i) => (
              <LoadingButton
                color={props?.flowVars?.error ? "error" : "primary"}
                disabled={!c?.active}
                key={i}
                loading={props?.flowVars?.loading}
                onClick={() => {
                  props?.setMethod(c?.name.toLowerCase());
                }}
                size={dataObj?.deviceWidth?.isMobile ? "small" : undefined}
                startIcon={
                  c?.name === "Telegram" ? (
                    <Telegram />
                  ) : c?.name === "Sms" ? (
                    <Sms />
                  ) : c?.name === "Phone" ? (
                    <Call />
                  ) : undefined
                }
                variant="outlined"
              >
                {c?.name === "Sms"
                  ? "Sms"
                  : c?.name === "Phone"
                    ? "Звонок"
                    : c?.name}
              </LoadingButton>
            ))}
          </Box>
          <Typography sx={{ textAlign: "center" }} variant="caption">
            Выполняя вход в приложение, вы соглашаетесь с
            <br />
            <Link to="/privacy">политикой обработки персональных данных</Link>
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

import { Box, Button } from "@mui/material";
import React from "react";

import { GetData } from "contexts/DataContext";

import { useNavigate } from "react-router-dom";
export default function ProfileButtons(props) {
  const { dataObj, setDataObj } = GetData();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          margin: "0.5rem",
          boxShadow: "none",
          flexWrap: "wrap",
          gap: "0.2rem",
        }}
      >
        <Button
          disableRipple
          onClick={() => {
            navigate("/request");
          }}
          variant="outlined"
        >
          Записаться
        </Button>
        <Button
          disableRipple
          disabled={
            (dataObj?.recsArray ? dataObj?.recsArray : {})[props?.car?.car?.id]
              ?.length > 0
              ? false
              : true
          }
          onClick={() => {
            navigate("/recs");
          }}
          variant="outlined"
        >
          Рекомендации
        </Button>
        <Button
          disableRipple
          onClick={() => {
            setDataObj({ car: props?.car }, "orderFilter");
            navigate("/orders");
          }}
          variant="outlined"
        >
          Заказы
        </Button>
      </Box>
    </React.Fragment>
  );
}

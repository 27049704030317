import { Add } from "@mui/icons-material/";
import { Accordion, AccordionDetails, Box, Button, List } from "@mui/material";
import CarListItem from "components/CarListItem";
import { CarListRecBlock } from "components/CarListRecBlock";
import { HeaderRow } from "components/HeaderRow";
import { GetData } from "contexts/DataContext";
import { isIdentical } from "data/functions";
import { isObjectEmpty } from "data/functions";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CarLoadBlock } from "./CarLoadBlock";

export const CarListBlock = (props) => {
  const { dataObj, setDataObj } = GetData();
  const [selectedCar, setSelectedCar] = React.useState(dataObj?.usedCar || {});
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      !isObjectEmpty(dataObj?.usedCar) &&
      !isIdentical(dataObj?.usedCar, selectedCar)
    ) {
      setSelectedCar(dataObj?.usedCar);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.usedCar]);

  return (
    <React.Fragment>
      <HeaderRow
        boxShadow="none"
        buttonText={<Add />}
        navigateOnButton={"/cars/add"}
        navigateOnButtonState={{
          state: { fromMain: true },
        }}
        navigateOnClick="/cars"
        text="Автомобили"
      />
      <List sx={{ padding: "0" }}>
        {dataObj?.carsError && <CarLoadBlock />}
        {!isObjectEmpty(dataObj?.carIds) &&
          dataObj?.carIds?.map((carID, index) => {
            if (props?.limit && props?.limit > 0) {
              if (props?.limit <= index) return "";
            }
            const recs =
              dataObj?.recsArray && dataObj?.recsArray[carID]
                ? dataObj?.recsArray[carID]
                : [];
            let selected = isIdentical(carID, selectedCar?.car?.id);
            const carEl = dataObj.carArrWithSold.find((element) => {
              if (element?.car?.id === carID) return true;
              else return false;
            });
            return (
              <Box key={index} sx={{}}>
                <Accordion
                  disableGutters
                  expanded={selected ? true : false}
                  sx={{
                    backgroundColor: "transparent",
                    backgroundImage: "none",

                    border: "none",
                    boxShadow: "none",
                    margin: 0,
                  }}
                >
                  <Box
                    id={index}
                    onClick={() => {
                      setSelectedCar(carEl);
                      setDataObj(carEl, "usedCar");

                      if (selected || isObjectEmpty(recs)) {
                        navigate(`/cars/${carEl?.car?.id ?? "unknowncar"}`, {
                          state: { carID: carEl?.car?.id },
                        });
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgb(0 0 0 / 5%)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <CarListItem
                      el={carEl}
                      index={index}
                      key={index}
                      navigate={false}
                    />
                  </Box>
                  <AccordionDetails sx={{ padding: "0 1rem" }}>
                    {[...recs]
                      .sort((a, b) => (a.urgency === 1 ? 1 : -1))
                      .map((element, ind) => {
                        if (ind < 3)
                          return (
                            <CarListRecBlock key={ind} recItem={element} />
                          );
                        else {
                          return "";
                        }
                      })}
                    {recs.length > 3 && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          fullWidth
                          onClick={() => navigate("/recs")}
                          sx={{
                            textTransform: "none",
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 2px 3px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                            height: "16px",
                            margin: 0,
                            fontSize: "12px",
                          }}
                          variant="standart"
                        >
                          Показать все рекомендации
                        </Button>
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
      </List>
    </React.Fragment>
  );
};

import { useLazyQuery } from "@apollo/client";
import { EXPORT_REC_LIST } from "components/gql/gql_queries_export";

import { GetAuthData } from "contexts/TAuthContext";
import { logGQLError } from "data/functions";

export function CarRecsQuery() {
  const queryName = "CarRecs";
  const { token } = GetAuthData();
  const [query, variables] = useLazyQuery(EXPORT_REC_LIST, {
    fetchPolicy: "no-cache",
    skip: !token,
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [query, variables];
}

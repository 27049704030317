import { useLazyQuery } from "@apollo/client";
import { AUTH_SELF_DATA } from "components/gql/gql_queries_auth";
import { GetAuthData } from "contexts/TAuthContext";
import { logGQLError } from "data/functions";

export function SelfDataQuery() {
  const queryName = "SelfData";
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(AUTH_SELF_DATA, {
    // errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onError: (e) => {
      logGQLError(queryName, e);
      // setLogined(false);
    },
  });
  return [refetch, variables];
}

import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CompanyErrorPage } from "components/CompanyError";
import { SearchDnsMutation } from "components/gql/mutations/SearchDns";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import {
  getCompanyStyle,
  getPinnedServices,
  isIdentical,
  joinParams,
  saveParseJson,
  setLocalStorageItem,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import { actions } from "data/globals";
import Favicon from "react-favicon";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { GetCompanyExtDataQuery } from "components/gql/queris/GetCompanyExtData";
import { GetServices } from "components/gql/queris/GetServices";
import { GetAuthData } from "contexts/TAuthContext";
import React, { useEffect, useState } from "react";

export const CompanyContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.pushArray: {
      let stateValue = state?.cInfo[action.paramName];
      let newValue = joinParams(stateValue, action.paramValue, action.defType);

      if (!isIdentical(stateValue, newValue)) {
        setLocalStorageItem("company_" + action.paramName, newValue);
        return {
          ...state,
          cInfo: {
            ...state.cInfo,
            [action.paramName]: newValue,
          },
        };
      } else return state;
    }

    case actions.setArray: {
      let paramValue = state?.cInfo[action.paramName] || "";
      if (paramValue !== action.paramValue) {
        setLocalStorageItem("company_" + action.paramName, action.paramValue);

        return {
          ...state,
          cInfo: {
            ...state?.cInfo,
            [action.paramName]: action.paramValue,
          },
        };
      } else return state;
    }
    default:
      return state;
  }
};

export const initialState = {
  cInfo: {},
};

export function CheckSize(theme) {
  return useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });
}

function CompanyProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const searchParams = new URLSearchParams(window.location.search);
  const [searchQuery] = SearchDnsMutation();
  const [selfDataQuery] = SelfDataQuery();
  const [getCompanyExtDataQuery] = GetCompanyExtDataQuery();
  const [getServicesQuery] = GetServices();
  const [searchError, setSearchError] = useState(false);
  const [selfError, setSelfError] = useState(false);
  const { token } = GetAuthData();
  const value = {
    cInfo: state.cInfo,

    setCInfo: React.useCallback(
      (value, param) =>
        dispatch({
          paramName: param,
          paramValue: value,
          type: actions.setArray,
        }),
      [],
    ),
  };

  React.useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = undefined;
      if (key.includes("company_"))
        try {
          value = saveParseJson(localStorage.getItem(key));
          if (!isObjectEmpty(value)) {
            dispatch({
              paramName: key.replace("company_", ""),
              paramValue: value,
              type: actions.setArray,
            });
          }
        } catch {}
    }
  }, []);

  useEffect(() => {
    searchDnsHost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.host]);

  useEffect(() => {
    if (token) searchDnsHost(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  async function searchDnsHost(access_token = "") {
    let UUID = "";
    if (
      window.location.host === "link.5-systems.ru" ||
      window.location.host === "localhost:3000" ||
      window.location.host === "delta2.5-systems.ru"
    ) {
      UUID = "857f3fa2-170d-4b3b-864d-b8626e3ae7a3"; //Тестовая
      // UUID = "0b812bc9-e4e2-41b2-a977-d046f3bb0f18"; //Юры
      // UUID = "26fd5a70-606c-462b-9787-ffe5f97c38fe"; //БИк
      // UUID = "47183f0a-19c4-4faf-8ae5-78307fa68659"; //AKPP35
      // UUID = " 4074bd68-c018-44cd-8a7c-880da7b1dd35"; //Октопос

      if (state?.cInfo?.UUID !== UUID) {
        dispatch({
          paramName: "UUID",
          paramValue: UUID,
          type: actions.setArray,
        });
      }
    } else {
      if (access_token) {
        let { data, error } = await selfDataQuery();
        if (
          data &&
          !isObjectEmpty(data?.auth_getSelfData?.companyUUID) &&
          !isIdentical(data?.auth_getSelfData?.companyUUID, state?.cInfo?.UUID)
        ) {
          //
          if (state?.cInfo?.UUID !== data?.auth_getSelfData?.companyUUID) {
            setSelfError(false);
            await dispatch({
              paramName: "UUID",
              paramValue: data?.auth_getSelfData?.companyUUID,
              type: actions.setArray,
            });
            window.location.reload(false);
          }
          return;
        }
        // if (error) {
        //   setSelfError(true);
        // }
      } else {
        let { data, error } = await searchQuery({
          variables: {
            host:
              window.location.host === "localhost:3000" ||
              window.location.host === "delta2.5systems.ru"
                ? "delta2.link.5systems.ru"
                : window.location.host,
          },
        });
        if (data) {
          if (
            data?.acc_searchDnsHostLink?.code === 200 &&
            !isObjectEmpty(data?.acc_searchDnsHostLink?.data?.companyUUID) &&
            !isIdentical(
              data?.acc_searchDnsHostLink?.data?.companyUUID,
              state?.cInfo?.UUID,
            )
          ) {
            setSearchError(false);
            dispatch({
              paramName: "UUID",
              paramValue: data?.acc_searchDnsHostLink?.data?.companyUUID,
              type: actions.setArray,
            });
          }
          if (
            data?.acc_searchDnsHostLink?.code === 200 &&
            !isObjectEmpty(data?.acc_searchDnsHostLink?.data?.companyName) &&
            !isIdentical(
              data?.acc_searchDnsHostLink?.data?.companyName,
              state?.cInfo?.name,
            )
          ) {
            dispatch({
              paramName: "name",
              paramValue: data?.acc_searchDnsHostLink?.data?.companyName,
              type: actions.setArray,
            });
          }
          return;
        }
        if (error || data?.acc_searchDnsHostLink?.code !== 200) {
          setSearchError(true);
        }
      }
    }
  }

  React.useEffect(() => {
    if (state?.cInfo?.UUID) {
      getCompanyStyle(state?.cInfo?.UUID, getCompanyExtDataQuery, value);
      getPinnedServices(state?.cInfo?.UUID, getServicesQuery, value);
    }
    // eslint-disable-next-line
  }, [state?.cInfo?.UUID]);

  React.useEffect(() => {
    let companyUUID = searchParams.get("company");
    if (!isObjectEmpty(companyUUID))
      dispatch({
        paramName: "UUID",
        paramValue: companyUUID,
        type: actions.setArray,
      });
    // eslint-disable-next-line
  }, [searchParams.get("company")]);

  React.useEffect(() => {
    let channel = searchParams.get("channel");
    if (!isObjectEmpty(channel))
      dispatch({
        paramName: "channel",
        paramValue: channel,
        type: actions.setArray,
      });
    // eslint-disable-next-line
  }, [searchParams.get("channel")]);

  const favicon = document.getElementById("favicon");

  useEffect(() => {
    if (
      state?.cInfo?.style?.favicon?.url &&
      favicon.href !== state?.cInfo?.style?.favicon?.url
    ) {
      favicon.setAttribute("href", state?.cInfo?.style?.favicon?.url);
    }
  }, [state?.cInfo?.style?.favicon?.url, favicon?.href]);

  return (
    <CompanyContext.Provider value={value}>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{state?.cInfo?.title || "5S LINK"}</title>
        </Helmet>
      </HelmetProvider>
      <Box
        sx={{ display: isObjectEmpty(state?.cInfo?.UUID) ? "none" : undefined }}
      >
        {children}
      </Box>

      {isObjectEmpty(state?.cInfo?.UUID) && (selfError || searchError) && (
        <React.Fragment>
          <CompanyErrorPage
            text={
              <>
                Не удалось определить <br /> компанию
              </>
            }
          />
        </React.Fragment>
      )}
    </CompanyContext.Provider>
  );
}
export const GetCInfo = () => {
  const context = React.useContext(CompanyContext);
  return context;
};

export { CompanyProvider };

import { useTheme } from "@emotion/react";
import { MiscellaneousServices } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { getImageUrlByName, isObjectEmpty } from "data/functions";
import { SliceTextTwoRows } from "data/styleGlobals";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ServiceBlock = (props) => {
  const theme = useTheme();
  const cardStyle = {
    "@media (max-width: 310px)": {
      flexDirection: "column",
    },
    alignItems: props?.onBasket ? "center" : undefined,
    background: props?.folder ? "#ececec" : undefined,
    borderLeft: props?.borderLeft ? props?.borderLeft : undefined,
    display: props?.onBasket ? "flex" : "flex",
    flexDirection: props?.onBasket ? "row" : "column",
    justifyContent: props?.onBasket ? undefined : "space-between",
    outline: props?.check
      ? "1px solid " + theme?.custom?.telegramColors?.button_color
      : undefined,
    position: "relative",
    width: "100%",
  };
  const cardContentStyle = {
    background: props?.folder ? "#ececec" : undefined,
    bottom: props?.folder ? 0 : undefined,
    display: props?.folder ? "flex" : undefined,
    minHeight: props?.folder ? "40px" : undefined,
    padding: "4px 12px !important",
    position: props?.folder ? "absolute" : undefined,
    width: "100%",
  };
  return (
    <Box
      sx={{
        width: "100%",
        padding: props?.thereIsEl ? "6px" : undefined,
        height: "100%",
        display: props?.onBasket ? "block" : "flex",
      }}
    >
      <Card sx={cardStyle}>
        <Box
          onClick={() => {
            if (props?.item) props?.setItem(props?.item);
          }}
          sx={{
            // padding: "2px",
            background: props?.folder ? "#ececec" : undefined,
            position: "relative",
          }}
        >
          <ServiceImage {...props} />
          <ServiceIcon {...props} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          <CardContent sx={cardContentStyle}>
            <ServiceHeader {...props} />
            <ServiceDescription {...props} />
          </CardContent>
          <ServiceFooter {...props} />
        </Box>
      </Card>
    </Box>
  );
};

function ServiceHeader(props) {
  return (
    <Box
      onClick={() => {
        if (props?.item) props?.setItem(props?.item);
      }}
      sx={{
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        "@media (max-width: 310px)": {
          flexDirection: "column",
        },
        marginBottom: !props?.description ? 0 : undefined,
      }}
    >
      <ServiceTitle {...props} />
      <ServicePriceDeleteOnBasket {...props} />
    </Box>
  );
}

function ServiceImage(props) {
  return (
    <React.Fragment>
      {getImageUrlByName(props?.title) ? (
        <Box
          sx={{
            width: props?.onBasket ? "60px" : "100%",
            padding: "4px",
            height: props?.onBasket ? "60px" : undefined,
            aspectRatio: props?.onBasket ? undefined : "1 / 1",
            background: `url(${getImageUrlByName(props?.title)}) center center / 100% no-repeat local padding-box padding-box rgb(255, 255, 255)`,
          }}
        />
      ) : (
        <Box sx={{ width: "100%", background: "white", display: "flex" }}>
          <MiscellaneousServices
            sx={{
              height: props?.onBasket ? "60px" : "100%",
              width: props?.onBasket ? "60px" : "100%",
              fill: "black",
              padding: "23%",
              opacity: "0.3",
            }}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

function ServiceIcon(props) {
  return (
    <React.Fragment>
      {!props?.onBasket && (
        <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}>
          {props.iconElement}
        </Box>
      )}
    </React.Fragment>
  );
}

function ServiceDescription(props) {
  return (
    <Box
      onClick={() => {
        if (props?.item) props?.setItem(props?.item);
      }}
    >
      {props?.description && (
        <React.Fragment>
          <Box
            sx={{
              fontSize: "90%",
              ...SliceTextTwoRows,
            }}
          >
            {props?.description}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

function ServiceTitle(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
      }}
    >
      {props?.onBasket && props?.iconElement}
      <Typography
        sx={{
          fontWeight: props?.thereIsEl ? "400 !important" : "500 !important",
          wordBreak: "break-word",
          // fontWeight: "400",
          color: props?.folder ? "black" : undefined,
          lineHeight: "1.2 !important",
          typography: {
            sm: "body1",
            xs: "body2",
            md: "body2",
            lg: "body1",
          },
        }}
      >
        {props?.title}
      </Typography>
    </Box>
  );
}

function ServicePriceDeleteOnBasket(props) {
  return (
    <React.Fragment>
      {props?.onBasket && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
          }}
        >
          <Box sx={{ whiteSpace: "nowrap" }}>{props?.price}</Box>
          {props?.deleteComponent}
        </Box>
      )}
    </React.Fragment>
  );
}

function ServiceFooter(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {" "}
      {props?.thereIsEl && !props?.onBasket && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile
              ? "column"
              : "row-reverse",
            flexWrap: "wrap",
            alignItems: "flex-end",
            justifyContent: "space-between",
            padding: "4px 12px",
            gap: "2px",
          }}
        >
          {!isObjectEmpty(props?.price) && (
            <Box
              onClick={() => {
                if (props?.item) props?.setItem(props?.item);
              }}
              sx={{
                fontWeight: "500",
                lineHeight: "1.5",
                fontSize: "1em",
              }}
            >
              {props?.price}
            </Box>
          )}
          <Button
            onClick={(e) =>
              navigate(`/services/${props?.item?.id}`, {
                state: { serviceID: props?.item?.id },
              })
            }
            sx={{
              fontWeight: "400",
              lineHeight: "1.5",
              textTransform: "none",
              padding: 0,
              zIndex: 1,
              // width: "100%",
              justifyContent: "flex-end",
              fontSize: "1em",
            }}
          >
            Подробнее
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}

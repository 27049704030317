import React from "react";

import { IconButton } from "@mui/material";

import { GetData } from "contexts/DataContext";

import { LocationOn } from "@mui/icons-material";
import { showRoute } from "data/mobapp_functions";

export function MapButton(props) {
  const { dataObj } = GetData();

  return (
    <IconButton
      aria-label="На карте"
      onClick={(e) => {
        if (
          (dataObj?.platform === "Android" ||
            dataObj?.platform === "iPhone" ||
            dataObj?.platform === "iPad" ||
            dataObj?.platform === "iPod") &&
          props?.locationLatitude &&
          props?.locationLongitude
        )
          showRoute(props?.locationLatitude, props?.locationLongitude);
        else {
          let URL = "https://yandex.ru/maps/";
          if (dataObj?.locationParam) {
            URL =
              URL +
              "?rtext=" +
              dataObj?.locationParam?.location_longitude +
              "," +
              dataObj?.locationParam?.location_latitude +
              "~" +
              props.locationLongitude +
              "," +
              props.locationLatitude +
              "&rtt=auto";
          } else {
            URL =
              URL +
              "?whatshere[point]=" +
              props.locationLongitude +
              "," +
              props.locationLatitude +
              "&whatshere[zoom]=17";
          }
          window.open(URL, "_blank");
        }
      }}
    >
      <LocationOn />
    </IconButton>
  );
}

import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { GetData } from "contexts/DataContext";
import {
  assignedArray,
  changeInArray,
  getFirstInArray,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
} from "data/functions";

import {
  AccountCircle,
  AlternateEmail,
  Apartment,
  Event,
  Receipt,
} from "@mui/icons-material/";
import { LoadingButton } from "@mui/lab";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { UserUpdateMutation } from "components/gql/mutations/UpdateUser";
import { SelfInfoQuery } from "components/gql/queris/GetSelfInfo";
import { GetCInfo } from "contexts/CompanyContext";
import { GetMetrika } from "contexts/MetrikaContext";

export const ChangeProfile = (props) => {
  const [changeList, setChangeList] = useState([]);
  const { dataObj } = GetData();
  const { cInfo, setCInfo } = GetCInfo();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ ChangeProfile: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userUpdate, userUpdateVars] = UserUpdateMutation();
  const [selfInfoQuery] = SelfInfoQuery();

  async function sendChanges() {
    if (
      !isObjectEmpty(changeList)
      // ||       !isObjectEmpty(dataObj?.clientInfoArray?.telegram)
    ) {
      let variables = {
        input: {
          companyUUID: cInfo?.UUID,
          userID: dataObj?.clientID,
        },
      };
      if (!isObjectEmpty(changeList)) {
        Object.keys(changeList).forEach((key) => {
          if (key !== "region")
            variables = {
              ...variables,
              input: {
                ...variables.input,
                [key]:
                  key === "birthday"
                    ? new Date(changeList[key] || 0).toISOString()
                    : changeList[key],
              },
            };
        });
      }
      // else if (!isObjectEmpty(dataObj?.clientInfoArray?.telegram)) {
      //   variables = {
      //     ...variables,
      //     input: {
      //       ...variables.input,
      //       telegram: dataObj?.clientInfoArray?.telegram,
      //     },
      //   };
      // }
      //console.log(variables);
      let { data } = await userUpdate({
        variables: variables,
      });
      if (data?.export_userUpdate?.success) {
        props.setOpen(false);
        selfInfoQuery({
          variables: {
            companyUuid: cInfo?.UUID,
          },
        });
      }
    } else {
      props.setOpen(false);
    }
  }

  useEffect(() => {
    onOpen();
    // eslint-disable-next-line
  }, [dataObj?.clientInfoArray, props.open]);

  // useEffect(() => {
  //   console.log(changeList);
  //   // eslint-disable-next-line
  // }, [changeList]);

  function onOpen() {
    setChangeList([]);
  }

  useEffect(() => {
    let name = "";
    let lastName = changeList?.lastName
      ? changeList?.lastName
      : dataObj?.clientInfoArray?.name?.lastName;
    if (lastName) name = lastName;
    let firstName = changeList?.firstName
      ? changeList?.firstName
      : dataObj?.clientInfoArray?.name?.firstName;
    if (firstName) name = name + " " + firstName;
    let middleName = changeList?.middleName
      ? changeList?.middleName
      : dataObj?.clientInfoArray?.name?.middleName;
    if (middleName) name = name + " " + middleName;

    if (name && !isIdentical(changeList?.fullName, name))
      addParamToArray("fullName", name);
  }, [changeList?.middleName, changeList?.lastName, changeList?.firstName]);

  function setValue(param, value) {
    if (param === "region") {
      setCInfo(value, "preferedRegion");
      // addParamToArray(param, value);
    } else {
      addParamToArray(param, value);
    }
  }

  function addParamToArray(param, value) {
    let arr = assignedArray(changeList);
    let data =
      param === "fullName"
        ? dataObj?.clientInfoArray?.name?.fullName
        : param === "lastName"
          ? dataObj?.clientInfoArray?.name?.lastName
          : param === "firstName"
            ? dataObj?.clientInfoArray?.name?.firstName
            : param === "middleName"
              ? dataObj?.clientInfoArray?.name?.middleName
              : param === "email"
                ? ""
                : param === "birthday"
                  ? dataObj?.clientInfoArray?.birthday
                  : param === "region"
                    ? cInfo?.preferedRegion
                    : param === "sex"
                      ? dataObj?.clientInfoArray?.sex
                      : "";
    if (!isIdentical(value, data)) {
      arr = changeInArray(arr, value, param);
    } else {
      let inArr = arr.hasOwnProperty(param);
      if (inArr) delete arr[param];
    }
    setChangeList(arr);
    return arr;
  }

  const [isExistName, setIsExistName] = useState(
    (dataObj?.clientInfoArray?.name?.lastName ||
      dataObj?.clientInfoArray?.name?.firstName ||
      dataObj?.clientInfoArray?.name?.middleName) &&
      (changeList?.lastName !== "" ||
        changeList?.firstName !== "" ||
        changeList?.middleName !== "")
      ? false
      : true,
  );

  useEffect(() => {
    setIsExistName(
      (dataObj?.clientInfoArray?.name?.lastName ||
        dataObj?.clientInfoArray?.name?.firstName ||
        dataObj?.clientInfoArray?.name?.middleName) &&
        (changeList?.lastName ||
          changeList?.firstName !== "" ||
          changeList?.middleName !== "")
        ? false
        : true,
    );
  }, [dataObj?.clientInfoArray?.name, changeList]);

  return (
    <Dialog
      onClose={() => props.setOpen(false)}
      open={props.open}
      sx={{ zIndex: 1201 }}
    >
      <DialogTitle>Изменения профиля</DialogTitle>
      <DialogContent>
        <TextFieldBlock
          error={isExistName}
          icon={<AccountCircle />}
          label="Фамилия"
          setValue={setValue}
          title="lastName"
          value={dataObj?.clientInfoArray?.name?.lastName}
          valueChange={changeList?.lastName}
        />
        <TextFieldBlock
          error={isExistName}
          icon={<AccountCircle />}
          label="Имя"
          setValue={setValue}
          title="firstName"
          value={dataObj?.clientInfoArray?.name?.firstName}
          valueChange={changeList?.firstName}
        />
        <TextFieldBlock
          error={isExistName}
          icon={<AccountCircle />}
          label="Отчество"
          setValue={setValue}
          title="middleName"
          value={dataObj?.clientInfoArray?.name?.middleName}
          valueChange={changeList?.middleName}
        />

        {dataObj?.includeBeta && (
          <FormControl>
            <FormLabel
              sx={{ display: "flex", alignItems: "center", gap: "6px" }}
            >
              <Receipt />
              Способ передачи фискального чека
            </FormLabel>
            <RadioGroup
              defaultValue={"none"}
              onChange={(e) => {
                setValue("receipt", e.target.value);
              }}
              row
              // value={""}
            >
              <FormControlLabel control={<Radio />} label="SMS" value="sms" />
              <FormControlLabel
                control={<Radio />}
                label="Telegram"
                value="telegram"
              />
              <FormControlLabel
                control={<Radio />}
                label="Email"
                value="email"
              />
              <FormControlLabel
                control={<Radio />}
                label="Не указан"
                value="none"
              />
            </RadioGroup>
          </FormControl>
        )}
        <RegionSelectBlock
          region={cInfo?.preferedRegion}
          regions={cInfo?.regions}
          setValue={setValue}
        />
        <DateBlock
          setValue={setValue}
          value={dataObj?.clientInfoArray?.birthday}
          valueChange={changeList?.birthday}
        />
        <SexBlock
          setValue={setValue}
          value={dataObj?.clientInfoArray?.sex}
          valueChange={changeList?.sex}
        />
        <TextFieldBlock
          icon={<AlternateEmail />}
          label="Добавить почту"
          setValue={setValue}
          title="email"
          value={""}
          valueChange={changeList?.email}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onOpen();
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Отмена
        </Button>
        <LoadingButton
          loading={userUpdateVars?.loading}
          onClick={() => {
            sendChanges();
          }}
          variant="outlined"
        >
          Сохранить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

function RegionSelectBlock(props) {
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      {!isObjectEmpty(props.regions) && props.regions.length > 1 && (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Apartment />
              </InputAdornment>
            ),
          }}
          autoFocus
          error={!props.region}
          fullWidth
          helperText={
            !props.region
              ? "Пожалуйста, выберите предпочитаемый регион"
              : undefined
          }
          label="Регион"
          mb={2}
          mt={2}
          name="region"
          // defaultValue=""
          select
          sx={{
            minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
            margin: "0.3rem 0",
          }}
          type="text"
          value={props.region?.name || ""}
          variant="standard"
        >
          {!isObjectEmpty(props.regions) &&
            props.regions?.map((item, index) => (
              <MenuItem
                key={index}
                onClick={(e) => props?.setValue("region", item)}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      )}
    </React.Fragment>
  );
}

function TextFieldBlock(props) {
  const { dataObj } = GetData();
  const [value, setValue] = useState(props?.value);

  useEffect(() => {
    if (props?.valueChange) setValue(props?.valueChange);
    else setValue(props?.value);
  }, [props?.value, props?.valueChange]);

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{props?.icon}</InputAdornment>
        ),
      }}
      error={props?.error}
      // helperText={!name ? "Заполните это поле" : ""}
      fullWidth
      label={props?.label}
      mb={2}
      mt={2}
      name={props?.title}
      onChange={(e) => {
        props?.setValue(props?.title, e.target.value);
      }}
      sx={{
        minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
        margin: "0.3rem 0",
      }}
      type="text"
      value={value || ""}
      variant="standard"
    />
  );
}

function DateBlock(props) {
  const [value, setValue] = useState(props?.value);

  useEffect(() => {
    if (props?.valueChange) setValue(props?.valueChange);
    else setValue(props?.value);
  }, [props?.value, props?.valueChange]);

  return (
    <FormControl fullWidth variant="standard">
      <MobileDatePicker
        disableFuture
        label="День рождения"
        onChange={(e) => props?.setValue("birthday", e)}
        slotProps={{
          textField: {
            variant: "standard",
            sx: { marginTop: 2, marginBottom: 2 },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <Event />
                </InputAdornment>
              ),
            },
          },
          popper: {
            placement: "top",
          },
        }}
        value={parseRFC3339(value) || null}
      />
    </FormControl>
  );
}

function SexBlock(props) {
  const [value, setValue] = useState(props?.value);

  useEffect(() => {
    if (props?.valueChange) setValue(props?.valueChange);
    else setValue(props?.value);
  }, [props?.value, props?.valueChange]);

  return (
    <FormControl>
      <FormLabel>Пол</FormLabel>
      <RadioGroup
        onChange={(e) => {
          props?.setValue("sex", e.target.value);
        }}
        row
        value={value || ""}
      >
        <FormControlLabel control={<Radio />} label="Мужской" value="male" />
        <FormControlLabel control={<Radio />} label="Женский" value="female" />
        <FormControlLabel control={<Radio />} label="Не указан" value="other" />
      </RadioGroup>
    </FormControl>
  );
}

import { Box, IconButton, useTheme } from "@mui/material";
import Sidebar from "components/sidebar/Sidebar";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetDialog } from "contexts/DialogContext";
import { GetAuthData } from "contexts/TAuthContext";
import { useSwipeable } from "react-swipeable";

import {
  getFirstInArray,
  getLocalStorageItem,
  getTgPadding,
  isMobilePlatform,
  isObjectEmpty,
} from "data/functions";
import navItems from "data/menuStructure";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";

// import { setCookie } from "data/functions";

import { Fullscreen } from "@mui/icons-material";
import { GetTelegramContext, versionAtLeast } from "contexts/TelegramContext";
import { ChangeProfile } from "pages/profile/change/UserChangeDialog";

import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

function Home() {
  const tg = GetTelegramContext();
  const { setDialog } = GetDialog();
  const { cInfo } = GetCInfo();

  const handlers = useSwipeable({
    delta: { right: 80 },
    trackMouse: false,
    onSwiped: (eventData) => {
      if (
        isLogined &&
        location.pathname !== "/" &&
        eventData?.initial &&
        eventData.dir === "Right" &&
        getFirstInArray(eventData.initial)
          ? getFirstInArray(eventData.initial) < 60
          : false
      ) {
        if (
          location.pathname === "/services" &&
          (!window.history.state || window.history.state?.folder === "")
        ) {
          navigate("/", { relative: "path", replace: true, state: {} });
        } else {
          navigate(-1);
        }
      }

      // window.history.back();
    },
  });

  if (!window.location.hash && window.addEventListener) {
    window.addEventListener("load", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
    window.addEventListener("orientationchange", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
    window.addEventListener("touchstart", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
  }
  const { dataObj } = GetData();
  const [dashboardItems, setDashboardItems] = React.useState(
    navItems(dataObj) || null,
  );
  const { isLogined, token } = GetAuthData();
  const location = useLocation();
  const navigate = useNavigate();
  const spring = {
    damping: 20,
    mass: 2,
    stiffness: 150,
    type: "spring",
  };
  useEffect(() => {
    // console.log(token);
    if (isLogined === false && location.pathname !== "/admin") navigate("auth");
    else if (isLogined === true && location.pathname === "auth") navigate("/");
    // eslint-disable-next-line
  }, [isLogined, token, location.pathname]);

  useEffect(() => {
    // console.log(token);
    if (
      !isObjectEmpty(cInfo?.regions) &&
      cInfo?.regions?.length >= 1 &&
      isObjectEmpty(cInfo?.preferedRegion) &&
      isObjectEmpty(getLocalStorageItem("company_preferedRegion")) &&
      location.pathname === "/"
    ) {
      setDialog(true, <ChangeProfile />);
      // setOpen(true);
    }

    // eslint-disable-next-line
  }, [cInfo.regions]);

  useEffect(() => {
    if (
      dataObj?.carsCount === 0 &&
      !dataObj?.carsError &&
      location.pathname === "/"
    ) {
      navigate("cars/add", {
        state: { fromMain: true },
      });
    }
    // eslint-disable-next-line
  }, [dataObj?.carsCount]);

  // useEffect(() => {
  //   setCookie();
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setDashboardItems(navItems(dataObj, cInfo));
    // eslint-disable-next-line
  }, [
    cInfo?.loyalty,
    cInfo?.channel,
    dataObj?.includeBeta,
    dataObj?.activeOrdersCount,
    dataObj?.unreadMessages,
    dataObj?.currentBasketCount,
    dataObj?.creditBalance,
  ]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100dvh" }}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <PaddingBlocks />
          <Sidebar
            PaperProps={{
              style: {
                width: 256,
                position: "relative",
              },
            }}
            items={dashboardItems}
            sx={{
              height: tg?.isActive
                ? dataObj?.deviceWidth?.isMobile
                  ? `calc(100dvh - var(--tg-safe-area-inset-top) - var(--tg-content-safe-area-inset-top))`
                  : `calc(100dvh - var(--tg-safe-area-inset-top))`
                : "100dvh",
            }}
          />
        </Box>

        <Box
          sx={{
            flexDirection: "column",
            width: { xs: "100%", md: "calc(100% - 256px)" },
            overflow: "auto",
            position: "relative",
          }}
        >
          <React.Fragment>
            <PaddingBlocks />
            <Box {...handlers}>
              {/* <div>This element can be swiped</div> */}

              <AnimatePresence mode="wait">
                <motion.div
                  animate={{ opacity: 1 }}
                  key={location.pathname}
                  style={{
                    height: tg?.isActive
                      ? dataObj?.deviceWidth?.isMobile
                        ? `calc(100dvh - var(--tg-safe-area-inset-top) - var(--tg-content-safe-area-inset-top))`
                        : `calc(100dvh - var(--tg-safe-area-inset-top))`
                      : "100dvh",
                    opacity: "0.5",
                  }}
                  transition={spring}
                >
                  <Outlet usedCar={dataObj?.usedCar} />
                </motion.div>
              </AnimatePresence>
            </Box>
          </React.Fragment>
        </Box>
      </Box>
      {/* <ChangeProfile open={open} setOpen={setOpen} /> */}
    </React.Fragment>
  );
}

function PaddingBlocks() {
  const { dataObj } = GetData();
  const tg = GetTelegramContext();
  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "sticky" }}
      >
        <Box
          sx={{
            display: tg?.isActive && tg?.isFullscreen ? "flex" : "none",
            height: `var(--tg-safe-area-inset-top)`,
          }}
        ></Box>
        <Box
          sx={{
            display:
              tg?.isActive && tg?.isFullscreen && dataObj?.deviceWidth?.isMobile
                ? "flex"
                : "none",
            justifyContent:
              tg?.isActive &&
              // tg?.isFullscreen &&
              dataObj?.deviceWidth?.isMobile
                ? "center"
                : undefined,
            // paddingBottom: "var(--tg-content-safe-area-inset-bottom)",
            alignItems: "center",
            height: `var(--tg-content-safe-area-inset-top)`,
          }}
        >
          <IconButton
            onClick={() => {
              tg?.changeFullscreen();
            }}
            sx={{ padding: "0 12px" }}
          >
            <Fullscreen />
          </IconButton>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Home;

import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

import { dateToString, parseRFC3339 } from "data/functions";

import { CarUpdateMutation } from "components/gql/mutations/UpdateCar";
import { GetData } from "contexts/DataContext";
import { BoxFlexRow, MaterialUISwitch } from "data/styleGlobals";

import { Sell, Speed } from "@mui/icons-material/";
import ParamBlock from "components/ParamBlock";
import { deleteBrackets } from "data/functions";

export default function ProfileParams(props) {
  const { dataObj } = GetData();
  const [carUpdate, carUpdateVars] = CarUpdateMutation();
  const [editMileage, setEditMileage] = React.useState(false);
  const theme = useTheme();
  const handleChange = () => {
    carUpdate({
      variables: {
        input: {
          carID: props?.car?.car?.id,
          sold: (!props?.sold).toString(),
          userID: dataObj?.clientID,
        },
      },
    });
  };

  const Icon = () => (
    <>
      <div
        style={{
          width: 28,
          height: 28,
          borderRadius: "50%",
          background: props.sold ? "red" : "grey",
          position: "absolute",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
        }}
      >
        {/* {carUpdateVars?.loading && (
          <CircularProgress color="white" size={28} thickness={3} />
        )} */}
      </div>
      {!props.sold && (
        <Speed sx={{ zIndex: "10", padding: "1px", fill: "white" }} />
      )}
      {props.sold && (
        <Sell sx={{ zIndex: "10", padding: "3px", fill: "white" }} />
      )}
    </>
  );

  return (
    <React.Fragment>
      <Box sx={{ height: "2px" }}>
        {carUpdateVars?.loading && <LinearProgress sx={{ height: "2px" }} />}
      </Box>
      <Box sx={{ margin: "0 1.5rem" }}>
        {(props?.car?.car?.marka?.name || props?.car?.car?.model?.name) && (
          <ParamBlock
            text={
              (props?.car?.car?.marka?.name || "") +
              " " +
              deleteBrackets(props?.car?.car?.model?.name)
            }
            title="Модель"
          />
        )}
        {props?.car?.car.vin && (
          <ParamBlock text={props?.car?.car.vin} title="VIN" />
        )}
        {props?.car?.regNum && (
          <ParamBlock text={props?.car?.regNum} title="Гос. номер" />
        )}
        {props?.car?.car.year > 1800 && (
          <ParamBlock text={props?.car?.car.year + " г"} title="Год выпуска" />
        )}
        {/* {(props?.car?.lastMileage || props?.car?.lastMileage > 0) && ( */}
        {/* <ParamBlock
        text={
          props?.car?.lastMileage && props?.car?.lastMileage > 0
            ? props?.car?.lastMileage + " км"
            : "не указан"
        }
        title="Зафиксированный пробег"
      /> */}
        {/* )} */}
        <BoxFlexRow>
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            Зафиксированный пробег
          </Typography>
          {!editMileage ? (
            <Typography
              onClick={() => setEditMileage(true)}
              sx={{ marginLeft: "4px", cursor: "pointer" }}
              variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
            >
              {props?.car?.lastMileage && props?.car?.lastMileage > 0
                ? props?.car?.lastMileage + " км"
                : "не указан"}
            </Typography>
          ) : (
            <React.Fragment>
              <Box sx={{ lineHeight: "1.43" }}>
                <TextField
                  InputProps={{
                    disableUnderline: true,

                    inputProps: {
                      style: {
                        ...(dataObj?.deviceWidth?.isMobile
                          ? theme?.typography?.body2
                          : theme?.typography?.body1),
                        textAlign: "right",
                        padding: "0",
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end"> км</InputAdornment>
                    ),
                  }}
                  autoFocus
                  // value={text}
                  defaultValue={props?.car?.lastMileage || null}
                  fullWidth
                  name="comment"
                  onBlur={(e) => {
                    if (
                      e.target.value &&
                      props?.car?.lastMileage !== Number(e.target.value)
                    )
                      carUpdate({
                        variables: {
                          input: {
                            carID: props?.car?.car?.id,
                            userID: dataObj?.clientID,
                            lastMileage: Number(e.target.value),
                          },
                        },
                      });
                    setEditMileage(false);
                  }}
                  size="small"
                  sx={{
                    margin: "0",
                  }}
                  type="number"
                  variant={"standard"}
                />
              </Box>
            </React.Fragment>
          )}
        </BoxFlexRow>
        <Divider />

        {props?.car?.lastVisitAt && (
          <ParamBlock
            text={dateToString(parseRFC3339(props?.car?.lastVisitAt))}
            title="Последний визит"
          />
        )}
        {props?.car?.car.color && (
          <ParamBlock text={props?.car?.car.color} title="Цвет" />
        )}

        <BoxFlexRow>
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            Продан
          </Typography>
          <FormControlLabel
            control={
              <MaterialUISwitch
                checkedIcon={<Icon />}
                icon={<Icon />}
                sx={{ margin: "2px" }}
                // defaultChecked
                checked={props?.sold}
                onChange={handleChange}
              />
            }
            sx={{ margin: "0px" }}
          />
        </BoxFlexRow>
        <Divider />
      </Box>
    </React.Fragment>
  );
}

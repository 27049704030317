import { Box } from "@mui/material";
import { CarSelectBlock } from "components/CarSelectBlock";
import { PageTitle } from "components/PageTitle";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { isObjectEmpty } from "data/functions";
import { PresentsDialog } from "pages/basket/PresentsDialog";
import { RecsDialog } from "pages/basket/RecsDialog";
import { Section } from "pages/basket/Section";
import { ServicesDialog } from "pages/basket/ServicesDialog";
import React, { useState } from "react";
import { BasketActions } from "./BasketActions";
import { ButtonWithPrice } from "./ButtonWithPrice";

export default function BasketPage() {
  const { dataObj } = GetData();
  const { basket, contextDelete, sectionItemAdd, sections } = GetBasket();

  const [selected, setSelected] = useState({});
  const [allSelected, setAllSelected] = useState(false);

  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ Basket: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    changeRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  async function changeRegistration() {
    await contextDelete("registration");
    Object.keys(selected).forEach((key) => {
      selected[key].forEach((element) => {
        sectionItemAdd("registration", element, key);
      });
    });
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        <PageTitle title="Корзина" />
        {!dataObj?.deviceWidth?.isMobile && <CarSelectBlock />}
        <Box
          sx={{
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            overflow: "hidden",
            height: "100%",
            // flexBasis: !dataObj?.deviceWidth?.isMobile ? "66%" : undefined,
          }}
        >
          <Box sx={{ width: "100%", overflow: "auto", position: "relative" }}>
            {dataObj?.deviceWidth?.isMobile && <CarSelectBlock />}
            <BasketActions
              allSelected={allSelected}
              selected={selected}
              setAllSelected={setAllSelected}
              setSelected={setSelected}
            />

            <Section
              array={basket[sections?.services]}
              dialogItem={<ServicesDialog />}
              section={sections?.services}
              selected={selected}
              setSelected={setSelected}
              title="Услуги"
            />
            {!isObjectEmpty(
              (dataObj?.recsArray ? dataObj?.recsArray : {})[
                dataObj?.usedCar?.car?.id
              ],
            ) && (
              <Section
                array={basket[sections?.recommendations]}
                dialogItem={<RecsDialog />}
                section={sections?.recommendations}
                selected={selected}
                setSelected={setSelected}
                title="Рекомендации"
              />
            )}
            {!isObjectEmpty(dataObj?.presentAccrue) && (
              <Section
                array={basket[sections?.gifts]}
                dialogItem={<PresentsDialog />}
                section={sections?.gifts}
                selected={selected}
                setSelected={setSelected}
                title="Бонусные услуги"
              />
            )}
          </Box>
          <ButtonWithPrice selected={selected} />
        </Box>
      </Box>
    </React.Fragment>
  );
}

import React, { useState } from "react";

import { Box } from "@mui/material";
import { PageTitle } from "components/PageTitle";

import ButtonsBlock from "pages/cars/add/ButtonsBlock";
import ImageBlock from "pages/cars/add/ImageBlock";

import FieldYear from "pages/cars/add/fields/FieldYear";

import FieldMark from "pages/cars/add/fields/FieldMark";
import FieldMileage from "pages/cars/add/fields/FieldMileage";
import FieldModels from "pages/cars/add/fields/FieldModels";
import FieldPlate from "pages/cars/add/fields/FieldPlate";
import FieldVin from "pages/cars/add/fields/FieldVin";

export default function CarAdd() {
  const [helperText, setHelperText] = useState({});
  const [carAddData, setCarAddData] = useState({});

  const [error, setError] = useState(false);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Добавление автомобиля" />
        <Box sx={{ overflow: "auto" }}>
          <FieldVin
            carAddData={carAddData}
            helperText={helperText}
            required={error}
            setCarAddData={setCarAddData}
            setHelperText={setHelperText}
          />
          <FieldPlate
            carAddData={carAddData}
            helperText={helperText}
            required={error}
            setCarAddData={setCarAddData}
            setHelperText={setHelperText}
          />
          <FieldMark
            carAddData={carAddData}
            helperText={helperText}
            required={error}
            setCarAddData={setCarAddData}
            setHelperText={setHelperText}
          />
          <FieldModels
            carAddData={carAddData}
            helperText={helperText}
            required={error}
            setCarAddData={setCarAddData}
            setHelperText={setHelperText}
          />
          <ImageBlock carAddData={carAddData} />
          <FieldYear
            carAddData={carAddData}
            helperText={helperText}
            setCarAddData={setCarAddData}
          />
          <FieldMileage
            carAddData={carAddData}
            // helperText={helperText}
            setCarAddData={setCarAddData}
          />

          <ButtonsBlock
            carAddData={carAddData}
            setCarAddData={setCarAddData}
            setError={setError}
            setHelperText={setHelperText}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}

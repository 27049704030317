import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";

import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import React, { useEffect, useState } from "react";

import { Settings } from "@mui/icons-material";
import { Patterns } from "components/StorageDialog";
import { ExportMessageListQuery } from "components/gql/queris/ExportMessageList";
import { GetChat } from "contexts/ChatContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { isObjectEmpty, parseRFC3339, scrollBottom } from "data/functions";
import { ChatBody } from "./ChatBody";
import { ChatInput } from "./ChatInput";

function Chat() {
  const [endMark, setEndMark] = useState({});
  const [open, setOpen] = useState(false);
  const [getMessageList, getMessageListVars] = ExportMessageListQuery();
  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();
  const { addMess } = GetChat();
  React.useEffect(() => {
    yaParams({ Chat: "visit" });
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // React.useEffect(() => {
  //   console.log(endMark);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [endMark]);

  async function getMessages(mark, limit = 20, scroll = false) {
    if (getMessageListVars?.loading) return;
    let { data, error } = await getMessageList({
      variables: {
        companyUuid: cInfo?.UUID,
        limit: limit,
        start: mark?.end ? mark?.end : null,
      },
    });

    if (
      data?.chat_messageList &&
      !isObjectEmpty(data?.chat_messageList?.messages)
    ) {
      setEndMark({
        end: data?.chat_messageList?.end,
        start: mark?.end ? mark.end : "",
      });
      let list = data?.chat_messageList?.messages;
      (list ?? []).forEach((element) => {
        element["endString"] = data?.chat_messageList?.end;
        addMess(element);
      });
      if (scroll) {
        scrollBottom();
      }
    }
  }

  function getLastEndMark(obj, res = "") {}

  const { dataObj } = GetData();

  const images = require.context("data/patterns", true);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          position: "relative",
          maxWidth: "100%",
          "::before": {
            content: `""`,
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "-1",
            background: `url(${dataObj?.chatPattern ? dataObj?.chatPattern : images("./pattern11.svg")}) center center/contain repeat`,
            opacity: dataObj?.themeMode === "light" ? 0.1 : 0.4,
          },
        }}
      >
        <PageTitle title="Чат" />

        {dataObj?.includeBeta && (
          <Box
            sx={{
              position: "absolute",
              top: dataObj?.deviceWidth?.isMobile ? "6px" : "16px",
              right: dataObj?.deviceWidth?.isMobile ? "6px" : "16px",
            }}
          >
            <IconButton onClick={() => setOpen(true)} sx={{ opacity: "0.2" }}>
              <Settings />
            </IconButton>
          </Box>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}
        >
          <ChatBody
            endMark={endMark}
            getMessages={getMessages}
            loading={getMessageListVars?.loading}
          />
          <ChatInput getMessages={getMessages} />
        </Box>
      </Box>
      <Dialog hideBackdrop={true} onClose={() => setOpen(false)} open={open}>
        <DialogContent
          sx={{
            padding: "0 0.86em",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ overflow: "auto" }}></Box>
          <Patterns hide={true} />
          <Button onClick={() => setOpen(false)}>OK</Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default Chat;

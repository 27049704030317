import React, { useState } from "react";

import { Key } from "@mui/icons-material/";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { StorageDialog } from "components/StorageDialog";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { createTokenData, setLocalStorageItem } from "data/functions";
import { useLongPress } from "pages/profile/info/ProfileMenu";

export const TokenDialog = (props) => {
  const { dataObj } = GetData();
  const { setAlert } = GetAlertData();
  const [value, setValue] = useState("");
  const { setTokenData } = GetAuthData();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const longPressProps = useLongPress({
    onLongPress: () => setOpenDialog(true),
  });
  const [selfDataQuery] = SelfDataQuery();

  async function addToken(dataNew) {
    if (!dataNew?.accessToken) return;
    let { data, error } = await selfDataQuery({
      variables: {
        token: dataNew?.accessToken,
      },
    });

    if (error) {
      if (error.networkError) {
        setAlert("Нет соединения с сервером", "error");
      } else setAlert("Неактивный токен", "error");
    }
    if (data) {
      if (!data?.auth_getSelfData?.limited) setTokenData(dataNew);
      else
        setAlert(
          "Токен данного вида не может быть использован для входа",
          "error",
        );
    }
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        {...longPressProps}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "2rem",
          width: "2rem",
        }}
      />
      <Dialog onClose={() => setOpen(false)} open={open} sx={{ zIndex: 1201 }}>
        <DialogTitle>Изменение данных токена</DialogTitle>
        <DialogContent>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              ),
            }}
            fullWidth
            label="JSON RAW!! TokenData"
            mb={2}
            mt={2}
            multiline={false}
            name="tokenData"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            sx={{
              minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "30%",
              margin: "0.3rem 0",
            }}
            type="text"
            value={value}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValue("");
              setOpen(false);
            }}
            variant="outlined"
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              try {
                let obj = JSON.parse(value);
                setLocalStorageItem("tokenDataOriginal", obj);
                let tokenData = createTokenData(
                  obj?.access_token || obj?.accessToken,
                  obj?.expires_in || obj?.expiresIn,
                  obj?.refresh_token || obj?.refreshToken,
                  obj?.refresh_expires_in || obj?.refreshExpiresIn,
                );
                addToken(tokenData);
              } catch (error) {
                setAlert(
                  `Введенная строка скорее всего не распознана. \nПример:\n{"access_token":"xxxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx","expires_in":"300"}`,
                  "warning",
                  5,
                );
              }
            }}
            variant="outlined"
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
      <StorageDialog setOpen={setOpenDialog} status={openDialog} />
    </React.Fragment>
  );
};

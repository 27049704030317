import React from "react";
import { RouterProvider } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AlertProvider } from "contexts/AlertContext";
import { ApolloContext } from "contexts/ApolloContext";
import { CompanyProvider } from "contexts/CompanyContext";
import { DataProvider } from "contexts/DataContext";
import { DialogProvider } from "contexts/DialogContext";
import { GQLQueryProvider } from "contexts/GQLQueryContext";
import { MetrikaProvider } from "contexts/MetrikaContext";

import { TAuthProvider } from "contexts/TAuthContext";
import { TelegramProvider } from "contexts/TelegramContext";
import { ThemeProvider } from "contexts/ThemeContext";

import "data/css/chat.css";
import { router } from "data/routes";
import ruLocale from "date-fns/locale/ru";

const App = () => {
  return (
    <LocalizationProvider adapterLocale={ruLocale} dateAdapter={AdapterDateFns}>
      {/* <LogsProvider> */}
      <ApolloContext>
        <TAuthProvider>
          <CompanyProvider>
            <AlertProvider>
              <MetrikaProvider>
                <DataProvider>
                  <TelegramProvider>
                    <ThemeProvider>
                      <DialogProvider>
                        <GQLQueryProvider>
                          <RouterProvider router={router} />
                        </GQLQueryProvider>
                      </DialogProvider>
                    </ThemeProvider>
                  </TelegramProvider>
                </DataProvider>
              </MetrikaProvider>
            </AlertProvider>
          </CompanyProvider>
        </TAuthProvider>
      </ApolloContext>
      {/* </LogsProvider> */}
    </LocalizationProvider>
  );
};

export default App;

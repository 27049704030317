import App from "App";
import "App.css";
import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
const el = document.getElementById("app");

window.addEventListener("resize", function () {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
});

createRoot(el).render(
  // <StrictMode>
  <App />,
  // </StrictMode>,
);

import React from "react";

import { Box, Divider, Typography } from "@mui/material";

import { dateToString, getCarName, parseRFC3339 } from "data/functions";
import {
  isObjectEmpty,
  translateOrderStatus,
  translateOrderType,
} from "data/functions";
import { constants } from "data/globals";

import { GetData } from "contexts/DataContext";
import { BoxFlexRow, FlexRowNoWrap } from "data/styleGlobals";

export function ParamRow(props) {
  return (
    <React.Fragment>
      <BoxFlexRow>
        <Typography>{props?.title}</Typography>
        <Box
          sx={{
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
          }}
        >
          {props?.subTitle}
        </Box>
      </BoxFlexRow>
      <Divider />
    </React.Fragment>
  );
}

export function OrderParams({ department, docItem }) {
  const { dataObj } = GetData();
  const car = docItem?.carID
    ? (dataObj?.carArrWithSold ?? [])?.find(
        (el) => el?.car?.id === docItem?.carID,
      ) || {}
    : {};
  return (
    <React.Fragment>
      {translateOrderType(docItem?.type) !== constants.ruTypeZR &&
        docItem?.status && (
          <ParamRow
            subTitle={translateOrderStatus(docItem?.status)}
            title="Статус"
          />
        )}

      {department?.name && (
        <ParamRow subTitle={department?.name} title="Филиал" />
      )}
      {department?.adress && (
        <ParamRow
          subTitle={
            department?.region &&
            !department?.adress.includes(department?.region)
              ? department?.region + ", " + department?.adress
              : department?.adress
          }
          title="Адрес"
        />
      )}

      {/* {docItem?.masterName && (
            <ParamRow subTitle={docItem?.masterName} title="Мастер" />
          )} */}

      {!isObjectEmpty(car) && (
        <ParamRow
          subTitle={
            <Box sx={{ ...FlexRowNoWrap }}>
              {car?.car?.marka?.imageUrl ? (
                <img
                  alt={"Марка"}
                  onError={(e) => {
                    e.target.hidden = true;
                  }}
                  src={car?.car?.marka?.imageUrl}
                  style={{
                    overflow: "hidden",
                    width: "24px",
                    margin: "0 0 0 -2px",
                  }}
                />
              ) : undefined}

              {getCarName(car?.car?.id, dataObj?.carArrWithSold, "", false)}
            </Box>
          }
          title="Автомобиль"
        />
      )}
      {(docItem?.mileage || docItem?.mileage > 0) && (
        <ParamRow
          subTitle={docItem?.mileage + " км"}
          title="Зафиксированный пробег"
        />
      )}

      {!isObjectEmpty(parseRFC3339(docItem?.planDate)) && (
        <React.Fragment>
          {translateOrderType(docItem?.type) === constants.ruTypeZN && (
            <ParamRow
              subTitle={dateToString(parseRFC3339(docItem?.planDate), true)}
              title={"Время выдачи"}
            />
          )}
          {translateOrderType(docItem?.type) === constants.ruTypeZP && (
            <ParamRow
              subTitle={dateToString(parseRFC3339(docItem?.planDate), false)}
              title={"Срок поставки"}
            />
          )}
          {translateOrderType(docItem?.type) === constants.ruTypeZR && (
            <ParamRow
              subTitle={dateToString(
                parseRFC3339(docItem?.planDate),
                false,
                true,
              )}
              title={"Время"}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

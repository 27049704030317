import { CameraAlt, PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from "@mui/material";

import { GetStsDecode } from "components/gql/queris/GetStsDecode";
import { GibddUploadUrlQuery } from "components/gql/queris/GibddUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import React, { useState } from "react";

import { GetModelsQuery } from "components/gql/queris/GetModels";
import { SearchMark } from "components/gql/queris/SearchMark";
import { SearchModel } from "components/gql/queris/SearchModel";
import { GetData } from "contexts/DataContext";
import { isActiveChannel, isObjectEmpty, translateError } from "data/functions";
import { DragFileElement } from "data/styleGlobals";
import { ResponseParamsBlock } from "pages/cars/add/ResponseParamsBlock";
import { getModels } from "pages/cars/add/fields/FieldModels";
import { uploadFile } from "tools/requests";

export default function DecodeSts(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [stsRes, setStsRes] = React.useState(false);
  const { setAlert } = GetAlertData();
  const [gibddUpload] = GibddUploadUrlQuery();
  const [stsDecodeQuery] = GetStsDecode();
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const [modelsQuery] = GetModelsQuery();
  const [searchMarkQuery] = SearchMark();
  const [searchModelQuery] = SearchModel();

  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    changeImageArray(e.dataTransfer.files);
    e.dataTransfer.value = null;
  };

  async function changeImageArray(array) {
    if (array && array[0]) {
      showLoading();
      let { data } = await gibddUpload({
        variables: {
          filename: array[0].name,
        },
      });
      if (data?.gibdd_uploadUrl?.fileID) {
        let success = await uploadFile(data?.gibdd_uploadUrl, array[0]);
        if (!success) {
          setAlert("Не удалось загрузить файл для расшифровки", "warning");
        } else {
          let stsData = await stsDecodeQuery({
            variables: {
              fileId: data?.gibdd_uploadUrl?.fileID,
            },
          });
          if (stsData?.data && stsData?.data?.gibdd_stsDecode?.grz) {
            let fileData = stsData?.data?.gibdd_stsDecode;
            let mark = {};
            let model = {};
            let models = {};
            setStsRes(fileData);
            let searchMark = await searchMarkQuery({
              variables: {
                markName: fileData?.brand,
              },
            });
            if (searchMark?.data && searchMark?.data?.exch_searchMark[0]?.id) {
              mark = searchMark?.data?.exch_searchMark[0];
            } else {
              mark = (dataObj?.markList ?? []).find((element) =>
                (element?.name ?? "").includes(fileData?.brand),
              );
            }
            if (!isObjectEmpty(mark)) {
              models = await getModels(dataObj, modelsQuery, mark?.id);
              let searchModel = await searchModelQuery({
                variables: {
                  markId: mark?.id,
                  modelName: fileData?.model,
                },
              });
              if (
                searchModel?.data &&
                searchModel?.data?.exch_searchModel[0]?.id
              ) {
                model = searchModel?.data?.exch_searchModel[0];
              } else {
                model = (models ?? []).find((element) =>
                  (element?.name ?? "").includes(fileData?.model),
                );
              }
            }

            props?.setCarAddData((e) => {
              let res = { ...e };
              if (fileData?.vin) {
                res = {
                  ...res,
                  vin: fileData?.vin,
                };
              }
              if (fileData?.grz) {
                res = {
                  ...res,
                  plate: fileData?.grz,
                };
              }
              if (!isObjectEmpty(mark)) {
                res = {
                  ...res,
                  mark: mark,
                };
              }
              if (!isObjectEmpty(model)) {
                res = {
                  ...res,
                  model: model,
                };
              }
              if (!isObjectEmpty(models)) {
                res = {
                  ...res,
                  modelList: models,
                };
              }
              // if (fileData?.model) {
              //   res = {
              //     ...res,
              //     modelName: fileData?.model,
              //   };
              // }

              if (fileData?.year) {
                res = {
                  ...res,
                  year: new Date("" + fileData?.year),
                };
              }
              return res;
            });
            props?.setHelperText((e) => {
              let res = { ...e };
              if (fileData?.brand) {
                res = {
                  ...res,
                  markText: fileData?.brand,
                };
              }
              if (fileData?.model) {
                res = {
                  ...res,
                  modelText: fileData?.model,
                };
              }
              return res;
            });
          }
          if (stsData?.error && translateError(stsData?.error?.message)) {
            setAlert(translateError(stsData?.error?.message), "warning");
          }
        }
      }
      hideLoading();
    }
  }

  function showLoading() {
    setLoading(true);
  }

  function hideLoading() {
    setLoading(false);
  }

  return (
    <React.Fragment>
      {isActiveChannel(cInfo?.channels, "Gibdd_Sts") && (
        <React.Fragment>
          <input
            accept="image/*"
            alt="СТС"
            id={`input-file-upload-sts`}
            multiple={false}
            onChange={handleChange}
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
          />

          <IconButton
            aria-hidden={false}
            disableRipple
            onClick={(e) => {
              setOpen(true);
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PhotoCamera aria-hidden={false} />
          </IconButton>
          <Dialog
            maxWidth="sm"
            onClose={(e) => {
              setOpen(false);
            }}
            open={open}
          >
            <DialogTitle sx={{ fontSize: "1rem" }}>
              Загрузите фото свидетельства о регистрации ТС (сторона с
              гос/номером и VIN)
            </DialogTitle>
            <DialogContent>
              <Box
                id="form-file-upload"
                onDragEnter={(e) => handleDrag(e)}
                onSubmit={(e) => e.preventDefault()}
              >
                {/* <Input
                  id={`input-file-upload-sts`}
                  multiple={false}
                  onChange={(e) => handleChange(e)}
                  ref={inputRef}
                  sx={{ display: "none" }}
                  type="image"
                /> */}
                {!dataObj?.deviceWidth?.isMobile &&
                  !dataObj?.deviceWidth?.isPad && (
                    <label
                      className={
                        dragActive
                          ? "label-file-upload drag-active "
                          : "label-file-upload"
                      }
                      htmlFor={`input-file-upload-sts`}
                      id={`label-file-upload-sts`}
                    >
                      <CameraAlt sx={{ width: "36px", height: "36px" }} />
                    </label>
                  )}
                {dragActive && (
                  <DragFileElement
                    id={`drag-file-element ${props.variant}`}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  />
                )}
              </Box>
              {loading && <LinearProgress />}
              <ResponseParamsBlock data={stsRes} name={"stsRes"} />
            </DialogContent>
            <DialogActions>
              <Button
                aria-hidden={false}
                onClick={(e) => {
                  inputRef.current.click();
                }}
                sx={{ margin: "0.4rem" }}
                variant="outlined"
              >
                Загрузить
              </Button>

              <Button
                aria-hidden={false}
                onClick={(e) => {
                  setOpen(false);
                }}
                sx={{ margin: "0.4rem" }}
                variant="outlined"
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DealFilesComponent } from "pages/deal/DealFilesComponent";

export const FilesDialog = (props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "80ssvw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
      >
        Вложенные файлы
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          Тут будет область с вложенными к внутреннему комментарию файлами,
          когда запрос на добавления файла к комментарию заработает и появится
          возможность тестировать отображение.
        </DialogContentText> */}
        <DealFilesComponent
          filesArray={props?.filesArray}
          setArray={props?.setFilesArray}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          fullWidth
          onClick={() => {
            props.setOpen(false);
          }}
          variant="outlined"
          // color="primary"
        >
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { Description } from "@mui/icons-material";
import { GetAlertData } from "contexts/AlertContext";
import { GetData } from "contexts/DataContext";
import { translateOrderType } from "data/functions";

export const GarantDialog = (props) => {
  const [text, setText] = React.useState("");

  const { setAlert } = GetAlertData();
  const { dataObj } = GetData();

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "60vw",
        },
      }}
      onClose={(e) => props.setOpen(false)}
      open={props.open}
    >
      <DialogTitle>Запрос по гарантии</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          // alignItems: "center",
        }}
      >
        <DialogContentText sx={{ display: "flex" }}>
          <Description color="dimblue" />
          {translateOrderType(props?.item?.type) +
            " №" +
            props?.item?.docNumber}

          {/* Опишите подробности */}
        </DialogContentText>
        <TextField
          fullWidth
          label="Комментарий"
          maxRows={dataObj?.deviceWidth?.isMobile ? 4 : undefined}
          mb={2}
          mt={2}
          multiline
          name="comment"
          onChange={(e) => setText(e.target.value)}
          rows={dataObj?.deviceWidth?.isMobile ? undefined : 4}
          sx={{ margin: "0.8rem 0" }}
          type="text"
          value={text}
          variant={dataObj?.deviceWidth?.isMobile ? "standard" : "outlined"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setText("");
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Отмена
        </Button>
        <Button
          onClick={(e) => {
            console.log({ text: text });
            setText("");
            props.setOpen(false);
            setAlert(
              "Функционал в разработке. \nЗапрос по гарантии не отправлен",
              "warning",
            );
          }}
          variant="outlined"
        >
          Отправить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import styled from "@emotion/styled";

import { Box, Card, Grid, useTheme } from "@mui/material";

import { GetData } from "contexts/DataContext";
import { localeTranslate } from "data/models";
import React, { useState } from "react";
import { MessageBox } from "react-chat-elements";

import ImageViewer from "react-simple-image-viewer";
import { cancel, format, register, render } from "timeago.js";

const ChatContainer = styled(Grid)`
  width: 100%;
  // padding: 0.4rem;
  background: transparent;
  display: flex;
  flex-direction: column;
  box-shadow: none;
`;

export function ChatBody(props) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [dialogType, setDialogType] = useState();
  const [URL, setURL] = useState([]);
  const theme = useTheme();
  const openImageViewer = React.useCallback((url, type) => {
    setURL([url]);
    setIsViewerOpen(true);
    setDialogType(type);
  }, []);
  const { dataObj } = GetData();
  const [messages, setMessages] = useState([]);
  const containerRef = React.useRef(null);
  const messagesRef = React.useRef(null);
  register("my-locale", localeTranslate);
  React.useEffect(() => {
    let element = messagesRef.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [messagesRef?.current, messages]);

  React.useEffect(() => {
    setMessages(props?.messages);
  }, [props?.messages]);

  async function openFunc(props) {
    if (props?.type === "photo") {
      openImageViewer(props?.data?.uri, "image");
    }
    if (props?.type === "file") {
      let link = document.createElement("a");
      link.href = props?.data?.uri;
      link.target = "_blank";
      link.download = props?.data?.uri.substr(
        props?.data?.uri.lastIndexOf("/") + 1,
      );
      link.click();
      link.remove();
    }
    // console.log(props);
  }

  return (
    <Box sx={{ overflow: "auto", width: "100%", position: "relative" }}>
      <ChatContainer
        component={Card}
        container
        ref={containerRef}
        sx={{
          "& .rce-container-mbox": {
            ".rce-mbox": {
              background: `${theme?.palette?.background?.paper} !important`,
            },
            ".rce-mbox-right": {
              background: `${theme?.components?.MuiPaper?.styleOverrides?.root?.background ?? "transparent"} !important`,
            },
          },
        }}
      >
        {(messages ?? []).map((el, ind) => (
          <React.Fragment key={ind}>
            <MessageBox
              data={el?.data}
              date={el?.date}
              dateString={el?.dateString}
              key={ind}
              onOpen={() => openFunc(el)}
              position={el?.position}
              // title="Burhan"
              reply={el?.reply}
              // replyButton={true}
              text={el?.text}
              type={el?.type}
              // width={300}
            />
            {ind === messages.length - 1 && (
              <Box
                ref={messagesRef}
                sx={{ height: "2px", width: "100%" }}
              ></Box>
            )}
          </React.Fragment>
        ))}
      </ChatContainer>
      {isViewerOpen && (
        <Box
          onClick={() => setIsViewerOpen(false)}
          onTouchEnd={() => setIsViewerOpen(false)}
        >
          {dialogType === "image" && (
            <ImageViewer
              backgroundStyle={{
                width: dataObj?.deviceWidth?.isMobile
                  ? "100%"
                  : "calc(100% - 256px)",
                left: dataObj?.deviceWidth?.isMobile ? "0" : "256px",
                backgroundColor: "rgba(0,0,0,0.7)",
              }}
              closeComponent={<></>}
              closeOnClickOutside={true}
              disableScroll={true}
              onClose={() => setIsViewerOpen(false)}
              src={URL}
              style={{ color: "black" }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export const promotions = [
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "1",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_3.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Мы проверим по 15 пунктам следующие узлы и агрегаты на Вашем автомобиле:
            1. Наружные осветительные приборы
            2. Проверка состояния шин
            3. Проверка состояния щеток стеклоочистителя
            4. Проверка состояния АКБ, U нагр,
            5. Проверка состояния АКБ, U без нагр,
            6. Уровень и состояние охлаждающей жидкости
            7. Уровень и состояние тормозной жидкости
            8. Уровень и состояние жидкости ГУРа
            9. Осмотр ДВС на предмет утечек и отпотеваний
            10. Состояние приводных ремней
            11. Проверка состояния опор и подушек ДВС
            12. Стойки стабилизатора
            13. Втулки стабилизатора
            14. Подшипники ступиц
            15. Шаровые опоры`,
    id: "2",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_3.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_2.png",
    time: "11.04.24-01.01.30",
    title: "ТЕХОБСЛУЖИВАНИЕ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "3",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "4",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "5",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
  {
    body: `Работы по регламентному ТО:
            Смазывание петель замков
            Чистка и смазка клемм АКБ
            Фильтр воздушный замена
            Фильтр салона замена
            Моторное масло, замена масляного фильтра двигателя`,
    id: "6",
    main: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    preview: "https://cloud5.5-systems.ru/5systems/1110x480_1.png",
    time: "11.04.24-01.01.30",
    title: "ДИАГНОСТИКА ",
  },
];

export const emptyItem = {
  createdForRelease: { id: 0, name: "" },
  description: "",
  favorite: "",
  id: 0,
  name: "",
  result: "",
  solvedInRelease: { id: 0, name: "" },
  state: "NEW",
  // createdAt: getCurrentDate(),
  ticketnumber: "",
};

export const mockMessage = [
  {
    // title: "Kursat",
    date: "16:30",
    dateString: "16:30",
    position: "left",
    text: "Give me a message list example !",
    type: "text",
  },
  {
    date: "16:40",
    dateString: "16:40",
    position: "right",
    // title: "Emre",
    text: "That's all.",
    type: "text",
  },
  {
    // title: "Emre",
    data: {
      controlsList: "",
      // height: 300,
      status: {
        click: true,
        download: true,
        loading: 0.5,
      },
      videoURL: "https://www.w3schools.com/html/mov_bbb.mp4",
      // width: 300,
    },
    date: "16:40",
    dateString: "16:40",
    position: "left",
    type: "video",
  },
  {
    // title: "Emre",
    data: {
      audioURL: "https://cloud5.5-systems.ru/5systems/audio.mp3",
    },
    date: "16:45",
    dateString: "16:45",
    position: "right",
    type: "audio",
  },
  {
    // title: "Emre",
    data: {
      uri: "https://cloud5.5-systems.ru/5systems/avtokompleks-1.jpg",
    },
    // text: "That's all.",
    date: "16:50",
    dateString: "16:50",
    position: "left",
    type: "photo",
  },

  {
    // title: "Emre",
    data: {
      // name: "logo.svg",
      id: "file",
      // extension: ".svg",
      status: {
        click: true,
        download: true,
        // loading: 0.5,
      },
      uri: "https://cloud.5-systems.ru/5systems/logo.svg",
      // uri: "https://cloud5.5-systems.ru/5systems/audio.mp3",
    },
    date: "16:52",
    dateString: "16:52",
    position: "left",
    text: "logo.svg",
    type: "file",
  },
  {
    date: "16:54",
    dateString: "16:54",
    // focus: "true",
    position: "right",
    reply: {
      message: "That's all.",
      title: "You",
      titleColor: "#8717ae",
    },
    text: "Nice to meet you",
    title: "Emre",
    type: "text",
  },
];

export const localeTranslate = (number, index, totalSec) => {
  // number: the timeago / timein number;
  // index: the index of array below;
  // totalSec: total seconds between date to be formatted and today's date;
  return [
    ["только что", "right now"],
    ["% сек. назад", "in %s seconds"],
    ["1 мин. назад", "in 1 minute"],
    ["%s мин. назад", "in %s minutes"],
    ["1 ч. назад", "in 1 hour"],
    ["%s ч. назад", "in %s hours"],
    ["1 д. назад", "in 1 day"],
    ["%s д. назад", "in %s days"],
    ["1 н. назад", "in 1 week"],
    ["%s н. назад", "in %s weeks"],
    ["1 м. назад", "in 1 month"],
    ["%s м. назад", "in %s months"],
    ["1 г. назад", "in 1 year"],
    ["%s г. назад", "in %s years"],
  ][index];
};

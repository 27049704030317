import { MiscellaneousServices } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { getImageUrlByName } from "data/functions";
import { BoxFolder, FlexRowNoWrap } from "data/styleGlobals";
import React from "react";

export const FolderBlock = (props) => {
  return (
    <BoxFolder
      onClick={() => {
        if (props?.item) props?.setItem(props?.item);
      }}
      sx={{
        width: "100%",
        flex: "auto",
      }}
    >
      <Card
        sx={{
          position: "relative",
          background: "#ececec",
        }}
      >
        <FolderImage {...props} />
        <CardContent
          sx={{
            padding: "4px 12px !important",
            width: "100%",
            position: "absolute",
            bottom: 0,
            background: "#ececec",
            minHeight: "40px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              ...FlexRowNoWrap,
              justifyContent: "space-between",

              alignItems: "center",
              marginBottom: 0,
            }}
          >
            <Box
              sx={{
                ...FlexRowNoWrap,
                alignItems: "center",
              }}
            >
              {props?.iconElement}
              <FolderTitle title={props?.title} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </BoxFolder>
  );
};

function FolderImage(props) {
  return (
    <Box
      sx={{
        padding: "2px 2px 20px 2px",
        background: "#ececec",
      }}
    >
      {getImageUrlByName(props?.title) ? (
        <Box
          sx={{
            width: "100%",
            padding: "4px",
            minHeight: "40px",
            aspectRatio: "1 / 1",
            // height: getImageUrlByName(props?.title) ? undefined : "40px",
            // maxHeight: "200px",

            background: getImageUrlByName(props?.title)
              ? `url(${getImageUrlByName(props?.title)}) center 36% / 100% no-repeat local padding-box padding-box rgb(255, 255, 255)`
              : undefined,
          }}
        />
      ) : (
        <Box sx={{ width: "100%", background: "white" }}>
          <MiscellaneousServices
            sx={{
              height: "100%",
              width: "100%",
              fill: "#dcdcdc",
              padding: "23%",
              opacity: "1",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

function FolderTitle(props) {
  return (
    <Typography
      sx={{
        fontWeight: "500 !important",
        wordBreak: "break-word",
        // fontWeight: "400",
        color: "black",
        lineHeight: "1.2 !important",
        typography: {
          sm: "body1",
          xs: "body2",
          md: "body2",
          lg: "body1",
        },
      }}
    >
      {props?.title}
    </Typography>
  );
}

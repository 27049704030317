import { PageTitle } from "components/PageTitle";
import React from "react";

export default function InDevelop() {
  return (
    <React.Fragment>
      <PageTitle />
      <p
        style={{
          minHeight: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Данная страница в разработке
      </p>
    </React.Fragment>
  );
}

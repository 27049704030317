import styled from "@emotion/styled";
import { Box, Divider, List } from "@mui/material";
import SidebarNavSection from "components/sidebar/SidebarNavSection";
import React from "react";
import "vendor/perfect-scrollbar.css";

const Items = styled.div``;

const SidebarNav = ({ items }) => {
  return (
    <List disablePadding>
      <Items>
        {items &&
          items.map((item, key) => (
            <Box key={key}>
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
              />
              {key !== items?.length - 1 && <Divider />}
            </Box>
          ))}
      </Items>
    </List>
  );
};

export default SidebarNav;

import {
  Cancel,
  CancelOutlined,
  CancelRounded,
  Clear,
  CloseOutlined,
  Search,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { SearchService } from "components/gql/queris/SearchService";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function FindBlock(props) {
  const { dataObj, pushDataObj, setDataObj } = GetData();
  const { cInfo } = GetCInfo();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [flowId, setFlowId] = useState("");
  const [searchQuery] = SearchService();

  React.useEffect(() => {
    searchText(value);
    if (value.length < 3) {
      deleteMode();
    } else {
      setSearchParams({ mode: "search" });
    }
  }, [value]);

  function deleteMode() {
    if (searchParams.get("mode")) {
      searchParams.delete("mode");
      setSearchParams(searchParams);
    }
  }

  async function searchText(str) {
    if (str.length < 3) {
      deleteMode();
      return;
    }
    let { data } = await searchQuery({
      variables: {
        companyUuid: cInfo?.UUID,
        flowId: flowId || null,
        searchText: str,
      },
    });
    if (data) {
      setFlowId(data?.export_svcSearch?.flowId);
      let obj = {
        categories: !isObjectEmpty(data?.export_svcSearch?.categories)
          ? data?.export_svcSearch?.categories
          : [],
        services: !isObjectEmpty(data?.export_svcSearch?.services)
          ? data?.export_svcSearch?.services
          : [],
      };
      setDataObj(obj, "serviceSearch");
    }
  }

  return (
    <React.Fragment>
      {/* {dataObj.includeBeta && ( */}
      <TextField
        InputProps={{
          // type: "search",
          readOnly: props.logined ? props.logined : false,
          disableUnderline: props.logined ? props.logined : false,
          style: {
            fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: value && (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setValue("")}
              sx={{ padding: 0 }}
            >
              <Clear sx={{ maxWidth: "80%" }} />
            </IconButton>
          ),
        }}
        fullWidth={true}
        mb={2}
        mt={2}
        name="search"
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (value.length > 2) {
              setSearchParams({ mode: "search" });
              searchText(value);
            }
          }
        }}
        sx={{
          minWidth: dataObj?.deviceWidth?.isMobile ? "inherit" : "48%",
          margin: "0.3rem 0",
          padding: "0 12px ",
        }}
        type="text"
        value={value}
        variant="standard"
      />
      {/* )} */}
    </React.Fragment>
  );
}

export function getServiceArrayByMode(arr, parentId) {
  return (arr ?? []).filter((el) => {
    if (el?.id === "search") return false;
    return el?.mode !== "search" && (el.parentId || "") === (parentId || "");
  });
}

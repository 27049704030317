import { Clear } from "@mui/icons-material";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import React from "react";

export default function SelectBlock(props) {
  const { dataObj } = GetData();

  return (
    <React.Fragment>
      <Box sx={{ margin: "0.5rem 1rem" }}>
        <Autocomplete
          autoSelect={true}
          blurOnSelect
          clearIcon={<Clear sx={{ height: "16px", width: "16px" }} />}
          clearOnEscape
          disableClearable={false}
          disabled={props?.disabled ? props?.disabled : false}
          forcePopupIcon={false}
          fullWidth
          getOptionKey={(option) => {
            return option.id;
          }}
          getOptionLabel={(option) => {
            if (props?.customOptionLabelParam) {
              return (
                (option.name || "") +
                (!isObjectEmpty(option[props?.customOptionLabelParam])
                  ? " (" + option[props?.customOptionLabelParam].join(",") + ")"
                  : "")
              );
            }
            return option.name || "";
          }}
          id={props?.label}
          isOptionEqualToValue={(option, newValue) => {
            return option.id === newValue.id;
          }}
          noOptionsText={"Не найдено"}
          onChange={(event, newValue, reason) => {
            if (event.type !== "blur") {
              if (typeof newValue !== "string") {
                props?.setValue(newValue);
              } else {
                props?.setValue("");
              }
              try {
                props?.setSecondValue(newValue?.id);
              } catch (error) {}
              try {
                props?.setThirdValue(newValue);
              } catch (error) {}
              if (!newValue) {
                try {
                  props?.onClear();
                } catch (error) {}
              }
            }
          }}
          options={
            props?.loading ? ["Загрузка.."] : props?.array ? props.array : []
          }
          // renderOption={(props, option) => {
          //   const { key, ...optionProps } = props;
          //   return (
          //     <li key={option?.id} {...optionProps}>
          //       {option?.name}
          //     </li>
          //   );
          // }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={
                  props?.required && isObjectEmpty(props?.value)
                    ? props?.required
                    : undefined
                }
                variant="standard"
                // value={props?.value}
                helperText={props.helperText ? props.helperText : undefined}
                label={props?.title}
                // onBlur={(e) => {
                //   props?.setInputValue(e.target.value);
                // }}
                sx={{
                  fontSize: dataObj?.deviceWidth?.isMobile
                    ? "0.875rem"
                    : "1rem",
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: props?.startIconUrl ? (
                    <InputAdornment position="start" sx={{ margin: 0 }}>
                      <img
                        alt="markIcon"
                        src={props?.startIconUrl || ""}
                        style={{ maxHeight: "36px", maxWidth: "36px" }}
                      />
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            );
          }}
          value={props?.value ?? null}
        />
      </Box>
    </React.Fragment>
  );
}

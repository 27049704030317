import { Box, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import ParamBlock from "components/ParamBlock";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { findInArray, isIdentical, isObjectEmpty } from "data/functions";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DepartmentButtons } from "./DepartmentButtons";
import { DepartmentImage } from "./DepartmentImage";

export default function DepartmentProfile(props) {
  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ DepartmentProfile: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let location = useLocation();
  const params = useParams();

  const { dataObj } = GetData();
  const departmentID = location.state?.departmenID || params.id;
  const [el, setEl] = useState(
    findInArray(cInfo?.departments, departmentID, "ID") || {},
  );

  useEffect(() => {
    let val = findInArray(cInfo?.departments, departmentID, "ID");
    if (!isIdentical(el, val)) {
      setEl(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.departments]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Контакты"} />
        <Typography
          sx={{
            padding: "0.4rem",
            textAlign: dataObj?.deviceWidth?.isMobile ? "center" : "start",
          }}
          variant={dataObj?.deviceWidth?.isMobile ? "h5" : "h4"}
        >
          {el.name}
        </Typography>
        <Box sx={{ overflow: "auto" }}>
          <DepartmentImage
            images={
              !isObjectEmpty(el?.imageUrls)
                ? el?.imageUrls
                : [
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-1.jpg",
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-2.jpg",
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-3.jpg",
                  ]
            }
            info={true}
            title={el?.title}
          />
          <Box sx={{ margin: "1.5rem" }}>
            {el?.workTime && (
              <ParamBlock text={el?.workTime} title={"Время работы"} />
            )}
            {el?.phone && <ParamBlock text={el?.phone} title={"Телефон"} />}
            {el?.adress && <ParamBlock text={el?.adress} title={"Адрес"} />}
          </Box>
          <DepartmentButtons item={el} />
        </Box>
      </Box>
    </React.Fragment>
  );
}

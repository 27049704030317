import { CarListQuery } from "components/gql/queris/GetCarList";
import { CarRecsQuery } from "components/gql/queris/GetCarRecs";
import { ChannelsQuery } from "components/gql/queris/GetChannels";
import { GetCreditsQuery } from "components/gql/queris/GetCredits";
import { GetCreditsHistoryQuery } from "components/gql/queris/GetCreditsHistory";
import { DepartmentsQuery } from "components/gql/queris/GetDepartments";
import { OrdersListQuery } from "components/gql/queris/GetOrdersList";
import { GetPrices } from "components/gql/queris/GetPrices";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import { SelfInfoQuery } from "components/gql/queris/GetSelfInfo";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GQLTokenProvider } from "contexts/GQLTokenProvider";
import { GetAuthData } from "contexts/TAuthContext";
import { getElementsPrices, isIdentical, updateRecs } from "data/functions";
import { isObjectEmpty } from "data/functions";
import { gqlReducer } from "data/globals";
import React, { useEffect } from "react";

export const GQLQueryContext = React.createContext();

function GQLQueryProvider({ children }) {
  const { dataObj, forceSetDataObj, pushDataObj, setDataObj } = GetData();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useReducer(gqlReducer, {});
  const contextValue = {
    state: state,
  };
  const { cInfo } = GetCInfo();
  const { token } = GetAuthData();

  const [selfDataQuery] = SelfDataQuery();

  const [selfInfoQuery] = SelfInfoQuery();

  DepartmentsQuery(); //onCompleted is needed

  ChannelsQuery();

  const [carRefetch] = CarListQuery();

  async function checkClientID(token) {
    let { data, error } = await selfDataQuery({
      variables: {
        token: token,
      },
    });
    if (data) {
      if (!isObjectEmpty(data?.auth_getSelfData?.clientID, dataObj?.clientID)) {
        setDataObj(data?.auth_getSelfData?.clientID, "clientID");
      }
      selfInfoQuery({
        variables: {
          companyUuid: cInfo?.UUID,
        },
      });
      ordersListQuery();
      carRefetch();
      return;
    }
    if (error) {
      if (error.networkError) {
        return;
      } else {
        forceSetDataObj("", "clientID");
        return;
      }
    }
    return;
  }

  const [ordersListQuery] = OrdersListQuery();

  useEffect(() => {
    if (!isObjectEmpty(token) && cInfo?.UUID) {
      checkClientID(token);
    }
    // eslint-disable-next-line
  }, [token, cInfo?.UUID]);

  const [recQuery] = CarRecsQuery();

  useEffect(() => {
    if (!isObjectEmpty(dataObj?.carIds) && !isObjectEmpty(token)) {
      dataObj?.carIds?.forEach((carID) => {
        updateRecs(recQuery, carID, {
          dataObj,
          pushDataObj,
        });
      });
    }
    // eslint-disable-next-line
  }, [dataObj?.carIds]);

  GetCreditsQuery(); //onCompleted is needed

  GetCreditsHistoryQuery(); //onCompleted is needed

  const [pricesQuery] = GetPrices();

  const { basket, sectionItemAdd } = GetBasket();

  React.useEffect(() => {
    if (!isObjectEmpty(basket?.services)) {
      getElementsPrices(
        pricesQuery,
        basket?.services,
        {
          dataObj,
          setDataObj,
        },
        { cInfo },
        { sectionItemAdd },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.usedCar]);

  return (
    <GQLQueryContext.Provider value={contextValue}>
      <GQLTokenProvider>{children}</GQLTokenProvider>
    </GQLQueryContext.Provider>
  );
}
export const GetGQLContext = () => {
  try {
    const context = React.useContext(GQLQueryContext);
    return context;
  } catch {
    return "";
  }
};

export { GQLQueryProvider };

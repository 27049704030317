import { useMutation } from "@apollo/client";
import { USER_UPDATE } from "components/gql/gql_queries";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, logGQLError } from "data/functions";

export function UserUpdateMutation() {
  const queryName = "UserUpdate";
  const { setAlert } = GetAlertData();

  const [userUpdate, userUpdateVars] = useMutation(USER_UPDATE, {
    onCompleted: (data) => {
      if (data?.export_userUpdate?.success) {
        consoleBeauty(
          "Успешное изменение пользователя",
          "GraphQL",
          "darkgreen",
        );
        setAlert("Данные сохранены", "success");
      } else {
        logGQLError(queryName, data?.export_userUpdate);
        setAlert("Не удалось сохранить новые данные", "error");
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
      setAlert("Не удалось сохранить новые данные", "error");
    },
  });

  return [userUpdate, userUpdateVars];
}

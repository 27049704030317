import { Apartment } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import * as React from "react";
export const CompanyErrorPage = (props) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "fixed",
          bottom: "200px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 2,
          // height: "100%",
        }}
      >
        {/* <DepartmentIcon /> */}
        <Apartment sx={{ width: "8em", height: "8em" }} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#4da07c",
          borderRadius: "0",
          position: "fixed",
          top: "calc(100% - 240px)",
          width: "100%",
          height: "100%",
          background: "",
          zIndex: 1,
        }}
      >
        <Typography
          align="center"
          sx={{ marginTop: "2.0rem", color: "#161c23" }}
          variant="h6"
        >
          {props?.text}
        </Typography>
      </Box>
    </Box>
  );
};

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import MDEditor, { selectWord } from "@uiw/react-md-editor";
import { PageTitle } from "components/PageTitle";
import { SetCompanyExtDataMutation } from "components/gql/mutations/UpdateCompanyExtData";
import { GetCompanyExtDataQuery } from "components/gql/queris/GetCompanyExtData";
import { GetCInfo } from "contexts/CompanyContext";
import { getCompanyStyle, isObjectEmpty } from "data/functions";
import Logo from "data/images/logo.svg";
import { ImageField } from "pages/admin/ImageField";
import React, { useState } from "react";

export function AdminPanel(props) {
  const { cInfo, setCInfo } = GetCInfo();
  const [setCompanyExtDataQuery] = SetCompanyExtDataMutation();
  const [adminData, setAdminData] = useState({});
  const [getCompanyExtDataQuery] = GetCompanyExtDataQuery();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setAdminData((e) => {
      return { ...e, title: cInfo?.title };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cInfo?.title]);

  function reset() {
    setAdminData((e) => {
      return {
        ...{},
        loyaltyDescription: cInfo?.loyalty?.description,
        loyaltyUsed: cInfo?.loyalty?.used,
        title: cInfo?.title,
      };
    });
  }

  async function handleSubmit(vars) {
    if (props?.accessTokenExpires) {
      if (new Date(props?.accessTokenExpires) < new Date(Date.now())) {
        props?.setAdminToken({});
        return;
      }
    }

    if (!isObjectEmpty(vars?.input)) {
      vars.companyUuid = cInfo?.UUID;
      vars.adminToken = props?.adminToken;
      let { data } = await setCompanyExtDataQuery({
        variables: vars,
      });
      if (data?.acc_setCompanyExtData?.success) {
        // console.log(data);

        await getCompanyStyle(cInfo?.UUID, getCompanyExtDataQuery, {
          cInfo,
          setCInfo,
        });

        reset();
      }
    }
  }

  // return <InDevelop />;
  return (
    <React.Fragment>
      <PageTitle home={true} title="Управление приложением" />
      <Box sx={{ padding: "0.4rem" }}>
        <TextField
          disabled={true}
          fullWidth
          label={`Компания ${cInfo?.name ?? ""}`}
          name="company_uuid"
          sx={{ margin: "0.4em" }}
          value={cInfo?.UUID || ""}
          variant="standard"
        />
      </Box>
      <Styles
        adminData={adminData}
        handleSubmit={handleSubmit}
        setAdminData={setAdminData}
      />
      <Loyalty
        adminData={adminData}
        handleSubmit={handleSubmit}
        setAdminData={setAdminData}
      />
      <Metrica
        adminData={adminData}
        handleSubmit={handleSubmit}
        setAdminData={setAdminData}
      />
    </React.Fragment>
  );
}

function Styles(props) {
  const { cInfo, setCInfo } = GetCInfo();
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Typography sx={{ padding: "0.4em" }} variant="h5">
        Стилистика
      </Typography>
      <Divider />
      <Box
        sx={{
          padding: "0 1rem",
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          label="Заголовок окна"
          name="title"
          onChange={(el) => {
            props?.setAdminData((e) => {
              return { ...e, title: el.target.value };
            });
          }}
          sx={{ margin: "0.4em" }}
          value={props?.adminData?.title || ""}
          variant="standard"
        />

        <ImageField
          setAdminData={props?.setAdminData}
          startData={
            cInfo?.style?.favicon?.url
              ? cInfo?.style?.favicon?.url
              : "./favicon.ico"
          }
          title={"Иконка сайта"}
          value={props?.adminData?.favicon}
          valueName="favicon"
        />

        <ImageField
          setAdminData={props?.setAdminData}
          startData={
            cInfo?.style?.logoMain?.url ? cInfo?.style?.logoMain?.url : Logo
          }
          title={"Основной логотип (моб.)"}
          value={props?.adminData?.logoMain}
          valueName="logoMain"
        />
        <ImageField
          setAdminData={props?.setAdminData}
          startData={
            cInfo?.style?.logoSecond?.url ? cInfo?.style?.logoSecond?.url : Logo
          }
          title={"Дополнительный логотип"}
          value={props?.adminData?.logoSecond}
          valueName="logoSecond"
        />

        <Box
          sx={{
            width: "100%",
            padding: "0.4rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            // fullWidth
            color="error"
            onClick={() => {
              setOpen(true);
            }}
            sx={{ margin: "0.4em" }}
            variant="text"
          >
            Установить по умолчанию
          </Button>
          <Button
            // fullWidth
            onClick={() => {
              let vars = { input: {} };
              if (props?.adminData?.title !== cInfo?.title) {
                vars.input.title = props?.adminData?.title;
              }
              if (props?.adminData?.favicon) {
                vars.input.stylesFavicon = props?.adminData?.favicon;
              }
              if (props?.adminData?.logoMain) {
                vars.input.stylesLogoMain = props?.adminData?.logoMain;
              }
              if (props?.adminData?.logoSecond) {
                vars.input.stylesLogoSecond = props?.adminData?.logoSecond;
              }
              props?.handleSubmit(vars);
            }}
            sx={{ margin: "0.4em" }}
            variant="outlined"
          >
            Сохранить стиль
          </Button>
          <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>Подтверждение сброса</DialogTitle>
            <DialogContent>
              Стилистика будет сброшена до настроек по умолчанию.
              <br /> Без возможности восстановления!
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => setOpen(false)}>
                Отмена
              </Button>
              <Button
                autoFocus
                color="error"
                onClick={() => {
                  let vars = { input: {} };
                  vars.input.title = "";
                  vars.input.stylesFavicon = "";
                  vars.input.stylesLogoMain = "";
                  vars.input.logoSecond = "";
                  props?.handleSubmit(vars);
                  setOpen(false);
                }}
              >
                Сбросить
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function Loyalty(props) {
  const { cInfo, setCInfo } = GetCInfo();
  const [checked, setChecked] = React.useState(cInfo?.loyalty?.used || false);
  React.useEffect(() => {
    setChecked(cInfo?.loyalty?.used);
  }, [cInfo?.loyalty?.used]);
  const [value, setValue] = React.useState(cInfo?.loyalty?.description || "");
  return (
    <React.Fragment>
      <Typography sx={{ padding: "0.4em" }} variant="h5">
        Система лояльности
      </Typography>
      <Divider />
      <Box
        sx={{
          padding: "0 1rem",
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl component="fieldset" fullWidth variant="standard">
          {/* <FormLabel component="legend">
            Использование системы лояльности
          </FormLabel> */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => {
                    props?.handleSubmit({
                      input: {
                        loyaltyUsed: event.target.checked,
                      },
                    });
                  }}
                  name="loyalty-used"
                />
              }
              label="Использовать систему лояльности"
            />
          </FormGroup>
          <FormHelperText>
            При выключенном значении убирает ссылки на страницу с бонусами
          </FormHelperText>
        </FormControl>
        <FormControl
          component="fieldset"
          fullWidth
          sx={{ marginTop: "12px" }}
          variant="standard"
        >
          <FormLabel component="legend">Описание</FormLabel>
          <MDEditor height={200} onChange={setValue} value={value} />
          <Button
            // fullWidth
            onClick={() => {
              if (cInfo?.loyalty?.description !== value) {
                let vars = {
                  input: {
                    loyaltyDescription: value,
                  },
                };
                props?.handleSubmit(vars);
              }
            }}
            sx={{ margin: "0.4em" }}
            variant="outlined"
          >
            Сохранить описание
          </Button>
        </FormControl>
      </Box>
    </React.Fragment>
  );
}

function Metrica(props) {
  const { cInfo, setCInfo } = GetCInfo();
  return (
    <React.Fragment>
      <Typography sx={{ padding: "0.4em" }} variant="h5">
        Метрики
      </Typography>
      <Divider />
      <Box
        sx={{
          padding: "0 1rem",
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          defaultValue={cInfo?.metrika?.yandex || ""}
          fullWidth
          label="Идентификатор Яндекс метрики"
          name="metrica-ya"
          onBlur={(e) => {
            if (cInfo?.metrika?.yandex !== e?.target?.value) {
              let vars = {
                input: {
                  metrikaYandex: e?.target?.value,
                },
              };

              props?.handleSubmit(vars);
            }
          }}
          sx={{ margin: "0.4em" }}
          variant="standard"
        />
      </Box>
    </React.Fragment>
  );
}

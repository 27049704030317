import { Box, Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { isOrderStatusActive } from "data/functions";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { HeaderRow } from "components/HeaderRow";
import QRBlock, { QRDialog } from "components/QRBlock";
import { MiniOrderBlock } from "components/sliders/MiniOrderBlock";
// import moment from "moment";

export const MiniDocumentSlider = (props) => {
  const [arrayOrders, setArrayOrders] = useState([]);
  const { dataObj } = GetData();
  const [activeSlide, setActiveSlide] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!isObjectEmpty(dataObj.orders)) {
      setArrayOrders(dataObj.orders);
    }
  }, [dataObj.orders]);

  return (
    <React.Fragment>
      {!isObjectEmpty(dataObj?.activeOrdersCount) && (
        <Card
          sx={{
            margin: "0.4rem",
            padding: 0,
            background: "transparent",
            boxShadow: "none",
            position: "relative",
          }}
        >
          <CardContent sx={{ padding: "0.36rem 0!important" }}>
            <HeaderRow
              boxShadow="none"
              margin="0px !important"
              navigateOnClick="/orders"
              padding="0px !important"
              text="Текущие заказы"
            />

            <Swiper
              initialSlide={activeSlide}
              injectStyles={[
                ":host .swiper { align-items: center !important; }",
              ]}
              modules={[Mousewheel]}
              mousewheel={{
                releaseOnEdges: true,
              }}
              onSlideChange={(e) => {
                setActiveSlide(e.realIndex);
              }}
              // onSliderMove={(e) => console.log(e)}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              slidesPerView={"auto"}
              // slidesPerView={dataObj?.deviceWidth?.isMobile ? 1 : 3}
              spaceBetween={14}
              style={{
                "--swiper-navigation-size": "14px",
                alignItems: "center !important",
                "--swiper-pagination-color":
                  theme?.custom?.telegramColors?.button_color,
                "--swiper-pagination-bullet-inactive-color":
                  theme?.custom?.telegramColors?.button_color,
                "--swiper-pagination-bullet-inactive-opacity": 0.8,
                borderRadius: "4px",
                padding: "0.4rem 64px 0.4rem 0.2rem",
                width: "100%",
              }}
            >
              <SwiperSlide
                onClick={(e) => {
                  setOpen(true);
                }}
                style={{
                  height: "auto",
                  width: "45px",
                  background: "transparent",
                  borderRadius: "4px",
                  transform: "none",
                  backfaceVisibility: "visible",
                }}
              >
                <Box
                  sx={{
                    margin: "-4px 0px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "4px",
                    position: "relative",
                    padding: "4px",
                    height: "100%",
                    overflow: "hidden",
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                  }}
                >
                  <QRBlock temp={true} />
                </Box>
              </SwiperSlide>
              {arrayOrders.map((order, index) => {
                if (order && isOrderStatusActive(order?.status) && index < 24) {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        maxWidth: "254px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "auto",
                      }}
                    >
                      <MiniOrderBlock item={order} margin={"-4px -2px"} />
                    </SwiperSlide>
                  );
                } else return "";
              })}
            </Swiper>
          </CardContent>
        </Card>
      )}
      <QRDialog open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

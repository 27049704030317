import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import styled from "@emotion/styled";
import { AttachFile, Cancel, Close, Done } from "@mui/icons-material";
import { Mic } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField as MuiTextField,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";

import React, { useEffect, useState } from "react";

import { ExportUploadUrlQuery } from "components/gql/queris/ExportUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { translateError } from "data/functions";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { uploadFile } from "tools/requests";
import { ChatEmoji } from "./ChatEmoji";

const TextField = styled(MuiTextField)(spacing);

export function ChatInput(props) {
  const [value, setValue] = useState("");
  const { setAlert } = GetAlertData();
  const recorderControls = useAudioRecorder(null, (e) => {
    setAlert(
      translateError(e?.message)
        ? translateError(e?.message)
        : e?.message
          ? e?.message
          : "",
      "warning",
    );
  });

  return (
    <React.Fragment>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "10px 0",
          boxShadow: "1px -1px 0px 0px rgba(0, 0, 0, 0.2)",
        }}
      >
        {!recorderControls?.isRecording && (
          <React.Fragment>
            <FileInput {...props} />
            <TextInput {...props} setValue={setValue} value={value} />
            <ChatEmoji {...props} setValue={setValue} />
          </React.Fragment>
        )}
        <SendAndMicInput
          recorderControls={recorderControls}
          {...props}
          setValue={setValue}
          value={value}
        />
      </Paper>
    </React.Fragment>
  );
}

function FileInput(props) {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { setAlert } = GetAlertData();
  const [queryUpload] = ExportUploadUrlQuery();
  const inputRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const attachFile = (type) => {
    let accept =
      type === "photo"
        ? dataObj?.deviceWidth?.isMobile
          ? "image/*;capture=camera"
          : "image/*"
        : type === "video"
          ? "video/mp4,video/x-m4v,video/*"
          : "audio/*,video/*,image/*";
    inputRef.current.accept = accept;
    inputRef.current.alt = type;
    inputRef.current.click();
    setAnchorEl(null);
  };

  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };
  async function changeImageArray(array) {
    if (array && array[0] && cInfo?.UUID) {
      for (let i = 0; i < array.length; i++) {
        // showLoading();
        if (array[i]?.type === "application/x-msdownload") {
          setAlert("Исполнительные файлы не разрешены", "warning");
          continue;
        }
        let mb = array[i]?.size / (1024 * 1024);
        if (mb > 30) {
          setAlert("Файлы размером более 30 МБ не разрешены", "warning");
          continue;
        }
        let { data } = await queryUpload({
          variables: {
            companyUuid: cInfo?.UUID,
            filename: array[i].name,
            prefix: "deals",
          },
        });
        if (data?.export_uploadUrl?.fileID) {
          let success = await uploadFile(data?.export_uploadUrl, array[0]);
          if (!success) {
            setAlert("Не удалось загрузить файл", "warning");
          } else {
            let obj = {
              fileData: array[i],
              fileId: data?.export_uploadUrl?.fileID,
            };
            let url = window.URL.createObjectURL(obj?.fileData);
            props?.addMessage(url, inputRef.current.alt, obj?.fileData?.name);
          }
          //There wll be a func upload file
        }
      }
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <React.Fragment>
      <input
        // accept="image/*"
        accept="image/*;capture=camera"
        alt="upload"
        // capture="environment"
        id={`upload-chat`}
        multiple={false}
        onChange={handleChange}
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
      />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: -16,
          horizontal: "center",
        }}
        aria-labelledby="demo-positioned-button"
        id="demo-positioned-menu"
        onClose={() => setAnchorEl(null)}
        open={open}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => attachFile("file")}>Файл</MenuItem>
        <MenuItem onClick={() => attachFile("photo")}>Изображение</MenuItem>
        <MenuItem onClick={() => attachFile("video")}>Видео</MenuItem>
      </Menu>
      <Box
        sx={{
          padding: "0px 0px 0px 16px",
        }}
      >
        <IconButton onClick={handleClick}>
          <AttachFile
            color={open ? "primary" : "disabled"}
            sx={{
              width: "1.2em",
              height: "1.2em",
              transform: "rotate(220deg)",
            }}
          />
        </IconButton>
      </Box>
    </React.Fragment>
  );
}

function TextInput(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      <TextField
        fullWidth
        multiline={true}
        onChange={(e) => props?.setValue(e.target.value)}
        placeholder={
          dataObj?.deviceWidth?.isMobile
            ? "Сообщение "
            : "Написать сообщение..."
        }
        sx={{
          padding: "4px",
          "& label.Mui-focused": {
            color: "transparent",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& .MuiOutlinedInput-root": {
            padding: "6px 2px",
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
        value={props?.value}
        variant="outlined"
      />
    </React.Fragment>
  );
}
function SendAndMicInput(props) {
  const [successRec, setSuccessRec] = useState(false);

  useEffect(() => {
    if (!props?.recorderControls?.recordingBlob) return;
    if (!successRec) return;
    addAudioElement(props?.recorderControls?.recordingBlob);
    setSuccessRec(false);
    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [props?.recorderControls?.recordingBlob]);

  useEffect(() => {
    let recording = document.getElementsByClassName("audio-recorder-status");
    if (recording && recording[0]) {
      recording[0].innerHTML = recording[0].innerHTML.replace(
        "Recording",
        "Запись",
      );
    }
  }, [props?.recorderControls?.isRecording]);

  const addAudioElement = (blob) => {
    props?.addMessage(URL.createObjectURL(blob), "audio");
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: !props?.recorderControls?.isRecording
            ? "0px 16px 0px 0px"
            : "3px",
          width: props?.recorderControls?.isRecording ? "100%" : undefined,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // paddingRight: "16px",
            alignItems: "center",
          }}
        >
          {props?.value ? (
            <IconButton
              onClick={() => {
                if (props?.value) {
                  props?.addMessage(props?.value, "text");
                  props?.setValue("");
                }
              }}
              sx={{ margin: "0 4px" }}
            >
              <SendIcon
                color="disabled"
                sx={{
                  width: "1.2em",
                  height: "1.2em",
                }}
              />
            </IconButton>
          ) : (
            <React.Fragment>
              {!props?.recorderControls?.isRecording && (
                <IconButton
                  onClick={() => {
                    setSuccessRec(false);
                    props?.recorderControls.startRecording();
                  }}
                  sx={{ margin: "0 4px" }}
                >
                  <Mic
                    color="disabled"
                    sx={{
                      width: "1.2em",
                      height: "1.2em",
                    }}
                  />
                </IconButton>
              )}
              {props?.recorderControls?.isRecording && (
                <IconButton
                  onClick={() => {
                    setSuccessRec(false);
                    props?.recorderControls.stopRecording();
                  }}
                  sx={{ marginLeft: "16px" }}
                >
                  <Close
                    color="error"
                    sx={{
                      width: "1.2em",
                      height: "1.2em",
                    }}
                  />
                </IconButton>
              )}

              <AudioRecorder
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                classes={{
                  AudioRecorderClass: "audio-main",
                }}
                downloadFileExtension="webm"
                downloadOnSavePress={false}
                // onNotAllowedOrFound={(e) => setAlert(e, "warning")}
                // onRecordingComplete={(blob) => addAudioElement(blob, true)}
                recorderControls={props?.recorderControls}
                sx={{
                  // "& audio-recorder": {
                  backgroundColor: "transparent!important",
                  // },
                }}
              />
              {props?.recorderControls?.isRecording && (
                <IconButton
                  onClick={() => {
                    setSuccessRec(true);
                    props?.recorderControls.stopRecording();
                  }}
                  sx={{ marginRight: "16px" }}
                >
                  <Done
                    color="primary"
                    sx={{
                      width: "1.2em",
                      height: "1.2em",
                    }}
                  />
                </IconButton>
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

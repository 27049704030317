import React from "react";
import { RouterProvider } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AlertProvider } from "contexts/AlertContext";
import { ApolloContext } from "contexts/ApolloContext";
import { CompanyProvider } from "contexts/CompanyContext";
// import { OidcProvider } from "contexts/OidcContext";
import { DataProvider } from "contexts/DataContext";
import { DialogProvider } from "contexts/DialogContext";
import { GQLQueryProvider } from "contexts/GQLQueryContext";
// import { LogsProvider } from "contexts/LogsContext";
import { TAuthProvider } from "contexts/TAuthContext";
import { ThemeProvider } from "contexts/ThemeContext";
// import { WebAppProvider } from "@vkruglikov/react-telegram-web-app";
// import "react-chat-elements/dist/main.css";
import "data/css/chat.css";
import { router } from "data/routes";
import ruLocale from "date-fns/locale/ru";

const AppN = React.memo((AppN) => {
  return (
    <LocalizationProvider adapterLocale={ruLocale} dateAdapter={AdapterDateFns}>
      {/* <LogsProvider> */}
      <ApolloContext>
        <TAuthProvider>
          <CompanyProvider>
            <AlertProvider>
              <DataProvider>
                <ThemeProvider>
                  <GQLQueryProvider>
                    <DialogProvider>
                      <RouterProvider router={router} />
                    </DialogProvider>
                  </GQLQueryProvider>
                </ThemeProvider>
              </DataProvider>
            </AlertProvider>
          </CompanyProvider>
        </TAuthProvider>
      </ApolloContext>
      {/* </LogsProvider> */}
    </LocalizationProvider>
  );
});

const App = () => {
  return <AppN />;
};

export default App;

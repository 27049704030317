import styled from "@emotion/styled";
import React from "react";

import { Avatar, Chip, Grid, Paper, Typography } from "@mui/material";

import { Face3, Face6 } from "@mui/icons-material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { useNavigate } from "react-router-dom";

const Footer = styled(Paper)`
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: relative;
`;

const FooterText = styled(Typography)``;

const FooterBadge = styled(Chip)`
  height: 20px;
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    padding-left: ${(props) => props.theme?.spacing(4)};
    padding-right: ${(props) => props.theme?.spacing(4)};
  }
`;

const SidebarFooter = ({ ...rest }) => {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <Footer>
      <Grid
        container
        onClick={() => navigate("/profile")}
        spacing={2}
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgb(0 0 0 / 5%)",
          },
          padding: "0.4rem",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
        >
          {!isObjectEmpty(dataObj?.clientAvatar) ? (
            <Avatar
              alt={dataObj?.clientName}
              src={dataObj?.clientAvatar}
              sx={{ width: "2em", height: "2em" }}
            />
          ) : dataObj?.clientInfoArray?.sex === "female" ? (
            <Face3 sx={{ width: "2rem", height: "2rem" }} />
          ) : (
            <Face6 sx={{ width: "2rem", height: "2rem" }} />
          )}
        </Grid>
        <Grid item>
          {dataObj?.clientInfoArray?.name?.firstName ||
          dataObj?.clientInfoArray?.name?.lastName ||
          dataObj?.clientInfoArray?.name?.middleName ? (
            <React.Fragment>
              <FooterText variant="body2">
                {dataObj?.clientInfoArray?.name?.lastName +
                  " " +
                  dataObj?.clientInfoArray?.name?.firstName}
              </FooterText>
              <FooterText variant="body2">
                {dataObj?.clientInfoArray?.name?.middleName}
              </FooterText>{" "}
            </React.Fragment>
          ) : dataObj?.clientInfoArray?.name?.fullName ? (
            dataObj?.clientInfoArray?.name?.fullName
          ) : (
            "Не задано"
          )}
        </Grid>
        <Grid item sx={{ position: "relative" }}></Grid>
      </Grid>
      <FooterBadge
        label={dataObj?.activeOrdersCount || undefined}
        sx={{
          display: isObjectEmpty(dataObj?.activeOrdersCount)
            ? "none"
            : undefined,
        }}
      />
    </Footer>
  );
};

export default SidebarFooter;

import { Fade, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { translateUrgency } from "data/functions";
import { BlockWithPseudo, MainBox, UrgencyBox } from "pages/recs/RecBlock";
import React from "react";

export const CarListRecBlock = (props) => {
  return (
    <MainBox key={props.index} margin={"0.3em 0"} padding={"3px 0 3px 24px"}>
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
        arrow
        placement={"top-start"}
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: "0.4rem",
                  marginLeft: "-4px",
                },
            },
          },
        }}
        title={translateUrgency(props.recItem.urgency)}
      >
        <UrgencyBox critical={props.recItem.urgency} left={"10px"} />
      </Tooltip>

      <BlockWithPseudo
        critical={props.recItem.urgency}
        cursor={"auto"}
        disablePseudo={true}
      >
        <Typography variant={props?.isMobile ? "caption" : "body2"}>
          {props.recItem.text}
        </Typography>
      </BlockWithPseudo>
    </MainBox>
  );
};

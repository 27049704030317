import React from "react";

import { Apartment } from "@mui/icons-material";
import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export function DepartmentImage(props) {
  const { dataObj } = GetData();

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      {!isObjectEmpty(props?.images) && (
        <Swiper
          // centeredSlides={props?.images?.length === 1 ? true : false}
          modules={[Pagination, Navigation, Mousewheel]}
          mousewheel={{
            releaseOnEdges: true,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          slidesPerView={
            props?.perView
              ? props?.perView
              : dataObj?.deviceWidth?.isMobile || dataObj?.deviceWidth?.isPad
                ? 1
                : props?.images.length <= 3
                  ? props?.images.length
                  : 3
          }
          spaceBetween={16}
        >
          {(props?.images ? props?.images : []).map((el, index) => (
            <SwiperSlide
              key={index}
              style={{
                height:
                  props?.info && !dataObj?.deviceWidth?.isMobile
                    ? "460px"
                    : "240px",
              }}
            >
              <Box
                sx={{
                  margin: "0.4rem 0",

                  // height: dataObj?.deviceWidth?.isMobile
                  //   ? "calc(68vw - 0.66rem)"
                  //   : dataObj?.deviceWidth?.isPad
                  //     ? "calc(15vw - 0.66rem)"
                  //     : "10vw",
                  // width: "100%",
                  maxHeight:
                    props?.info && !dataObj?.deviceWidth?.isMobile
                      ? "460px"
                      : "240px",
                  backgroundImage: `url(${el})`,
                  backgroundSize: "auto 100%",
                  backgroundPosition: "top center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img
                  alt={"dep_" + index}
                  onError={(e) => {
                    // e.target.hidden = true;
                  }}
                  src={el}
                  style={{ visibility: "hidden", maxWidth: "100%" }}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isObjectEmpty(props?.images) && (
        <Apartment sx={{ height: "7rem", width: "7rem" }} />
      )}
    </Box>
  );
}

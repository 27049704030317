import { useLazyQuery } from "@apollo/client";
import { ORDERS_LIST } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import {
  consoleBeauty,
  isIdentical,
  isObjectEmpty,
  logGQLError,
} from "data/functions";

export function OrdersListQuery() {
  const queryName = "OrdersList";
  const { dataObj, setDataObj } = GetData();
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(ORDERS_LIST, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onCompleted: (data) => {
      onCompletedOrders(data, { dataObj, setDataObj });
    },
    onError: (e) => {
      onCompletedOrders(undefined, { dataObj, setDataObj });
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

export function onCompletedOrders(data, { dataObj, setDataObj }, set = true) {
  let newArr = (
    !isObjectEmpty(data?.export_ordersList) ? data?.export_ordersList : []
  ).sort((a, b) => {
    let dateA = new Date(a.docDate || 0);
    let dateB = new Date(b.docDate || 0);
    if (dateA < dateB) return 1;
    else return -1;
  });

  if (!isIdentical(newArr, dataObj?.orders)) {
    consoleBeauty("Получен список документов", "GraphQL", "darkgreen");
    setDataObj(newArr, "orders");
  }
  // setDataObj(new Date(Date.now()), "lastDataUpdate");
}

import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { DrawerBlock } from "components/DrawerBlock";
import { ExtPaymentCreateMutation } from "components/gql/mutations/PaymentCreate";
import { ExportPaymentCheckQuery } from "components/gql/queris/ExportPaymentCheck";
import { OrderReadQuery } from "components/gql/queris/GetOrderInfo";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  isActiveChannel,
  isObjectEmpty,
  translateOrderStatus,
} from "data/functions";
import { constants } from "data/globals";
import sberLogo from "data/images/sber.png";
import { ReactComponent as SbpLogo } from "data/images/sbp.svg";
import tinkoffLogo from "data/images/tinkoff.png";
import React from "react";
import { Link } from "react-router-dom";

export function PayButton(props) {
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [payment, setPayment] = React.useState();
  const [filterArray, setFilterArray] = React.useState([]);
  const [paymentCreateQuery] = ExtPaymentCreateMutation();
  const [paymentCheckQuery] = ExportPaymentCheckQuery();
  const { setAlert } = GetAlertData();
  let findSber = isActiveChannel(cInfo?.channels, "Sber_Payment");
  let findTinkoff = isActiveChannel(cInfo?.channels, "Tinkoff_Payment");
  const [orderGQL] = OrderReadQuery();
  React.useEffect(() => {
    let arr = [];
    let findSber = isActiveChannel(cInfo?.channels, "Sber_Payment");
    if (findSber) {
      arr = [
        ...arr,
        {
          icon: sberLogo,
          name: "Сбербанк",
        },
      ];
    }
    let findTinkoff = isActiveChannel(cInfo?.channels, "Tinkoff_Payment");
    if (findTinkoff) {
      arr = [
        ...arr,
        {
          icon: tinkoffLogo,
          name: "Т‑Банк",
        },
      ];
    }
    setFilterArray(arr);
  }, [cInfo?.channels]);

  async function getUrl(uuid, orderID) {
    if (!uuid || !orderID) return;
    let { data } = await paymentCreateQuery({
      variables: {
        companyUuid: uuid,
        orderId: orderID,
      },
    });
    if (
      data?.export_paymentCreate?.success &&
      data?.export_paymentCreate?.result?.paymentUrl
    ) {
      window.open(data?.export_paymentCreate?.result?.paymentUrl, "_blank");
      setPayment(data?.export_paymentCreate?.result);
    } else {
      setAlert("Оплата сейчас недоступна.\nПопробуйте позже", "warning");
    }
  }

  async function checkPayment(orderID, paymentID) {
    if (!orderID || !paymentID) return;
    let { data } = await paymentCheckQuery({
      variables: {
        companyUuid: cInfo?.UUID,
        orderId: orderID,
        paymentId: paymentID,
        userId: cInfo?.clientID,
      },
    });
    if (data?.export_paymentCheck?.code === 200) {
      // window.open(data?.export_paymentCreate?.result?.paymentUrl, "_blank");
      return true;
    } else {
      return false;
    }
  }

  React.useEffect(() => {
    if (payment) {
      console.log(payment);
      const fetchData = async (orderID, paymentID) => {
        let success = await checkPayment(orderID, paymentID);
        if (success) {
          // console.log("Something done");
          setTimeout(() => {
            orderGQL({
              variables: { orderId: orderID },
            });
            // console.log("Delayed for 3 second.");
          }, 3000);
          clearInterval(timerInterval);
        }
      };
      const timerInterval = setInterval(() => {
        fetchData(props?.orderID, payment?.paymentID);
      }, 5000);

      // Cleanup the interval when the component unmounts
      return () => clearInterval(timerInterval);
    }
  }, [payment]);

  return (
    <React.Fragment>
      {(findSber || findTinkoff) &&
        // dataObj?.includeBeta &&
        translateOrderStatus(props?.docItem.payStatus) ===
          constants.ruPAY_WAITING &&
        typeof props?.docItem?.payDebt === "number" &&
        props?.docItem?.payDebt > 0 && (
          <Box
            sx={{
              minHeight: "50px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => getUrl(cInfo?.UUID, props?.orderID)}
              // component={Link}
              // fullWidth={dataObj?.deviceWidth?.isMobile ? true : false}
              sx={{ textTransform: "none", width: "70%" }}
              target="_blank"
              // to="https://qr.nspk.ru/BD20001DPB17VJ3O8L5BFC1AK6F9HPT8?type=02&bank=100000000004&sum=1000&cur=RUB&crc=9970"
              variant="contained"
            >
              <SbpLogo style={{ height: "24px", width: "24px" }} /> К оплате{" "}
              {props?.docItem?.payDebt} руб.
            </Button>
          </Box>
        )}
      <DrawerBlock
        component={<DrawerEl />}
        height={"auto"}
        itemArray={filterArray || []}
        onClickItemFunc={(el = "") => {
          setOpenFilter(false);
          console.log(el);
        }}
        onCloseFunc={() => {
          setOpenFilter(false);
        }}
        open={openFilter}
        showFull={false}
        title="Оплатить по СБП"
      />
    </React.Fragment>
  );
}

const DrawerEl = (props) => {
  if (isObjectEmpty(props?.el)) return "";
  return (
    <React.Fragment>
      {props?.el && (
        <Paper
          index={props?.index}
          sx={{
            padding: "0.86rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            alt={props?.el?.name}
            onError={(e) => {
              // e.target.hidden = true;
            }}
            src={props?.el?.icon}
            style={{
              overflow: "hidden",
              // background: "white",
              width: "45px",
            }}
          />
          <Typography sx={{ padding: "0 0.86rem" }}>
            {props?.el?.name}
          </Typography>
          <Divider />
        </Paper>
      )}
    </React.Fragment>
  );
};

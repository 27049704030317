import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

import { isObjectEmpty } from "data/functions";

import React from "react";

export function LoyaltyAccordion(props) {
  return (
    <React.Fragment>
      {(!props?.empty || props?.dontHide) && (
        <Paper sx={{ padding: "2px 16px" }}>
          <Accordion
            defaultExpanded={props?.defaultExpanded ?? false}
            disableGutters
            // disabled={props?.empty ?? false}
            sx={{
              backgroundColor: "transparent",
              backgroundImage: "none",
              border: "none",
              boxShadow: "none",
              margin: "2px",
              "& .MuiAccordionSummary-root": {
                pointerEvents: props?.empty ? "none" : undefined,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              sx={{
                minHeight: "36px",

                "& .MuiAccordionSummary-expandIconWrapper": {
                  display: props?.empty ? "none" : undefined,
                },

                "& .MuiAccordionSummary-content": {
                  margin: "4px",
                  alignItems: "center",
                },
              }}
            >
              {typeof props?.title === "object" ? (
                props?.title
              ) : (
                <Box>
                  <Typography variant="h6">{props?.title}</Typography>
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 1rem" }}>
              {props?.value}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </React.Fragment>
  );
}

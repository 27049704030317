import React from "react";

import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";

import { Apartment } from "@mui/icons-material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";

import { SliceTextOneRow, SliceTextTwoRows } from "data/styleGlobals";
import { CallButton } from "pages/departments/CallButton";
import { MapButton } from "pages/departments/MapButton";
import { ReviewsButton } from "pages/departments/ReviewsButton";
import { useNavigate } from "react-router-dom";
import { DepartmentImage } from "../info/DepartmentImage";

function DepartmentHeader(props) {
  return (
    <React.Fragment>
      <CardHeader
        avatar={<Apartment sx={{ height: "1.5rem", width: "1.5rem" }} />}
        subheader={
          props?.subheader +
          "" +
          (props?.subheader && props?.workTime
            ? ", " + props?.workTime
            : props?.workTime
              ? props?.workTime
              : "")
        }
        sx={{
          height: "55px",
          position: "absolute",
          top: 0,
          left: 0,
          padding: "0 12px",
          ".MuiCardHeader-subheader": {
            lineHeight: "1.2",
            ...SliceTextTwoRows,
          },
          ".MuiCardHeader-title": {
            // maxWidth: "80%",
            ...SliceTextOneRow,
          },
        }}
        title={props?.title}
      />
    </React.Fragment>
  );
}

// function DepartmentImage(props) {
//   const { dataObj } = GetData();
//   if (isObjectEmpty(props?.images))
//     return (
//       <Box
//         sx={{
//           padding: 0,
//           margin: 0,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",

//           maxHeight: "200px",
//           overflow: "hidden",
//         }}
//       >
//         {props.url ? (
//           <img
//             alt={props?.title}
//             onError={(e) => {
//               // e.target.hidden = true;
//             }}
//             src={props?.images}
//             style={{ maxWidth: "100%" }}
//           />
//         ) : (
//           <Apartment sx={{ height: "7rem", width: "7rem" }} />
//         )}
//       </Box>
//     );
//   else
//     return (
//       <React.Fragment>
//         <Box
//           sx={{
//             margin: "0.4rem 0",

//             // height: dataObj?.deviceWidth?.isMobile
//             //   ? "calc(68vw - 0.66rem)"
//             //   : dataObj?.deviceWidth?.isPad
//             //     ? "calc(15vw - 0.66rem)"
//             //     : "10vw",
//             // width: "100%",
//             maxHeight: "240px",
//             backgroundImage: `url(${props?.images[0]})`,
//             backgroundSize: "auto 100%",
//             backgroundPosition: "top center",
//             backgroundRepeat: "no-repeat",
//           }}
//         >
//           <img
//             alt={props?.title}
//             onError={(e) => {
//               // e.target.hidden = true;
//             }}
//             src={props?.images[0]}
//             style={{ visibility: "hidden", maxWidth: "100%" }}
//           />
//         </Box>
//       </React.Fragment>
//     );
// }

function DepartmentDescription(props) {
  return (
    <React.Fragment>
      {!isObjectEmpty(props?.description) && (
        <CardContent>
          <Typography color="text.secondary" variant="body2">
            {props?.description}
          </Typography>
        </CardContent>
      )}
    </React.Fragment>
  );
}

export function DepartmentBlock(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        width: dataObj?.deviceWidth?.isMobile ? "inherit" : "325px",
        background: "transparent",
        maxHeight: "350px",
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box onClick={(e) => navigate(`/departments/${props?.id}`)}>
        <DepartmentHeader
          subheader={props?.subheader}
          title={props?.title}
          workTime={props?.workTime}
        />
        <Box
          sx={{ marginTop: "50px", marginBottom: "45px", overflow: "hidden" }}
        >
          <DepartmentImage
            images={
              !isObjectEmpty(props?.imageUrls)
                ? props?.imageUrls
                : [
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-1.jpg",
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-2.jpg",
                    "https://cloud5.5-systems.ru/5systems/avtokompleks-3.jpg",
                  ]
            }
            perView={1}
            title={props?.title}
          />{" "}
          {/* <Box sx={{ position: "absolute", bottom: "0.5rem", right: "0.5rem" }}>
            <Typography sx={{ fontSize: "0.9em", opacity: "0.5" }}>
              {props?.workTime}
            </Typography>
          </Box> */}
          <DepartmentDescription description={props?.description} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "50px",
          position: "absolute",
          bottom: "4px",
          left: "4px",
        }}
      >
        <CallButton phone={props?.phone} />
        <MapButton
          locationLatitude={props.locationLatitude}
          locationLongitude={props?.locationLongitude}
        />
        {!isObjectEmpty(props?.reviews) && dataObj.includeBeta && (
          <ReviewsButton reviews={props?.reviews} />
        )}
      </Box>
    </Card>
  );
}

import { Box } from "@mui/material";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { PromoBox } from "pages/promotions/list/PromoList";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const PromotionSlider = (props) => {
  const [array, setArray] = useState([]);
  const { dataObj } = GetData();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isObjectEmpty(dataObj?.promotions)) {
      setArray(dataObj?.promotions);
    } else {
      setArray([]);
    }
  }, [dataObj?.promotions]);
  return (
    <React.Fragment>
      {/* {!isObjectEmpty(dataObj?.activePromotion) && ( */}
      <Box sx={{ margin: "0.4rem 0", width: "97%" }}>
        <Swiper
          autoplay={
            !dataObj?.deviceWidth?.isMobile
              ? {
                  delay: 15000,
                  disableOnInteraction: false,
                }
              : false
          }
          // centeredSlides={true}
          injectStyles={[":host .swiper { align-items: center !important; }"]}
          modules={[Navigation, Autoplay, Pagination, Mousewheel]}
          mousewheel={{
            releaseOnEdges: true,
          }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={
            dataObj?.deviceWidth?.isMobile
              ? 1
              : // : dataObj?.deviceWidth?.isPad
                //   ? 2
                "auto"
          }
          spaceBetween={12}
          style={{
            padding: "0.4rem 36px",
            width: "100%",
          }}
        >
          {(array ? array : []).map((item, index) => {
            if (props?.fromMain ? item?.showOnMain : true) {
              return (
                <SwiperSlide
                  key={index}
                  onClick={(e) =>
                    navigate(`/promotions/${item?.id}`, {
                      state: { id: item?.id },
                    })
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    /* align-content: center; */
                    alignItems: "center",
                    justifyContent: "center",
                    // minHeight: "70px",
                    // margin: "12px",
                    // minWidth: "80%",
                    maxWidth: "450px",
                    height: "100%",
                  }}
                >
                  <PromoBox el={item} index={index} slider={true} />
                </SwiperSlide>
              );
            } else return "";
          })}
        </Swiper>
      </Box>
      {/* )} */}
    </React.Fragment>
  );
};

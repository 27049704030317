import { useMutation } from "@apollo/client";
import { CAR_UPDATE } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, logGQLError } from "data/functions";

export function CarUpdateMutation() {
  const queryName = "CarUpdate";
  const { setAlert } = GetAlertData();
  const { dataObj, forceSetDataObj, setDataObj } = GetData();
  const [carUpdate, carUpdateVars] = useMutation(CAR_UPDATE, {
    onCompleted: (data) => {
      onCompletedCarUpdate(
        data,
        { dataObj, forceSetDataObj, setDataObj },
        { setAlert },
      );
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [carUpdate, carUpdateVars];
}

export function onCompletedCarUpdate(
  data,
  { dataObj, forceSetDataObj, setDataObj },
  { setAlert },
) {
  let carIds = [];
  let carListWithSold = [...(dataObj.carArrWithSold ?? [])].map(
    (item, index) => {
      if (item.car.id === data.export_carUpdate.car.id) {
        return data.export_carUpdate;
      }
      return item;
    },
  );

  let carList = [...(carListWithSold ?? [])].filter((item, index) => {
    return item?.sold !== true;
  });
  carList.forEach((element) => {
    carIds = [...carIds, element?.car?.id];
  });

  let find = carIds?.find((element) => element === dataObj?.usedCar?.car?.id);
  if (!find) {
    if (carList?.length !== 0) setDataObj(carList[0], "usedCar");
    else forceSetDataObj({}, "usedCar");
  }

  setDataObj(carList, "carArr");
  setDataObj(carListWithSold, "carArrWithSold");
  setDataObj(carIds, "carIds");
  consoleBeauty("Обновлен список машин", "GraphQL", "darkgreen");
  setAlert("Изменения внесены", "success");
}

import React, { useEffect, useState } from "react";

import { DriveEta } from "@mui/icons-material/";
import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { CarAddBlock } from "components/CarAddBlock";
import { GetData } from "contexts/DataContext";
import { findInArray, getCarName, isObjectEmpty } from "data/functions";
import { CarLeftSVG } from "data/styleGlobals";
import { useNavigate } from "react-router-dom";

export default function CarListItem(props) {
  const { dataObj, setDataObj } = GetData();
  const navigate = useNavigate();

  function clickOnCar() {
    if (props?.navigate)
      navigate(`/cars/${props?.el?.car?.id ?? "unknowncar"}`, {
        state: { carID: props?.el?.car?.id },
      });

    let inArray = findInArray(dataObj?.carArr, props?.el);
    if (!isObjectEmpty(props?.el) && inArray) {
      setDataObj(props?.el, "usedCar");
    }
  }

  return (
    <React.Fragment>
      {!isObjectEmpty(props?.el) && (
        <CarContainer
          key={props?.index}
          onClick={() => {
            clickOnCar();
          }}
          sx={{
            "&:hover": {
              backgroundColor: "rgb(0 0 0 / 5%)",
              cursor: "pointer",
            },
          }}
        >
          <CarMarkAvatar {...props} />
          <CarBody {...props} />
          <CarModelImage {...props} />
          <Divider />
        </CarContainer>
      )}
      {isObjectEmpty(dataObj?.carArr) && isObjectEmpty(props?.el) && (
        <Box sx={{ position: "relative" }}>
          <CarAddBlock />
        </Box>
      )}
    </React.Fragment>
  );
}

function CarMarkAvatar(props) {
  const [url, setUrl] = useState(props?.el?.car?.marka?.imageUrl || "");

  useEffect(() => {
    setUrl(props?.el?.car?.marka?.imageUrl);
  }, [props?.el?.car?.marka?.imageUrl]);

  return (
    <ListItemAvatar
      id={"avatar_" + (props?.index || "none")}
      sx={{
        width: "40px",
        minWidth: "40px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {url ? (
        <img
          alt={props?.el?.car?.vin}
          id={"markimage_" + (props?.index || "none")}
          onError={(e) => {
            // e.target.hidden = true;
          }}
          src={url}
          style={{ overflow: "hidden", width: "40px" }}
        />
      ) : (
        <Avatar
          id={"markimage_" + (props?.index || "none")}
          sx={{
            background: "transparent",
            boxShadow: "inset 0px 0px 2px #00000026",
            width: "40px",
          }}
        >
          <DriveEta color="dimblue" />
        </Avatar>
      )}
    </ListItemAvatar>
  );
}

function CarBody(props) {
  const { dataObj } = GetData();
  return (
    <ListItemText sx={{ padding: "0 0.4rem" }}>
      <Typography variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}>
        {getCarName(props?.el)}
        {props?.el?.sold ? " (Продан)" : ""}
      </Typography>
      {props?.el?.regNum && (
        <PlateContainer>
          <PlateNumber>
            <Typography
              variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
            >
              {props?.el?.regNum.slice(0, 6)}
            </Typography>
          </PlateNumber>
          <PlateRegion>
            <Typography
              variant={dataObj?.deviceWidth?.isMobile ? "caption" : "body2"}
            >
              {props?.el?.regNum.slice(
                6,
                props?.el?.regNum.length > 9 ? 9 : props?.el?.regNum.length,
              )}
            </Typography>
          </PlateRegion>
        </PlateContainer>
      )}
    </ListItemText>
  );
}

function CarModelImage(props) {
  const [url, setUrl] = useState(props?.el?.car?.model?.imageUrl || "");

  useEffect(() => {
    setUrl(props?.el?.car?.model?.imageUrl);
  }, [props?.el?.car?.model?.imageUrl]);

  return (
    <ListItemAvatar
      id={"avatar_" + (props?.index || "none")}
      sx={{
        width: "110px",
        display: "flex",
        justifyContent: "center",
        background: "white",
      }}
    >
      {url ? (
        <img
          alt={props?.el?.car?.vin}
          id={"modelimage_" + (props?.index || "none")}
          onError={(e) => {
            // e.target.hidden = true;
          }}
          src={url}
          style={{ overflow: "hidden" }}
        />
      ) : (
        <CarLeftSVG
          id={"modelimage_" + (props?.index || "none")}
          style={{ width: "80px", height: "50px", opacity: "1" }}
        />
      )}
    </ListItemAvatar>
  );
}

const CarContainer = styled(ListItem)(() => ({
  boxShadow:
    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  margin: "2px 0px",
  paddingRight: "24px",
}));

const PlateContainer = styled(Box)(() => ({
  border: "1px solid #b8b8b8",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "row",
  flexWrap: "no-wrap",
  minHeight: "20px",
  width: "90px",
}));

const PlateNumber = styled(Box)(() => ({
  alignItems: "center",
  borderRight: "1px solid #b8b8b8",
  display: "flex",
  justifyContent: "center",
  width: "60px",
}));

const PlateRegion = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  width: "30px",
}));

import React, { useState } from "react";

import { Box, List, Typography } from "@mui/material";
import { NotFoundBlock } from "components/NotFoundBlock";
import { PageTitle } from "components/PageTitle";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { isIdentical } from "data/functions";
import { isObjectEmpty } from "data/functions";
import { Outlet, useNavigate } from "react-router-dom";

export default function PromoMain(props) {
  const { dataObj } = GetData();

  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ PromoListMain: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Акции"} />
        {dataObj?.includeBeta ? (
          <Box sx={{ overflow: "auto" }}>
            <PromoList />
            <Outlet />
          </Box>
        ) : (
          <NotFoundBlock />
        )}
      </Box>
    </React.Fragment>
  );
}

export function PromoList(props) {
  const { dataObj } = GetData();

  const [promoArr, setPromoArr] = useState(dataObj?.promotions || []);
  React.useEffect(() => {
    if (!isIdentical(promoArr, dataObj?.promotions))
      setPromoArr(dataObj?.promotions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.promotions]);

  return (
    <List
      sx={{
        padding: "0 0.4rem",
        display: dataObj?.deviceWidth?.isMobile ? "block" : "flex",
        flexWrap: dataObj?.deviceWidth?.isMobile ? undefined : "wrap",
        gap: dataObj?.deviceWidth?.isMobile ? undefined : "10px",
        justifyContent: dataObj?.deviceWidth?.isMobile ? undefined : "center",
      }}
    >
      {!isObjectEmpty(promoArr) &&
        promoArr.map((el, index) => {
          if (props?.fromMain ? el?.showOnMain : true) {
            return <PromoBox el={el} ind={index} key={index} />;
          } else return "";
        })}
      {isObjectEmpty(promoArr) && <NotFoundBlock />}
    </List>
  );
}

export function PromoBox(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  const boxStyle = {
    alignItems: "stretch",
    backgroundImage: !isObjectEmpty(props?.el?.preview)
      ? `url(${props?.el?.preview})`
      : !isObjectEmpty(props?.el?.main)
        ? `url(${props?.el?.main})`
        : "",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "4px",
    boxSizing: "border-box",
    color: "black",
    containerType: "inline-size",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "flex-start",
    margin: dataObj?.deviceWidth?.isMobile ? "0.4rem 0" : undefined,
    maxWidth: dataObj?.deviceWidth?.isMobile ? "undefined" : "450px",
    minHeight: dataObj?.deviceWidth?.isMobile ? "130px" : "160px",
    minWidth: 0,
    overflow: "hidden",
    padding: "12px",
    position: "relative",
    width: "100%",
  };
  return (
    <Box
      key={props?.ind}
      onClick={(e) =>
        navigate(`/promotions/${props?.el?.id}`, {
          state: { id: props?.el?.id },
        })
      }
      sx={boxStyle}
    >
      <Box
        sx={{ maxWidth: "60%", position: "absolute", top: "10%", left: "5%" }}
      >
        <Typography
          sx={{
            whiteSpace: "break-spaces",

            fontWeight: "700",
            lineHeight: "1.2",
          }}
          variant="body1"
        >
          {props?.el?.title}
        </Typography>
        <Typography
          sx={{
            whiteSpace: "break-spaces",
            lineHeight: "1",
            maxWidth: "60%",
          }}
          variant="caption"
        >
          {props?.el?.text}
        </Typography>
      </Box>
    </Box>
  );
}

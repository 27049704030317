import { useMutation } from "@apollo/client";
import { AUTH_EXT_LOGIN } from "components/gql/gql_queries_auth";
import { GetAuthData } from "contexts/TAuthContext";
import {
  consoleBeauty,
  createTokenData,
  logGQLError,
  setLocalStorageItem,
} from "data/functions";
export function ExtLoginMutation() {
  const queryName = "ExtLogin";
  const { setTokenData } = GetAuthData();
  const [mutation, variables] = useMutation(AUTH_EXT_LOGIN, {
    onCompleted: (data) => {
      if (
        data?.auth_extLogIn.code === 200 &&
        data?.auth_extLogIn?.data?.accessToken
      ) {
        consoleBeauty("Успешная авторизация", "GraphQL", "darkgreen");
        setLocalStorageItem("tokenDataOriginal", data?.auth_extLogIn?.data);
        let obj = createTokenData(
          data?.auth_extLogIn?.data?.accessToken,
          data?.auth_extLogIn?.data?.expiresIn,
          data?.auth_extLogIn?.data?.refreshToken,
          data?.auth_extLogIn?.data?.refreshExpiresIn,
        );

        setTokenData(obj);
      }
      // else {
      //   logGQLError(queryName, data?.auth_extLogIn);
      // }
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}

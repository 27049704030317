import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import styled from "@emotion/styled";
import {
  AttachFile,
  SentimentSatisfiedAlt,
  SentimentSatisfiedOutlined,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  IconButton,
  Paper,
  Popover,
  TextField as MuiTextField,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";

import React, { useEffect, useState } from "react";

import { Smile } from "react-feather";

const TextField = styled(MuiTextField)(spacing);

export function ChatEmoji(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const openEmoji = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeEmoji = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const emojiOpen = Boolean(anchorEl);
  return (
    <React.Fragment>
      <IconButton onClick={openEmoji}>
        <SentimentSatisfiedOutlined
          color={emojiOpen ? "primary" : "disabled"}
          sx={{
            width: "1.2em",
            height: "1.2em",
          }}
        />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: -16,
          horizontal: "center",
        }}
        onClose={closeEmoji}
        open={emojiOpen}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: "260px" }}>
          <Picker
            categories={["frequent", "people"]}
            data={data}
            dynamicWidth={false}
            emojiButtonSize={26}
            emojiSize={18}
            emojiVersion={3}
            exceptEmojis={exceptEmojies}
            locale="ru"
            maxFrequentRows={2}
            navPosition="none"
            noCountryFlags={true}
            // onEmojiSelect={(e) => console.log(e)}
            onEmojiSelect={(emoji) => {
              props?.setValue((e) => e + emoji?.native);
              // console.log(emoji);
            }}
            previewPosition={"none"}
            searchPosition={"none"}
            set={"native"}
            // set={"apple"}
            // skin={1}
            skinTonePosition={"none"}
            theme={theme?.palette?.mode}
            width="262"
          />
        </Box>
      </Popover>
    </React.Fragment>
  );
}

const exceptEmojies = [
  "relaxed",
  "smiling_face_with_3_hearts",
  "star-struck",
  "melting_face",
  "smiling_face_with_tear",
  "zany_face",
  "face_with_hand_over_mouth",
  "face_with_open_eyes_and_hand_over_mouth",
  "face_with_peeking_eye",
  "shushing_face",
  "saluting_face",
  "face_with_raised_eyebrow",
  "dotted_line_face",
  "shaking_face",
  "face_vomiting",
  "hot_face",
  "cold_face",
  "woozy_face",
  "exploding_head",
  "face_with_monocle",
  "partying_face",
  "disguised_face",
  "face_with_diagonal_mouth",
  "pleading_face",
  "face_holding_back_tears",
  "yawning_face",
  "face_with_symbols_on_mouth",
  "rightwards_hand",
  "leftwards_hand",
  "palm_down_hand",
  "palm_up_hand",
  "leftwards_pushing_hand",
  "rightwards_pushing_hand",
  "pinched_fingers",
  "pinching_hand",
  "hand_with_index_finger_and_thumb_crossed",
  "i_love_you_hand_sign",
  "index_pointing_at_the_viewer",
  "heart_hands",
  "palms_up_together",
  "mechanical_arm",
  "mechanical_leg",
  "leg",
  "foot",
  "ear_with_hearing_aid",
  "brain",
  "anatomical_heart",
  "lungs",
];

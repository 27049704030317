import { useQuery } from "@apollo/client";

import { EXPORT_REJECT_REASONS } from "components/gql/gql_queries_export";
import { logGQLError } from "data/functions";

export function RejectReasonsQuery() {
  const queryName = "RejectReasons";
  const query = useQuery(EXPORT_REJECT_REASONS, {
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });

  return query;
}

import { useLazyQuery } from "@apollo/client";
import { GRZ_DECODE } from "components/gql/gql_queries";
import { logGQLError } from "data/functions";

export function GetGrzDecode() {
  const queryName = "GrzDecode";
  const [refetch, variables] = useLazyQuery(GRZ_DECODE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [refetch, variables];
}

import { Event } from "@mui/icons-material";
import { FormControl, InputAdornment } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { GetData } from "contexts/DataContext";
import { roundMinutes } from "data/functions";
import React from "react";

export default function DateField(props) {
  const { dataObj } = GetData();
  return (
    <FormControl fullWidth variant="standard">
      <MobileDateTimePicker
        disablePast
        // onError={(newError) => props.setError(true)}
        label="Желаемая дата записи"
        minTime={new Date(Date.now() + 1000 * 60 * 60 * 1)}
        minutesStep={15}
        onChange={(e) => props.setDate(e)}
        slotProps={{
          textField: {
            variant: "standard",
            sx: { marginTop: 2, marginBottom: 2 },
            error: props.error,
            helperText: props.error ? "Выберите другое время" : "",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <Event />
                </InputAdornment>
              ),
              style: {
                fontSize: dataObj?.deviceWidth?.isMobile ? "0.875rem" : "1rem",
              },
            },
          },
          popper: {
            placement: "top",
          },
        }}
        value={
          props.date || roundMinutes(new Date(Date.now() + 1000 * 60 * 60 * 1))
        }
      />
    </FormControl>
  );
}

import React from "react";

import { Whatshot } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { GetData } from "contexts/DataContext";
import {
  dateToString,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
} from "data/functions";

export default function AccrueInfo(props) {
  const [accrueArr, setAccrueArr] = React.useState([]);
  const [expireSoon, setExpireSoon] = React.useState("");
  const { dataObj } = GetData();
  React.useEffect(() => {
    if (!isIdentical(accrueArr, dataObj?.creditAccrue)) {
      setAccrueArr(dataObj?.creditAccrue);
      props?.setParentArray(dataObj?.creditAccrue);
    }
    let credits = 0;
    let date = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30);
    (dataObj?.creditAccrue ?? []).forEach((element) => {
      let dateExp = new Date(element?.lifePeriod?.expireDate);
      if (dateExp < date) {
        credits += element?.activeCredit || 0;
      }
    });
    setExpireSoon(credits);
    // eslint-disable-next-line
  }, [dataObj?.creditAccrue]);

  return (
    !isObjectEmpty(expireSoon) && (
      <Paper sx={{ padding: "2px 16px" }}>
        <Accordion
          disableGutters
          sx={{
            backgroundColor: "transparent",
            backgroundImage: "none",

            border: "none",
            boxShadow: "none",
            margin: "2px",
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            sx={{
              minHeight: "36px",

              "& .MuiAccordionSummary-content": {
                margin: "2px",
                alignItems: "center",
              },
            }}
          >
            <Whatshot color="error" sx={{ marginRight: "0.4em" }} />
            <Box>
              <Typography>Скоро сгорят</Typography>
              <Box>{expireSoon} бонусов</Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0 1rem" }}>
            <AccrueArray accrueArr={accrueArr} />
          </AccordionDetails>
        </Accordion>
      </Paper>
    )
  );
}

function AccrueArray(props) {
  const { dataObj } = GetData();
  return (
    <React.Fragment>
      {!isObjectEmpty(props?.accrueArr) &&
        (props?.accrueArr ?? []).map((element, index) => (
          <Box key={index} sx={{ padding: "2px 16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
              >
                {element?.activeCredit} бонусов
              </Typography>
              <Typography
                align="right"
                variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
              >
                {parseRFC3339(element?.lifePeriod?.expireDate) <
                new Date("3990-12-31T21:00:00Z")
                  ? "до " +
                    dateToString(parseRFC3339(element?.lifePeriod?.expireDate))
                  : "бессрочно"}
              </Typography>
            </Box>
            <Divider />
          </Box>
        ))}
    </React.Fragment>
  );
}

import { GetAuthData } from "contexts/TAuthContext";
import React from "react";
export const DialogContext = React.createContext();

function DialogProvider({ children }) {
  const { isLogined } = GetAuthData();
  const [dialogBody, setDialogBody] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const setDialog = React.useCallback((open, component) => {
    // if (isLogined) {
    setDialogOpen(() => open);
    setDialogBody(() => component);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = React.useMemo(
    () => ({
      setDialog,
    }),
    // eslint-disable-next-line
    [setDialog],
  );

  return (
    <DialogContext.Provider value={contextValue}>
      {isLogined &&
        dialogBody &&
        React.cloneElement(dialogBody, {
          open: dialogOpen,
          setOpen: setDialogOpen,
        })}

      {children}
    </DialogContext.Provider>
  );
}
export const GetDialog = () => {
  const context = React.useContext(DialogContext);
  return context;
};

export { DialogProvider };

import React, { useEffect } from "react";

import {
  CameraAlt,
  CropFreeOutlined,
  Photo,
  QrCode,
  QrCodeScanner,
  StarOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";
import { NotFoundBlock } from "components/NotFoundBlock";
import { PageTitle } from "components/PageTitle";
import QRBlock, { QRDialog } from "components/QRBlock";
import { ExtSessionPayload } from "components/gql/mutations/ExtSessionPayload";
import { SelfDataQuery } from "components/gql/queris/GetSelfData";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import {
  createTokenData,
  dateToString,
  getFirstInArray,
  isIdentical,
  parseRFC3339,
  saveMap,
  saveParseJson,
} from "data/functions";
import { isObjectEmpty } from "data/functions";
import Markdown from "markdown-to-jsx";
import AccrueInfo from "pages/credits/AccrueInfo";
import HistoryInfo from "pages/credits/HistoryInfo";
import { markdownOptions } from "pages/promotions/info/Main";
import QRCode from "react-qr-code";
import { LoyaltyAccordion } from "./LoyaltyAccordion";

export default function LoyaltyMain() {
  const { dataObj } = GetData();

  const { cInfo } = GetCInfo();
  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ LoyaltyMain: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [accrueArr, setAccrueArr] = React.useState([]);
  const [historyArr, setHistoryArr] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Бонусы" />
        {cInfo?.loyalty?.used ? (
          <Box sx={{ overflow: "auto" }}>
            <BonusTotal />
            <AccrueInfo setParentArray={setAccrueArr} />
            <BonusQrBlock setOpen={setOpen} />
            {/* <BonusProgram /> */}
            <HistoryInfo setParentArray={setHistoryArr} />
            <br />
            <SendBonuses credits={dataObj?.creditBalance} />
            <GetBonuses />

            {!isObjectEmpty(dataObj?.presentAccrue) && <BonusServs />}
            {isObjectEmpty(historyArr) &&
              isObjectEmpty(dataObj?.presentAccrue) &&
              isObjectEmpty(accrueArr) && <NotFoundBlock />}
          </Box>
        ) : (
          <NotFoundBlock />
        )}
      </Box>
      <QRDialog open={open} setOpen={setOpen} />
    </React.Fragment>
  );
}

function BonusTotal() {
  const { cInfo } = GetCInfo();
  const { dataObj } = GetData();
  const BonusTotalBlock = () => (
    <Box
      sx={{
        // padding: "8px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h5">Мои БОНУСЫ:</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ lineHeight: "0.8" }} variant="h5">
          {dataObj?.creditBalance || 0}
        </Typography>
        <Typography variant="caption">бонусов</Typography>
      </Box>
    </Box>
  );
  return (
    <LoyaltyAccordion
      defaultExpanded={false}
      dontHide={true}
      empty={isObjectEmpty(cInfo?.loyalty?.description)}
      title={<BonusTotalBlock />}
      value={
        <Box sx={{ padding: "1em" }}>
          <Markdown options={markdownOptions}>
            {saveParseJson(cInfo?.loyalty?.description)}
          </Markdown>
        </Box>
      }
    />
  );
}

function BonusServs(props) {
  const { dataObj } = GetData();
  const PresentArray = () => (
    <React.Fragment>
      {(dataObj?.presentAccrue ?? []).map((el, ind) => (
        <Box
          key={ind}
          sx={{
            padding: "4px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            {el.name}
          </Typography>
          <Typography
            variant={dataObj?.deviceWidth?.isMobile ? "body2" : "body1"}
          >
            {el?.lifePeriod?.expireDate !== "0001-01-01 00:00:00 +0000 UTC"
              ? dateToString(parseRFC3339(el?.lifePeriod?.expireDate), true)
              : ""}
          </Typography>
        </Box>
      ))}
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <LoyaltyAccordion
        defaultExpanded={true}
        empty={isObjectEmpty(dataObj?.presentAccrue)}
        title={"Бонусные услуги"}
        value={<PresentArray />}
      />
    </React.Fragment>
  );
}

function BonusQrBlock(props) {
  return (
    <Box
      onClick={(e) => props?.setOpen(true)}
      sx={{
        maxHeight: "80px",
        margin: "0.4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        position: "relative",
        padding: "8px",
        height: "80px",
        overflow: "hidden",
        cursor: "pointer",
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
      }}
    >
      <Typography sx={{ textAlign: "left", fontWeight: "400" }} variant="body2">
        Покажите QR-код, чтобы подтвердить списание бонусов или получить заказ
      </Typography>
      <Box
        sx={{
          display: "flex",
          minWidth: "64px",
          width: "64px",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <QRBlock />
      </Box>
    </Box>
  );
}

function BonusProgram() {
  return (
    <Box
      sx={{
        padding: "36px 16px 0px 16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">Статус: Стандартный</Typography>
    </Box>
  );
}

function GetBonuses(props) {
  const { setAlert } = GetAlertData();
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();
  const [res, setRes] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [selfDataQuery] = SelfDataQuery();

  async function getPayload(result) {
    let decodeRaw = getFirstInArray(result);

    if (!isObjectEmpty(decodeRaw) && decodeRaw?.rawValue !== "") {
      let token = decodeRaw?.rawValue;
      let { data, error } = await selfDataQuery({
        variables: {
          token: token,
        },
      });

      if (error) {
        if (error.networkError) {
          return;
        } else {
          setAlert("Не удалось получить данные", "warning");
        }
      }
      if (data && data?.auth_getSelfData?.payload) {
        let val = "";
        if (!isIdentical(data?.auth_getSelfData?.companyUUID, cInfo?.UUID)) {
          val = "Вы не можете получить бонусы из другой компании!";
        }
        let s = data?.auth_getSelfData?.payload.split(",");

        if (s.length > 1) {
          val =
            s[0] === "sendCredits"
              ? val +
                " Получение бонусов - " +
                s[1] +
                " шт." +
                " Функционал реализован не полностью, передача отменена."
              : val + " Неизвестная операция ";
          setRes(val);
        }
        return;
      }

      return undefined;
    }
  }
  const DialogGet = () => {
    const [paused, setPaused] = React.useState(true);
    return (
      open && (
        <Dialog
          onClose={() => {
            setOpen(false);
            setRes("");
          }}
          open={open}
        >
          <DialogTitle>
            Отсканируйте QR-код друга для получения бонусов
          </DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                padding: "6px",
              }}
            >
              {paused ? (
                <Box
                  onClick={() => setPaused(false)}
                  sx={{
                    width: "250px",
                    height: "250px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    border: "1px dashed rgba(143, 143, 143, 0.245)",
                  }}
                >
                  <CameraAlt />
                </Box>
              ) : (
                <Scanner
                  components={
                    {
                      // finder: false,
                    }
                  }
                  onError={(e) => {
                    setRes(e?.message);
                    setPaused(true);
                  }}
                  onScan={(e) => {
                    getPayload(e);
                    setPaused(true);
                  }}
                  paused={paused}
                  styles={{
                    container: {
                      // overflow: "hidden",
                      maxWidth: "250px",
                      height: "250px",
                    },
                    video: {
                      maxWidth: "250px",
                      height: "250px",
                      overflow: "hidden",
                    },
                    // svg: {
                    //   width: "20px",
                    // },
                  }}
                />
              )}
            </Box>
            <Box sx={{ width: "100%", overflow: "auto" }}>
              <Typography
                // color={el?.type ?? undefined}
                sx={{ maxWidth: "100%", textAlign: "center" }}
                variant="body2"
              >
                {res}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => {
                setOpen(false);
                setRes("");
              }}
              variant="contained"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  };

  return (
    <React.Fragment>
      {dataObj?.includeBeta && (
        <Box sx={{ padding: "0.4rem" }}>
          <Button
            fullWidth
            onClick={() => {
              setOpen(true);
              // setAlert("Функционал не реализован.", "info");
            }}
            variant="outlined"
          >
            Получить бонусы
          </Button>
          <DialogGet />
        </Box>
      )}
    </React.Fragment>
  );
}

function SendBonuses(props) {
  const { setAlert } = GetAlertData();
  const [open, setOpen] = React.useState(false);
  const { dataObj, forceSetDataObj, setDataObj } = GetData();
  const [credits, setCredits] = React.useState(
    (dataObj?.lastPayloadNumber <= props?.credits &&
      dataObj?.lastPayloadNumber) ||
      0,
  );

  React.useEffect(() => {
    if (!isObjectEmpty(dataObj?.lastPayloadTokenData)) {
      const interval = setInterval(() => {
        if (
          !isObjectEmpty(dataObj?.lastPayloadTokenData) &&
          new Date(dataObj?.lastPayloadTokenData?.accessTokenExpires) <
            new Date(Date.now())
        ) {
          forceSetDataObj(0, "lastPayloadNumber");
          forceSetDataObj({}, "lastPayloadTokenData");
        }
      }, 30000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.lastPayloadTokenData]);

  const [payloadToken, setPayloadToken] = React.useState(() => {
    if (
      !isObjectEmpty(dataObj?.lastPayloadTokenData) &&
      new Date(dataObj?.lastPayloadTokenData?.accessTokenExpires) >
        new Date(Date.now())
    ) {
      return dataObj?.lastPayloadTokenData?.accessToken;
    } else return "";
  });

  useEffect(() => {
    if (
      !isObjectEmpty(dataObj?.lastPayloadTokenData) &&
      dataObj?.lastPayloadTokenData?.accessTokenExpires > new Date(Date.now())
    ) {
      setPayloadToken(dataObj?.lastPayloadTokenData?.accessToken);
    } else setPayloadToken("");
  }, [dataObj?.lastPayloadTokenData]);

  const [sessionPayloadOuery] = ExtSessionPayload();

  async function createSessionPayload(c) {
    if (!c || c === 0) return;
    let { data, error } = await sessionPayloadOuery({
      variables: {
        payload: "sendCredits," + c + ",LIMITED",
      },
    });
    if (
      data?.auth_extSessionPayload.code === 200 &&
      data?.auth_extSessionPayload?.data?.accessToken
    ) {
      let obj = createTokenData(
        data?.auth_extSessionPayload?.data?.accessToken,
        data?.auth_extSessionPayload?.data?.expiresIn,
        "",
        "",
      );
      setDataObj(c, "lastPayloadNumber");
      setDataObj(obj, "lastPayloadTokenData");
      setPayloadToken(data?.auth_extSessionPayload?.data?.accessToken);
    }
    if (error) {
      setAlert("Не удалось создать!", "error");
    }
  }

  return (
    <React.Fragment>
      {dataObj?.includeBeta && (
        <React.Fragment>
          <Box sx={{ padding: "0.4rem" }}>
            <Button
              fullWidth
              onClick={() => {
                setOpen(true);
                // setAlert("Функционал не реализован.", "info");
              }}
              variant="outlined"
            >
              Передать бонусы
            </Button>
            {/* <DialogSend {...props} /> */}
          </Box>
          <Dialog
            onClose={() => {
              setOpen(false);
            }}
            open={open}
          >
            <DialogTitle>Передать бонусы</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "6px",
                }}
              >
                <TextField
                  InputProps={{
                    // disableUnderline: true,
                    inputProps: {
                      style: {
                        // padding: "0",
                      },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <StarOutline />
                      </InputAdornment>
                    ),
                  }}
                  // autoFocus
                  fullWidth
                  label="Количество бонусов"
                  // value={text}
                  mb={2}
                  mt={2}
                  name="credits"
                  // onChange={(e) => setCredits(e?.target?.value)}
                  onChange={(e) => {
                    if ((props?.credits || 0) >= Number(e.target.value)) {
                      setCredits(Number(e.target.value));
                    } else {
                      setCredits(props?.credits);
                    }
                  }}
                  size="small"
                  sx={
                    {
                      // margin: "0",
                    }
                  }
                  type="Number"
                  value={credits}
                  variant={"standard"}
                />
              </Box>
              {payloadToken && (
                <QRCode
                  style={{
                    maxWidth: "256px",
                    width: "100%",
                    height: "auto",
                    background: "white",
                    padding: "0.4rem",
                  }}
                  value={payloadToken}
                />
              )}
            </DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {!payloadToken && (
                <Button
                  fullWidth
                  onClick={() => {
                    // setOpen(false);
                    createSessionPayload(credits);
                    // setAlert("Функционал в разработке", "info");
                  }}
                  sx={{ marginBottom: "4px" }}
                  variant="contained"
                >
                  Создать QR-код
                </Button>
              )}
              <Button
                fullWidth
                onClick={() => {
                  setOpen(false);
                  forceSetDataObj(0, "lastPayloadNumber");
                  forceSetDataObj({}, "lastPayloadTokenData");
                }}
                variant="contained"
              >
                Закрыть
              </Button>
            </Box>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

/* eslint-disable no-undef */
export function showAuthScreen() {
  // console.log("showAuthScreen");
  try {
    Android.showAuthScreen();
  } catch (err) {}
  var message = {};

  try {
    window.webkit.messageHandlers.showAuthScreen.postMessage(message);
    // console.log("post message to ios showAuthScreen");
  } catch (err) {
    // console.log("exception=" + err);
  }
}

export function saveAuthData(login) {
  try {
    Android.saveAuthData(login);
  } catch (err) {}

  var message = {
    login: login,
  };
  try {
    window.webkit.messageHandlers.saveAuthData.postMessage(message);
  } catch (err) {}
}

export function showMainScreen(login) {
  // console.log("showMainScreen");
  try {
    Android.showMainScreen(login);
  } catch (err) {}
  var message = { login: login };
  try {
    window.webkit.messageHandlers.showMainScreen.postMessage(message);
  } catch (err) {}
}

export function showRoute(lat, lng) {
  try {
    Android.showRoute(lat.toString(), lng.toString());
  } catch (err) {}

  var message = {
    lat: lat.toString(),
    lng: lng.toString(),
  };
  try {
    webkit.messageHandlers.showRoute.postMessage(message);
  } catch (err) {}
}

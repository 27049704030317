import { useMutation } from "@apollo/client";
import { DECODE_VIN } from "components/gql/gql_queries";
import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, isObjectEmpty, logGQLError } from "data/functions";

export function DecodeVinMutation() {
  const queryName = "DecodeVin";
  const { setAlert } = GetAlertData();
  const [mutation, variables] = useMutation(DECODE_VIN, {
    onCompleted: (data) => {
      if (data?.exch_decodeVin?.code === 200 && data?.exch_decodeVin?.data) {
        consoleBeauty("Успешная расшифровка VIN", "GraphQL", "darkgreen");
      } else if (
        data?.exch_decodeVin?.code === 200 &&
        isObjectEmpty(data?.exch_decodeVin?.data)
      ) {
        setAlert("Данные по VIN не найдены", "warning");
      } else {
        logGQLError(queryName, data?.exch_decodeVin);
      }
    },
    onError: (e) => {
      logGQLError(queryName, e);
    },
  });
  return [mutation, variables];
}

import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { GetData } from "contexts/DataContext";
import {
  dateToString,
  isIdentical,
  isObjectEmpty,
  parseRFC3339,
} from "data/functions";
import React, { useEffect, useState } from "react";

export default function HistoryInfo(props) {
  const [historyArr, setHistoryArr] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const { dataObj } = GetData();

  useEffect(() => {
    if (!isObjectEmpty(dataObj?.creditHistory)) {
      // console.log("Изменилась машина");
      let arr = [];
      let dataObjTemp = [...(dataObj?.creditHistory ?? [])].sort(
        (a, b) => a.date - b.date,
      );
      dataObjTemp.forEach((el) => {
        let newDate = dateToString(parseRFC3339(el.date, true));
        arr = [
          ...arr,
          { activeCredits: el.activeCredits, date: newDate ? newDate : "" },
        ];
      });

      if (!isIdentical(historyArr, arr)) {
        props?.setParentArray(arr);
        setHistoryArr(arr);
      }
    } else {
      setHistoryArr([]);
    }
    // eslint-disable-next-line
  }, [dataObj?.creditHistory]);

  return (
    <React.Fragment>
      {!isObjectEmpty(historyArr) && (
        <Paper sx={{ padding: "2px 16px" }}>
          <Accordion
            disableGutters
            sx={{
              backgroundColor: "transparent",
              backgroundImage: "none",

              border: "none",
              boxShadow: "none",
              margin: "2px",
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              sx={{
                minHeight: "36px",

                "& .MuiAccordionSummary-content": {
                  margin: "6px",
                  alignItems: "center",
                },
              }}
            >
              {/* <Whatshot color="error" sx={{ marginRight: "0.4em" }} /> */}
              <Box>
                <Typography variant="h6">История бонусов</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0 1rem" }}>
              {historyArr.map((element, index) => (
                <Box key={index} sx={{ padding: "2px 16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant={
                        dataObj?.deviceWidth?.isMobile ? "body2" : "body1"
                      }
                    >
                      {element?.date}
                    </Typography>

                    <Typography
                      align="right"
                      variant={
                        dataObj?.deviceWidth?.isMobile ? "body2" : "body1"
                      }
                    >
                      {element?.activeCredits > 0
                        ? "+" + element?.activeCredits
                        : element?.activeCredits}{" "}
                      Б
                    </Typography>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </React.Fragment>
  );
}

import React, { useState } from "react";

import { Box, Button, Container, Link, Typography } from "@mui/material";
import { CompanyErrorPage } from "components/CompanyError";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { saveAuthData, showAuthScreen } from "data/mobapp_functions";
import { AuthMethodButtons } from "pages/auth/AuthMethodButtons";
import { CodeBlock } from "pages/auth/CodeBlock";
import { TelegramBlock } from "pages/auth/TelegramBlock";
import { TokenDialog } from "pages/auth/TokenDialog";
import { useNavigate } from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme?.

export default function AuthMain() {
  const { dataObj } = GetData();
  const { isLogined } = GetAuthData();
  const [noChannels, setNoChannels] = useState(false);
  const [method, setMethod] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLogined === true) {
      saveAuthData(dataObj?.clientID);
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isLogined, dataObj?.clientID]);

  React.useEffect(() => {
    showAuthScreen();
  }, []);

  return (
    <Box>
      {noChannels ? (
        <CompanyErrorPage
          text={
            <>
              Для компании не заданы <br /> методы авторизации
            </>
          }
        />
      ) : (
        <React.Fragment>
          <TokenDialog />
          <AuthBodyContainer method={method}>
            <AuthMethodButtons
              method={method}
              setMethod={setMethod}
              setNoChannels={setNoChannels}
            />
            {(method === "sms" || method === "phone") && (
              <CodeBlock method={method} setMethod={setMethod} />
            )}
            {method === "telegram" && (
              <TelegramBlock method={method} setMethod={setMethod} />
            )}
            {method && <AuthButtonBack method={method} setMethod={setMethod} />}
          </AuthBodyContainer>
        </React.Fragment>
      )}
    </Box>
  );
}

function AuthBodyContainer(props) {
  return (
    <React.Fragment>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          // minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          padding: "1rem",
          background: "transparent",
        }}
      >
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <Typography
            component="h2"
            sx={{ paddingBottom: "1rem", textAlign: "center" }}
            variant="h4"
          >
            {props?.method
              ? props?.method === "sms"
                ? "Вход по SMS"
                : props?.method === "phone"
                  ? "Вход по звонку"
                  : "Вход через Telegram"
              : "Вход"}
          </Typography>

          <Box noValidate sx={{ mt: 1 }}>
            {props?.children}
          </Box>
        </Box>
      </Container>
      <Copyright />
    </React.Fragment>
  );
}

function AuthButtonBack(props) {
  return (
    <React.Fragment>
      {props?.method && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <Button
            onClick={() => {
              props?.setMethod("");
              // props?.setActive(false);
            }}
            variant="body2"
          >
            Назад
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}

function Copyright(props) {
  return (
    <Typography
      align="center"
      color="text.secondary"
      variant="body2"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://5systems.ru/">
        5S AUTO
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

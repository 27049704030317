import { CalendarMonth } from "@mui/icons-material";
import { Box, Button, Paper, Table, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import { findInArray, isIdentical, saveParseJson } from "data/functions";
import Markdown from "markdown-to-jsx";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const markdownOptions = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: "h3",
      },
    },
    h2: {
      component: Typography,
      props: {
        variant: "h4",
      },
    },
    h3: {
      component: Typography,
      props: {
        variant: "h5",
      },
    },
    h4: {
      component: Typography,
      props: {
        variant: "h6",
      },
    },
    h5: {
      component: Typography,
      props: {
        variant: "body1",
      },
    },
    h6: {
      component: Typography,
      props: {
        variant: "body2",
      },
    },
    table: {
      component: Table,
      props: {
        size: "small",
        sx: {
          overflow: "auto",
        },
      },
    },
  },
};

export default function PromotionProfile(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const { dataObj } = GetData();

  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ PromotionProfile: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const params = useParams();
  const ID = location.state?.id || params.id;

  const [item, setItem] = useState(
    findInArray(dataObj?.promotions, ID, "id") || {},
  );

  React.useEffect(() => {
    let val = findInArray(dataObj?.promotions, ID, "id");
    if (!isIdentical(item, val)) {
      setItem(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.promotions]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Акция"} />
        <Box sx={{ overflow: "auto" }}>
          <Box
            sx={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              padding: "8px 0",
              backgroundImage: `url(${item?.main ? item?.main : item?.preview})`,
              backgroundSize: "contain",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
            }}
          />

          <Paper
            sx={{
              position: "relative",
              padding: "0.4rem",
              boxShadow: "none",
              borderRadius: " 20px 20px 0 0",
            }}
          >
            {item?.time && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "0.4rem",
                  marginLeft: "0.4rem",
                  alignItems: "center",
                }}
              >
                <CalendarMonth />
                {item?.time}
              </Box>
            )}

            <Typography
              align="center"
              sx={{ padding: "0.2rem", whiteSpace: "break-spaces" }}
              variant="h4"
            >
              {item?.title}
            </Typography>
            <Box
              sx={{
                fontSize: "1rem",
                padding: "12px 12px",
                display: "flex",
                justifyContent: "space-around",
                overflow: "auto",
              }}
            >
              <Markdown options={markdownOptions}>
                {/* {JSON.parse(item?.body)} */}
                {saveParseJson(item.body)}
              </Markdown>
            </Box>
            <Box sx={{ fontSize: "1rem", textAlign: "center" }}>
              <Button onClick={() => navigate("/request")} variant="outlined">
                Записаться
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </React.Fragment>
  );
}

import { Add, Delete } from "@mui/icons-material";
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import { GetBasket } from "contexts/BasketContext";
import { GetData } from "contexts/DataContext";
import { getImageUrlByName, isObjectEmpty } from "data/functions";
import { ServiceBlock } from "pages/services/list/ServiceBlock";
import React, { useState } from "react";

export function Section(props) {
  const [open, setOpen] = useState(false);
  const { getPrice, sectionItemDelete } = GetBasket();
  const { dataObj } = GetData();

  function onCheckBoxClick(value, section) {
    let val = (props?.selected[section] ?? []).find(
      (element) => element === value,
    );
    if (val) {
      let newS = {
        ...props?.selected,
        [section]: props?.selected[section].filter(
          (element) => element !== value,
        ),
      };
      props?.setSelected(newS);
    } else {
      props?.setSelected({
        ...props?.selected,
        [section]: [...(props?.selected[section] ?? []), value],
      });
    }
  }
  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", padding: "0.4rem 0" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 12px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              padding: "0 4px",
              fontWeight: 400,
              textAlign: "start",
            }}
            variant="body1"
          >
            {props?.title}
          </Typography>

          <Add
            aria-hidden={false}
            onClick={() => setOpen(true)}
            sx={{ opacity: 0.5 }}
          />
        </Box>
        <Divider />
        {!isObjectEmpty(props?.array) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
              flexWrap: "wrap",
              padding: "0.4rem 1rem",
              // justifyContent: "space-around",
            }}
          >
            {(!isObjectEmpty(props?.array) ? props?.array : []).map(
              (element, index) => {
                let carID = dataObj?.usedCar?.car?.id || "all";
                const priceObj = getPrice(element?.id, carID) || {};
                let check =
                  !isObjectEmpty(
                    (!isObjectEmpty(props?.selected[props?.section])
                      ? props?.selected[props?.section]
                      : []
                    ).find((el) => el?.id === element?.id),
                  ) || false;
                const name = element?.name || element?.text || "";
                return (
                  <ServiceBlock
                    borderLeft={
                      element?.urgency > 1
                        ? "1px solid rgb(255 0 0)"
                        : undefined
                    }
                    boxShadow={element?.urgency > 1 ? "#ff000017" : undefined}
                    deleteComponent={
                      <Delete
                        onClick={() =>
                          sectionItemDelete("basket", element, props?.section)
                        }
                      />
                    }
                    height=""
                    iconElement={
                      <Checkbox
                        checked={check || false}
                        disableRipple
                        onChange={() => {
                          onCheckBoxClick(element, props?.section);
                        }}
                        edge="start"
                        // inputProps={{ "aria-labelledby": labelId }}
                        size="small"
                        sx={{
                          padding: "0.4rem",
                        }}
                        tabIndex={-1}
                      />
                    }
                    image={getImageUrlByName(name)}
                    item={element}
                    key={index}
                    onBasket={true}
                    price={
                      priceObj?.price ? (
                        priceObj?.price > priceObj?.salePrice ? (
                          <React.Fragment>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <strike>{priceObj?.price}</strike> руб.
                              </div>
                              {priceObj?.salePrice + " руб."}
                            </Box>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {priceObj?.priceType === "min_price"
                              ? "от " + priceObj?.salePrice + " руб."
                              : priceObj?.salePrice + " руб."}
                          </React.Fragment>
                        )
                      ) : (
                        ""
                      )
                    }
                    setItem={() => onCheckBoxClick(element, props?.section)}
                    thereIsEl={true}
                    title={name}
                  />
                );
              },
            )}
          </Box>
        )}
      </Box>
      {props?.dialogItem &&
        React.cloneElement(props?.dialogItem, {
          setOpen: setOpen,
          status: open,
        })}
    </React.Fragment>
  );
}

import { AdminPanelSettings, Done } from "@mui/icons-material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
// import JsonView from "@uiw/react-json-view";
import { FlexRowNoWrap, OrderChip } from "data/styleGlobals";

import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
// import { Console } from "console-feed";
// import { GetLogsContext } from "contexts/LogsContext";
import { GetAuthData } from "contexts/TAuthContext";
import {
  getLocalStorageItem,
  getTgPadding,
  isObjectEmpty,
  saveParseJson,
} from "data/functions";
import { BoxFlexRow } from "data/styleGlobals";
import React from "react";
import { useNavigate } from "react-router-dom";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

export const StorageDialog = (props) => {
  const navigate = useNavigate();
  const { dataObj, setDataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { tokenData } = GetAuthData();
  const basketContext = GetBasket();
  const theme = useTheme();
  const [array, setArray] = React.useState({});
  // const logsData = GetLogsContext();

  React.useEffect(() => {
    let arr = {};
    if (!isObjectEmpty(dataObj)) arr = { ...arr, ...{ dataObj: dataObj } };
    if (!isObjectEmpty(theme)) arr = { ...arr, ...{ theme: theme } };
    if (!isObjectEmpty(cInfo)) arr = { ...arr, ...{ cInfo: cInfo } };
    if (!isObjectEmpty(basketContext?.basket))
      arr = { ...arr, ...{ basketData: basketContext?.basket } };
    if (!isObjectEmpty(basketContext?.prices))
      arr = { ...arr, ...{ pricesData: basketContext?.prices } };
    if (!isObjectEmpty(tokenData))
      arr = { ...arr, ...{ tokenData: tokenData } };

    if (!isObjectEmpty(getLocalStorageItem("tokenDataOriginal")))
      arr = {
        ...arr,
        ...{ tokenDataOriginal: getLocalStorageItem("tokenDataOriginal") },
      };

    if (!isObjectEmpty(localStorage))
      arr = {
        ...arr,
        ...{ localStorage: saveParseJson(JSON.stringify(localStorage)) },
      };
    if (!isObjectEmpty(sessionStorage))
      arr = {
        ...arr,
        ...{ sessionStorage: saveParseJson(JSON.stringify(sessionStorage)) },
      };
    setArray(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj, cInfo, basketContext]);

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        Параметры
        <IconButton onClick={() => navigate("/admin")}>
          <AdminPanelSettings />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0 0.86em", overflowY: "scroll" }}>
        {/* https://yandex.ru/dev/commercial/doc/ru/#index__conditions */}
        <Link href="https://yandex.ru/legal/maps_api/">
          Условия использования Яндекс Карт
        </Link>
        <br />
        <Link href="/privacy">Политика обработки персональных данных</Link>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={dataObj?.includeBeta || false}
              onChange={(e) => setDataObj(e?.target?.checked, "includeBeta")}
            />
          }
          label="Включить экспериментальные функции"
        />
        {/* <TestInput /> */}
        <Accordion
          sx={{
            "&.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            id="panel-hran"
            sx={{
              minHeight: "36px",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            Хранилище
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(array).map((el, index) => {
              if (!isObjectEmpty(el)) {
                return (
                  <Box key={index}>
                    <BoxFlexRow
                      key={index}
                      sx={{
                        margin: "2px !important",
                        padding: "0rem 1rem",
                        flexDirection: "column!important",
                        alignItems: "start!important",
                      }}
                    >
                      <Typography
                        variant={
                          dataObj?.deviceWidth?.isMobile ? "caption" : "body1"
                        }
                      >
                        {el}
                      </Typography>

                      <JsonView
                        collapseStringsAfterLength={20}
                        collapsed={true}
                        dark={theme?.palette?.mode === "dark" ? true : false}
                        enableClipboard={true}
                        src={array[el]}
                        style={{ fontSize: "small" }}
                        theme="atom"
                      />
                    </BoxFlexRow>
                    <Divider />
                  </Box>
                );
              } else {
                return "";
              }
            })}
          </AccordionDetails>
        </Accordion>
        {/* <Patterns /> */}
        {/* <Accordion
          sx={{
            "&.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            id="panel-cons"
            sx={{
              minHeight: "36px",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            Консоль
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                overflow: "auto",
                maxHeight: "400px",
                flexDirection: "column",
              }}
            >
              <Console logs={logsData} variant="dark" />
            </Box>
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          sx={{
            "&.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            id="panel-cons"
            sx={{
              minHeight: "36px",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            Статусы заказов
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                overflow: "auto",
                maxHeight: "400px",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TestChip title="IN_WORK" />
                  <TestChip title="READY" />
                  <TestChip title="CANCELED" />
                  <TestChip title="COMPLETED" />
                  <TestChip title="NONE" />
                  <TestChip title="PAY_WAITING" />
                  <TestChip title="PAID" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "24px",
                    gap: "2px",
                  }}
                >
                  <TestChip color="dimblue" title="dimblue" />
                  <TestChip color="dimgreen" title="dimgreen" />
                  <TestChip color="dimgrey" title="dimgrey" />
                  <TestChip color="dimdarkgreen" title="dimdarkgreen" />
                  <TestChip color="dimdarkred" title="dimdarkred" />
                  <TestChip color="dimred" title="dimred" />
                  <TestChip color="error" title="error" />
                  <TestChip color="info" title="info" />
                  <TestChip color="primary" title="primary" />
                  <TestChip color="secondary" title="secondary" />
                  <TestChip color="success" title="success" />
                  <TestChip color="warning" title="warning" />
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            "&.Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            id="panel-cons"
            sx={{
              minHeight: "36px",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            Проверка отступов
          </AccordionSummary>
          <AccordionDetails>
            <TestPadding text={dataObj?.platform} title="platform" />
            <TestPadding
              text={getComputedStyle(document.body).getPropertyValue(
                "--tg-content-safe-area-inset-top",
              )}
              title="--tg-content-safe-area-inset-top (document.body)"
            />
            <TestPadding
              text={getComputedStyle(window.document.body).getPropertyValue(
                "--tg-content-safe-area-inset-top",
              )}
              title="--tg-content-safe-area-inset-top (window.document.body)"
            />
            <TestPadding
              text={getComputedStyle(document.body).getPropertyValue(
                "--tg-content-safe-area-inset-top",
              )}
              title="--tg-content-safe-area-inset-bottom"
            />
            <TestPadding
              text={getComputedStyle(document.body).getPropertyValue(
                "--tg-safe-area-inset-top",
              )}
              title=" --tg-safe-area-inset-top (document.body)"
            />
            <TestPadding
              text={getComputedStyle(window.document.body).getPropertyValue(
                "--tg-safe-area-inset-top",
              )}
              title=" --tg-safe-area-inset-top (window.document.body)"
            />
            <TestPadding
              text={getComputedStyle(document.body).getPropertyValue(
                "--tg-safe-area-inset-bottom",
              )}
              title="--tg-safe-area-inset-bottom"
            />{" "}
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          fullWidth
          onClick={(e) => {
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function TestPadding({ text, title }) {
  return (
    <Box
      sx={{
        ...FlexRowNoWrap,
        alignItems: "center",
      }}
    >
      <Typography>{title ? title + ":" : ""}</Typography>
      <Typography>{text ? text : ""}</Typography>
    </Box>
  );
}

function TestChip({ color, title }) {
  return (
    <Box
      sx={{
        ...FlexRowNoWrap,
        alignItems: "center",
      }}
    >
      <Typography>{title ? title + ":" : ""}</Typography>
      <OrderChip color={color} title={title} />
    </Box>
  );
}

function TestInput() {
  return (
    <React.Fragment>
      <br />
      <label htmlFor="test1">test 1 (capture attr)</label>
      <input accept="image/*" capture="camera" id="test1" type="file" />
      <br />
      <label htmlFor="test2">test 2 (capture in acc)</label>
      <input accept="image/*;capture=camera" id="test2" type="file" />
      <br />
      <label htmlFor="test3">test 3 (capture bool)</label>
      <input accept="image/*" capture id="test3" type="file" />
      <br />
      <label htmlFor="test4">test 4 (without accept, bool)</label>
      <input capture id="test4" type="file" />
      <br />
      <label htmlFor="test5">test 5 (capture in environment)</label>
      <input accept="image/*" capture="environment" id="test5" type="file" />
      <br />
      <InputLabel htmlFor="test6">test6 MUI Input</InputLabel>
      <Input accept="image/*" capture="camera" id="test6" type="file" />
    </React.Fragment>
  );
}

export function Patterns(props) {
  const { dataObj, forceSetDataObj } = GetData();
  const patterns = [
    "pattern1.png",
    "pattern2.png",
    "pattern3.png",
    "pattern4.png",
    "pattern5.png",
    "pattern6.png",
    "pattern7.svg",
    "pattern8.svg",
    "pattern9.svg",
    "pattern10.svg",
    "pattern11.svg",
    "pattern12.svg",
    "pattern13.svg",
    "pattern14.svg",
    "pattern15.svg",
    "pattern16.svg",
    "pattern17.svg",
    "pattern18.svg",
    "pattern19.svg",
    "pattern20.svg",
    "pattern21.svg",
  ];
  const images = require.context("data/patterns", true);
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
          overflow: "hidden",
        }}
      >
        {!props?.hide && (
          <Box
            sx={{
              boxShadow: "0px 0px 1px",
              width: "400px",
              height: "400px",
              margin: "0.4em",
              background: `url(${dataObj?.chatPattern}) center center/110% repeat`,
            }}
          ></Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => forceSetDataObj("", "chatPattern")}
            sx={{
              // border: "1px solid",
              boxShadow: "0px 0px 1px",
              width: "50px",
              height: "50px",
              // margin: "2px",
              // background: `url(${imageName}) center center/contain repeat`,
              // background: `url(${require(el)}) center center/contain repeat`,
            }}
          >
            {!dataObj?.chatPattern && <Done />}
          </Box>
          {patterns.map((el, ind) => {
            let imageName = images(`./${el}`);

            return (
              <Box
                key={ind}
                onClick={() => forceSetDataObj(imageName, "chatPattern")}
                sx={{
                  // border: "1px solid",
                  boxShadow: "0px 0px 1px",

                  width: "50px",
                  height: "50px",
                  background: `url(${imageName}) center center/200% repeat`,
                  // background: `url(${require(el)}) center center/contain repeat`,
                }}
              >
                {imageName === dataObj?.chatPattern && <Done />}
              </Box>
            );
          })}
        </Box>
      </Box>
    </React.Fragment>
  );
}

import React, { useState } from "react";

import { Box, Button, Divider, Typography } from "@mui/material";
import { MapBlockOnePoint } from "components/MapBlockOnePoint";
import { PageTitle } from "components/PageTitle";
import { OrderReadQuery } from "components/gql/queris/GetOrderInfo";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetMetrika } from "contexts/MetrikaContext";
import {
  findInArray,
  getOrderDate,
  isActiveChannel,
  isIdentical,
  isObjectEmpty,
  translateOrderType,
} from "data/functions";
import { BoxFlexRow } from "data/styleGlobals";
import { ProfileTable } from "pages/orders/info/ProfileTable";
import { useLocation, useParams } from "react-router-dom";
import { OrderButtons } from "./OrderButtons";
import { OrderParams } from "./OrderParams";
import { PayButton } from "./PayButton";

export function ParamRow(props) {
  return (
    <React.Fragment>
      <BoxFlexRow>
        <Typography>{props?.title}</Typography>
        <Typography>{props?.subTitle}</Typography>
      </BoxFlexRow>
      <Divider />
    </React.Fragment>
  );
}
export default function OrderProfile() {
  let location = useLocation();
  const { dataObj } = GetData();
  const params = useParams();
  const { cInfo } = GetCInfo();

  const { yaParams } = GetMetrika();

  React.useEffect(() => {
    yaParams({ OrderProfile: "visit" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const orderID = location.state?.orderID || params.id;
  const [orderGQL] = OrderReadQuery();
  const [docItem, setDocItem] = useState(
    findInArray(dataObj?.orders, orderID, "orderID") || {},
  );
  const department = findInArray(
    cInfo?.departments,
    docItem?.departmentID,
    "ID",
  );

  React.useEffect(() => {
    let val = findInArray(dataObj?.orders, orderID, "orderID");
    if (val && !isIdentical(docItem, val)) setDocItem(val);
    // eslint-disable-next-line
  }, [dataObj?.orders]);

  React.useEffect(() => {
    let val = findInArray(dataObj?.orders, orderID, "orderID");
    if (val && !isIdentical(docItem, val)) setDocItem(val);
    if (!val) {
      orderGQL({
        variables: { orderId: orderID },
      });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          margin: "0px 8px 0px 8px",
          padding: "0px 8px 0px 8px",
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageTitle
          title={translateOrderType(docItem.type)}
          title2={getOrderDate(docItem)}
        />
        <Box sx={{ margin: "0rem 1rem 0rem 1rem", overflow: "auto" }}>
          <OrderParams department={department} docItem={docItem} />
          <br />
          <ProfileTable docItem={docItem} />
          <br />
          {!isObjectEmpty(department) && <MapBlockOnePoint item={department} />}
          <OrderButtons department={department} docItem={docItem} />
        </Box>
        <PayButton docItem={docItem} orderID={orderID} />
      </Box>
    </React.Fragment>
  );
}
